import React, { useState, useCallback } from 'react';
import { useStytchB2BClient } from '@stytch/react/b2b';
import {
  Card,
  TextInput,
  Container,
  Center,
  Loader,
  PasswordInput,
} from '@mantine/core';
import env from 'env';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './CustomStytchLoginPage.css';
import './LoginCommon.css';
import LoginButton from './LoginButton';
import axios from '../../api/axiosConfig';

type LoginCard =
  | 'email'
  | 'noOrg'
  | 'active'
  | 'invited'
  | 'error'
  | 'loading'
  | 'create'
  | undefined;

type LinkState = '' | 'sending' | 'sent' | 'error';
type PasswordResetState = '' | 'sending' | 'sent' | 'error';

const CustomStytchLogin = () => {
  const stytch = useStytchB2BClient();
  const [email, setEmail] = useState('');
  const [card, setCard] = useState<LoginCard>('email');
  const [password, setPassword] = useState('');
  const [curOrgName, setCurOrgName] = useState('');
  const [orgId, setOrgId] = useState('');
  const [error, setError] = useState(false);
  const [authenticatingPasssword, setAuthenticatingPassword] = useState(false);
  const [sendingLoginLink, setSendingLoginLink] = useState<LinkState>('');
  const [passwordResetState, setPasswordResetState] =
    useState<PasswordResetState>('');

  const [emailError, setEmailError] = useState(false);

  const validateEmail = (email) => {
    // Basic email regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendLoginLink = async () => {
    setSendingLoginLink('sending');
    try {
      await axios.post(`${env.REACT_APP_SERVER_URL}/send_discovery_email`, {
        email,
      });
      setSendingLoginLink('sent');
    } catch (error) {
      alert('error sending link');
      setSendingLoginLink('error');
    }
  };

  const redirectByEmail = async () => {
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
    setCard('loading');
    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/login_or_signup`,
        {
          email,
        }
      );
      const { membershipResponse } = response.data;
      console.log('membershipResponse', membershipResponse);
      if (!membershipResponse) {
        setCard('create');
        return;
      }
      console.log('status', membershipResponse.member.status);
      switch (membershipResponse.member.status) {
        case 'active':
          setCard('active');
          setOrgId(membershipResponse.organization.organization_id);
          break;
        case 'invited':
          setCard('invited');
          setCurOrgName(membershipResponse.organization.organization_name);
          break;
        default:
          setCard('error');
      }
    } catch (error) {
      setCard('error');
      console.error('Error signing up / logging in:', error);
    } finally {
    }
  };

  const authenticatePassword = useCallback(async () => {
    setAuthenticatingPassword(true);
    setError(false);
    try {
      await stytch.passwords.authenticate({
        email_address: email,
        organization_id: orgId,
        password: password,
        session_duration_minutes: 525600,
      });
    } catch (error) {
      setError(true);
      console.error('Error', error);
    } finally {
      setAuthenticatingPassword(false);
    }
  }, [stytch, email, orgId, password]);

  const renderEmailEnter = () => {
    return (
      <>
        <h2 className='card-header'>Log In or Sign Up?</h2>
        <TextInput
          label='Email'
          placeholder='Enter your company email'
          required
          style={{ width: '100%' }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={emailError && !validateEmail(email)}
          mb='md'
          size='md'
        />
        <LoginButton disabled={false} onClick={redirectByEmail}>
          Continue
        </LoginButton>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <div style={{ alignItems: 'center' }}>
        <h3>Authenticating</h3>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Loader />
        </div>
      </div>
    );
  };

  const renderLoginLinkText = () => {
    switch (sendingLoginLink) {
      case '':
        return (
          <p className='clickable-text' onClick={sendLoginLink}>
            Receive a Magic Login Link Email
          </p>
        );
      case 'sending':
        return (
          <p
            className='clickable-text'
            style={{ pointerEvents: 'none', color: 'gray' }}
            onClick={() => {}}
          >
            Sending...
          </p>
        );
      case 'sent':
        return (
          <>
            <p style={{ marginTop: '4px', fontSize: '14px', color: 'gray' }}>
              Login link sent to <b>{email}</b>
            </p>
            <p className='clickable-text' onClick={sendLoginLink}>
              Click to Re-Send
            </p>
          </>
        );
      case 'error':
        return (
          <>
            <p style={{ marginTop: '4px' }}>
              There was an error sending the link.
            </p>
            <p className='clickable-text' onClick={sendLoginLink}>
              Click to Resend
            </p>
          </>
        );
      default:
        return (
          <p className='clickable-text' onClick={sendLoginLink}>
            Receive a Magic Login Link Email
          </p>
        );
    }
  };

  const renderPasswordResetText = () => {
    switch (passwordResetState) {
      case '':
        return (
          <p
            className='clickable-text small-text'
            style={{ margin: '0px', position: 'relative', bottom: '10px' }}
            onClick={sendPasswordReset}
          >
            Forgot Password?
          </p>
        );
      case 'sending':
        return (
          <p
            className='clickable-text small-text'
            style={{ pointerEvents: 'none', color: 'gray' }}
            onClick={() => {}}
          >
            Sending...
          </p>
        );
      case 'sent':
        return (
          <>
            <p
              className='small-text'
              style={{ marginTop: '4px', color: 'gray', margin: '0px' }}
            >
              Password reset link sent to <b>{email}</b>
            </p>
            <p
              className='clickable-text small-text'
              onClick={sendPasswordReset}
            >
              Click to Re-Send
            </p>
          </>
        );
      case 'error':
        return (
          <>
            <p
              className='small-text'
              style={{ marginTop: '4px', color: 'black', margin: '0px' }}
            >
              There was an error sending the link
            </p>
            <p
              className='clickable-text small-text'
              onClick={sendPasswordReset}
            >
              Click to Re-Send
            </p>
          </>
        );
      default:
        return (
          <p
            className='clickable-text small-text'
            style={{ margin: '0px', position: 'relative', bottom: '10px' }}
            onClick={sendPasswordReset}
          >
            Forgot Password?
          </p>
        );
    }
  };

  const sendPasswordReset = useCallback(async () => {
    setPasswordResetState('sending');
    try {
      await stytch.passwords.resetByEmailStart({
        email_address: email,
        organization_id: orgId,
        login_redirect_url: env.REACT_APP_STYTCH_REDIRECT_URL,
        reset_password_redirect_url: env.REACT_APP_STYTCH_REDIRECT_URL,
      });
      setPasswordResetState('sent');
    } catch {
      setPasswordResetState('error');
    }
  }, [stytch, orgId, email]);

  const renderPassword = () => {
    return (
      <>
        <h2 className='card-header'>Finish Login</h2>
        <PasswordInput
          label='Password'
          placeholder='Enter your password'
          required
          style={{ width: '100%' }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          mb='md'
          size='md'
          error={error}
        />
        {error && renderPasswordResetText()}
        <LoginButton
          disabled={authenticatingPasssword}
          onClick={authenticatePassword}
        >
          Continue
        </LoginButton>
        <h3 style={{ opacity: 0.7 }}>or</h3>
        {renderLoginLinkText()}
      </>
    );
  };

  const renderInvited = () => {
    return (
      <>
        <h2 className='card-header'>Accept Invitation</h2>
        <p>You've been invited to an organization </p>
        <p style={{ fontWeight: 'bold' }}>{curOrgName}</p>
        <p style={{ opacity: 0.6 }}>
          Check your email to accept your invitation.
        </p>
      </>
    );
  };

  const renderCreate = () => {
    return (
      <>
        <h2 className='card-header'>Verify Your Email</h2>
        <p>A verification link has been sent to</p>
        <p style={{ fontWeight: 'bold' }}>{email}</p>
        <p style={{ opacity: 0.6 }}>
          Click the link to continue your account creation.
        </p>
      </>
    );
  };

  const renderCard = () => {
    switch (card) {
      case 'email':
        return renderEmailEnter();
      case 'loading':
        return renderLoading();
      case 'active':
        return renderPassword();
      case 'invited':
        return renderInvited();
      case 'create':
        return renderCreate();
      default:
        return (
          <>
            <h3 style={{ marginTop: '4px' }}>An Error Occured</h3>
            <b> {email}</b>
            <br />
            Please ensure you entered a valid company email
            <br />
          </>
        );
    }
  };

  return (
    <div className='background-image'>
      <Container style={{ height: '100vh' }}>
        <Center style={{ height: '90%' }}>
          <Card className='login-card'>{renderCard()}</Card>
        </Center>
      </Container>
    </div>
  );
};

export default CustomStytchLogin;
