import axios from 'api/axiosConfig';
import env from 'env';

export const sendResultsEmail = (campaignId: string) => {
  const payload = { campaign_id: campaignId };
  return axios.post(
    `${env.REACT_APP_SERVER_URL}/trigger_results_email`,
    payload
  );
};

export const cancelPendingCalls = (campaignId: string) => {
  const payload = { campaign_id: campaignId };
  return axios.post(
    `${env.REACT_APP_SERVER_URL}/cancel_pending_calls`,
    payload
  );
};

export const duplicateScript = (campaignId: string) => {
  const payload = { campaign_id: campaignId };
  return axios.post(`${env.REACT_APP_SERVER_URL}/duplicate_campaign`, payload);
};

export const deleteCampaign = (campaignId) => {
  return axios.delete(`/delete_campaign/${campaignId}`);
};

export const pauseCampaign = (campaignId) => {
  return axios.post(
    `${env.REACT_APP_SERVER_URL}/campaigns/${campaignId}/pause`
  );
};

export const startCampaign = (campaignId) => {
  return axios.post(
    `${env.REACT_APP_SERVER_URL}/campaigns/${campaignId}/resume`
  );
};

export const fetchCampaignJobs = (campaignId) => {
  return axios.get(
    `${env.REACT_APP_SERVER_URL}/campaigns/campaign_jobs/${campaignId}`
  );
};
