import { useState } from 'react';

import { Client, ClientFilterState } from './types';

export default function useClientFilterState(): ClientFilterState {
  const [selectedClient, setSelectedClient] = useState<Client | undefined>();

  const clear = () => {
    setSelectedClient(undefined);
  };

  const isEmpty = !selectedClient;

  return {
    selectedClient,
    onChangeClient: setSelectedClient,
    clear,
    isEmpty,
  };
}
