import React from 'react';
import {
  ActionIcon,
  Group,
  Menu,
  Radio,
  Stack,
  Button,
  Text,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { IconX, TablerIcon } from '@tabler/icons-react';
import dayjs from 'dayjs';

import { DateSelectionMode } from './types';

function formatDate(date: Date): string {
  return dayjs(date).format('M/D/YY');
}

interface DateFilterState {
  selectionMode: DateSelectionMode;
  onChangeSelectionMode: (mode: DateSelectionMode) => void;
  startDate: Date | null;
  onChangeStartDate: (date: Date | null) => void;
  endDate: Date | null;
  onChangeEndDate: (date: Date | null) => void;
}

interface ComponentProps {
  Icon?: TablerIcon;
  onClear: () => void;
  dateFilterState: DateFilterState;
  disabled?: boolean;
}

export default function SourcingDateFilter({
  onClear,
  dateFilterState: {
    selectionMode,
    onChangeSelectionMode,
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate,
  },
  disabled,
}: ComponentProps) {
  const filterLabel = (() => {
    switch (selectionMode) {
      case 'before':
        return endDate ? `Before ${formatDate(endDate)}` : undefined;
      case 'after':
        return startDate ? `After ${formatDate(startDate)}` : undefined;
      case 'between':
        return endDate && startDate
          ? `${formatDate(startDate)} - ${formatDate(endDate)}`
          : undefined;
    }
  })();

  const handleChangeSelectionMode = (value: string) => {
    const mode = value as DateSelectionMode;
    if (mode === 'before') {
      if (!endDate) onChangeEndDate(startDate);
      onChangeStartDate(null);
    } else if (mode === 'after') {
      if (!startDate) onChangeStartDate(endDate);
      onChangeEndDate(null);
    }

    onChangeSelectionMode(mode);
  };

  const dateValue: Date | null | [Date | null, Date | null] = (() => {
    switch (selectionMode) {
      case 'before':
        return endDate;
      case 'after':
        return startDate;
      case 'between':
        return [startDate, endDate];
    }
  })();

  const handleChangeDate = (date: Date | null | [Date | null, Date | null]) => {
    if (Array.isArray(date)) {
      const [start, end] = date;
      onChangeStartDate(start);
      onChangeEndDate(end);
    } else {
      if (selectionMode === 'before') onChangeEndDate(date);
      if (selectionMode === 'after') onChangeStartDate(date);
    }
  };

  return (
    <Menu>
      <Menu.Target>
        <Button
          variant='default'
          radius='xl'
          size='xs'
          style={{ opacity: disabled ? 0.5 : 1.0 }}
          disabled={disabled}
        >
          <Group gap={4} align='center'>
            {filterLabel ? 'Exclude Viewed' : 'Exclude Recently Viewed'}
            <Text c='dimmed' size='sm'>
              {filterLabel ? filterLabel : 'No'}
            </Text>
            <ActionIcon
              variant='subtle'
              onClick={onClear}
              size='sm'
              style={{ zIndex: 100 }}
              component='div'
            >
              <IconX size={12} />
            </ActionIcon>
          </Group>
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Stack gap='xs' p='sm'>
          <Radio.Group
            value={selectionMode}
            onChange={handleChangeSelectionMode}
          >
            <Group>
              <Radio
                label='Before'
                value='before'
                style={{ cursor: 'pointer' }}
              />
              <Radio
                label='Between'
                value='between'
                style={{ cursor: 'pointer' }}
              />
              <Radio
                label='After'
                value='after'
                style={{ cursor: 'pointer' }}
              />
            </Group>
          </Radio.Group>
          <Menu.Divider />
          <DatePicker
            key={selectionMode}
            type={selectionMode === 'between' ? 'range' : 'default'}
            value={dateValue}
            onChange={handleChangeDate}
            firstDayOfWeek={0}
            maxDate={dayjs().endOf('day').toDate()}
          />
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
}
