import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return '';
  }
  const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US'); // Adjust the country code as needed
  if (parsedNumber) {
    return parsedNumber.formatInternational();
  }
  return phoneNumber;
};
