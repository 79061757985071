import React from 'react';
import { Group, Input, NumberInput, Slider, Stack } from '@mantine/core';

import { ScoreFilterState } from './types';
import FilterPill from './FilterPill';

const MIN_SCORE = 0;
const MAX_SCORE = 1;
const STEP_SIZE = 0.01;
const PRECISION = 2;

interface ComponentProps {
  onClear: () => void;
  onClose: () => void;
  scoreFilterState: ScoreFilterState;
}

export default function ScoreFilter({
  onClear,
  onClose,
  scoreFilterState: { score, onChangeScore },
}: ComponentProps) {
  const filterLabel = `>= ${score.toFixed(2)}`;

  const handleChangeScore = (value: string | number) => {
    onChangeScore(Number(value));
  };

  return (
    <FilterPill
      title='Score'
      label={filterLabel}
      onClear={onClear}
      onClose={onClose}
    >
      <Stack p='sm'>
        <Group justify='space-between'>
          <Input.Label>Score is at least</Input.Label>
          <NumberInput
            w='50%'
            min={MIN_SCORE}
            max={MAX_SCORE}
            value={score}
            onChange={handleChangeScore}
            allowDecimal
            decimalScale={PRECISION}
            step={STEP_SIZE}
          />
        </Group>
        <Slider
          mb='lg'
          min={MIN_SCORE}
          max={MAX_SCORE}
          marks={[
            { value: 0.25, label: '0.25' },
            { value: 0.5, label: '0.5' },
            { value: 0.75, label: '0.75' },
          ]}
          value={score}
          onChange={onChangeScore}
          precision={PRECISION}
          step={STEP_SIZE}
        />
      </Stack>
    </FilterPill>
  );
}
