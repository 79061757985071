import React, { useState, useRef, useEffect } from 'react';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { Combobox, Loader, TextInput, useCombobox } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

import { GoogleMapsPlaceWithDescription } from './types';

const LIBRARIES: Libraries = ['places'];

interface ComponentProps {
  selectedPlace?: GoogleMapsPlaceWithDescription;
  onPlaceSelected: (place: GoogleMapsPlaceWithDescription) => void;
}

export default function LocationAutocomplete({
  selectedPlace,
  onPlaceSelected,
}: ComponentProps) {
  const [searchValue, setSearchValue] = useState<string>(
    selectedPlace?.description || ''
  );
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 300);
  const [suggestions, setSuggestions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCC6NjokcT1zNYK9lnL72905AsR6FfmO1k',
    libraries: LIBRARIES,
  });

  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(
    null
  );

  useEffect(() => {
    if (selectedPlace?.formatted_address) {
      setSearchValue(selectedPlace?.formatted_address);
    }
    if (!selectedPlace) {
      setSearchValue('');
    }
  }, [selectedPlace]);

  useEffect(() => {
    if (isLoaded) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
      const mapElement = document.createElement('div');
      mapElement.style.display = 'none';
      const map = new window.google.maps.Map(mapElement);
      placesServiceRef.current = new window.google.maps.places.PlacesService(
        map
      );
    }
  }, [isLoaded]);

  useEffect(() => {
    if (debouncedSearchValue.length > 2 && autocompleteServiceRef.current) {
      setLoading(true);
      autocompleteServiceRef.current
        .getPlacePredictions({
          input: debouncedSearchValue,
          types: ['(regions)'],
          componentRestrictions: { country: 'us' }, // Change this as needed
        })
        .then((response) => {
          if (response.predictions) {
            setSuggestions(response.predictions);
          } else {
            setSuggestions([]);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setSuggestions([]);
    }
  }, [debouncedSearchValue]);

  const handleSuggestionClick =
    (suggestion: google.maps.places.AutocompletePrediction) => () => {
      if (placesServiceRef.current) {
        placesServiceRef.current.getDetails(
          { placeId: suggestion.place_id },
          (place, status) => {
            if (
              status === window.google.maps.places.PlacesServiceStatus.OK &&
              place
            ) {
              onPlaceSelected({
                ...place,
                description: suggestion.description,
              });
              setSearchValue(place.formatted_address || place.name || '');
              setSuggestions([]);
            }
          }
        );
      }
    };

  const options = suggestions.map((suggestion) => (
    <Combobox.Option
      value={suggestion.description}
      key={suggestion.place_id}
      onClick={handleSuggestionClick(suggestion)}
    >
      {suggestion.description}
    </Combobox.Option>
  ));

  return (
    <Combobox
      onOptionSubmit={(optionValue) => {
        setSearchValue(optionValue);
        combobox.closeDropdown();
      }}
      withinPortal={false}
      store={combobox}
    >
      <Combobox.Target>
        <TextInput
          placeholder='Enter a city, state, or zip code...'
          value={searchValue}
          onChange={(event) => {
            setSearchValue(event.currentTarget.value);
            combobox.resetSelectedOption();
            combobox.openDropdown();
          }}
          onClick={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearchValue(selectedPlace?.description || '');
          }}
          rightSection={(loading || !isLoaded) && <Loader size={18} />}
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={!suggestions}>
        <Combobox.Options>
          {options}
          {!loading && !suggestions.length && (
            <Combobox.Empty>No results found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
