export function convertUpperSnakeToTitle(str) {
  if (!str) {
    return '';
  }
  return str
    .toLowerCase() // Convert the entire string to lowercase
    .split('_') // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words back together with a space
}
