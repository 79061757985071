import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { debounce } from 'lodash';

import './CustomQuill.css'; // Custom CSS
import { removeHighlighting } from './utils';
import { ChatInputPanel } from './types';
const parser = new DOMParser();

const ResumeEditor = ({
  name,
  summaryText,
  educationEntries,
  skills,
  experiences,
  qualifications,

  editorContent,
  setEditorContent,
  setOriginalEditorContent,
  jobSkillsStrListLowered,
  chatPanel,
}) => {
  const isPasting = useRef(false);
  const isEditorFocused = useRef(false);
  const quillRef = useRef<ReactQuill | null>(null);

  function highlightTextNode(node: Text, wordsToHighlight: string[]): void {
    let text = node.nodeValue || '';
    const regex = new RegExp(`\\b(${wordsToHighlight.join('|')})\\b`, 'gi');

    const newContent = text.replace(regex, (match) => {
      return `<span style="background-color: rgb(0 251 255 / 46%);">${match}</span>`;
    });

    if (newContent !== text) {
      const wrapper = document.createElement('span');
      wrapper.innerHTML = newContent;
      node.replaceWith(...Array.from(wrapper.childNodes));
    }
  }

  const highLightedEditorContent = useMemo(() => {
    function walkDOM(node: Node, wordsToHighlight: string[]): void {
      // Recursively highlights text
      if (node.nodeType === Node.TEXT_NODE) {
        highlightTextNode(node as Text, wordsToHighlight);
      } else {
        Array.from(node.childNodes).forEach((child) =>
          walkDOM(child, wordsToHighlight)
        );
      }
    }
    if (chatPanel === ChatInputPanel.JobDetails) {
      const doc = parser.parseFromString(editorContent, 'text/html');
      walkDOM(doc.body, jobSkillsStrListLowered);
      if (
        doc.body.innerHTML.includes('rgb(0 251 255 / 46%)') &&
        !editorContent.includes('rgb(0 251 255 / 46%)')
      ) {
        console.log(
          'returning rgb(0 251 255 / 46%) innerhtml for the content because chat panel jobdetails'
        );
        return doc.body.innerHTML;
      }
    }
    return editorContent;
  }, [chatPanel, editorContent, jobSkillsStrListLowered]);

  useEffect(() => {
    // Combine all the inputs into one HTML string
    const combinedContent = `
            <h2>${name}</h2>
            <ul>
            ${summaryText.map((s) => `<li>${s}</li>`).join('')}
            </ul>
            ${experiences.length > 0 ? '<h2>Experience</h2>' : ''}
            ${experiences
              .map(
                (exp) => `
                <h3>${exp.role} - ${exp.company_name}, ${exp.location} - ${exp.time_period}</h3>
                <ul>
                    ${exp.details.map((detail) => `<li>${detail}</li>`).join('')}
                </ul>
            `
              )
              .join('')}
            ${
              skills.length > 0
                ? `
                <h2>Skills</h2>
                <ul>
                    ${skills.map((skill) => `<li>${skill}</li>`).join('')}
                </ul>
            `
                : ''
            }

            ${
              qualifications.length > 0
                ? `
                <h3>Certifications</h3>
                <ul>
                    ${qualifications.map((qualification) => `<li>${qualification}</li>`).join('')}
                </ul>
            `
                : ''
            }
            ${educationEntries.length > 0 ? '<h3>Education</h3>' : ''}
            ${educationEntries.map((entry) => `<p>${entry}</p>`).join('')}
        `;
    setEditorContent(combinedContent);
    setOriginalEditorContent(combinedContent);
  }, [
    name,
    summaryText,
    educationEntries,
    skills,
    experiences,
    qualifications,
    setEditorContent,
    setOriginalEditorContent,
  ]);

  const debouncedCallback = useRef(debounce((callback) => callback(), 300));

  const onEditorChange = useCallback(() => {
    if (!quillRef?.current) {
      return;
    }
    const content = quillRef.current.getEditor().root.innerHTML;
    const withHighlightingRemoved = removeHighlighting(content);
    if (chatPanel === ChatInputPanel.Chat) {
      setEditorContent(withHighlightingRemoved);
    } else {
      setEditorContent(content);
    }

    if (!(isEditorFocused.current || isPasting.current)) {
      setOriginalEditorContent(withHighlightingRemoved);
    }
    isPasting.current = false; // Reset paste flag after handling change
  }, [
    chatPanel,
    quillRef,
    isEditorFocused,
    isPasting,
    setEditorContent,
    setOriginalEditorContent,
  ]);

  const handleEditorChange = useCallback(() => {
    debouncedCallback.current(onEditorChange);
  }, [onEditorChange]);

  useEffect(() => {
    onEditorChange();
  }, [chatPanel, onEditorChange]);

  useEffect(() => {
    if (quillRef.current) {
      const quillEditor = quillRef.current.getEditor();

      const handleFocus = () => {
        isEditorFocused.current = true;
      };
      const handleBlur = () => {
        isEditorFocused.current = false;
      };
      const handlePaste = () => {
        isPasting.current = true;
      };

      quillEditor.on('text-change', handleEditorChange);
      quillEditor.on('selection-change', (range) => {
        if (range) handleFocus();
        else handleBlur();
      });
      quillEditor.root.addEventListener('paste', handlePaste);

      return () => {
        quillEditor.off('text-change', handleEditorChange);
        quillEditor.root.removeEventListener('paste', handlePaste);
      };
    }
  }, [handleEditorChange]);

  return (
    <div>
      <ReactQuill
        ref={quillRef}
        value={highLightedEditorContent}
        onChange={() => {}}
      />
    </div>
  );
};

export default ResumeEditor;
