// src/CustomButton.js
import React from 'react';
import { Button } from '@mantine/core'; // Assuming you are using Mantine's Button component

const LoginButton = ({ onClick, disabled, children, style = {}, ...props }) => {
  return (
    <Button
      style={{ marginTop: '20px', borderRadius: '12px', ...style }}
      fullWidth
      disabled={disabled}
      onClick={onClick}
      size='md'
      color='#2d6b9c'
      {...props} // Spread any additional props
    >
      {children}
    </Button>
  );
};

export default LoginButton;
