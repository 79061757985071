import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Table,
  Title,
  Modal,
  Center,
  Loader,
  Badge,
  ActionIcon,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { debounce } from 'lodash';
import env from 'env';
import { IconRefresh } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import SortableHeader from 'components/common/SortableHeader';
import { RequirementImportance } from 'pages/editorv2/EditorPageV2';
import { convertUpperSnakeToTitle } from 'utils/formatUtils';
import { formatPhoneNumber } from 'utils/phoneUtils';

import CampaignDetailStatsRow from './CampaignDetailStatsRow';
import axios from '../../../api/axiosConfig';
import Transcript from '../../../components/transcript/Transcript';
import './CampaignDetailsPage.css';
import {
  convertSecondsToMinutesAndSeconds,
  formatToLocalTime,
} from '../../../utils/dateUtils';
import 'index.css';

const CallStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  CANCELLED: 'CANCELLED',
  VOICEMAIL: 'VOICEMAIL',
  NO_ANSWER: 'NO_ANSWER',
  INVALID_NUMBER: 'INVALID_NUMBER',
};

const CallInitiatedBy = {
  CAMPAIGN: 'CAMPAIGN',
  AUTOMATIC_RETRY: 'AUTOMATIC_RETRY',
  CANDIDATE_RESCHEDULED: 'CANDIDATE_RESCHEDULED',
  INBOUND: 'INBOUND',
};

const SortColumn = {
  LAST_UPDATED: 'last_updated',
  OVERALL_GRADE: 'overall_grade',
  COMPLETION_RATE: 'completion_rate',
  CALL_LENGTH_SEC: 'call_length_sec',
};

interface SortConfig {
  column: string;
  direction: 'ascending' | 'descending';
}

const COLLAPSE_THRESHOLD = 1400; // Note: threshold here AND css file

const CampaignDetailsPage = () => {
  const [paginationObject, setPaginationObject] = useState({
    skip: 0,
    limit: 50,
  });
  const [sort, setSort] = useState<SortConfig | undefined>({
    column: SortColumn.COMPLETION_RATE,
    direction: 'descending',
  });
  const handleSort = (column: string) => () => {
    if (sort && sort.column === column) {
      if (sort.direction === 'descending')
        setSort({ column, direction: 'ascending' });
      else setSort(undefined);
    } else {
      setSort({ column, direction: 'descending' });
    }
  };

  const [isCollapsed, setIsCollapsed] = useState(
    window.innerWidth <= COLLAPSE_THRESHOLD
  );

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth <= COLLAPSE_THRESHOLD);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on window width

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [totalCount, setTotalCount] = useState(0);
  const [isFetchingCalls, setIsFetchingCalls] = useState(true);
  const [isFetchingTranscript, setIsFetchingTranscript] = useState(false);
  const [candidates, setCandidates] = useState<any>([]);
  const [transcriptError, setTranscriptError] = useState(false);
  const [transcriptData, setTranscriptData] = useState({
    transcript: '',
    requirementGrades: {} as Record<
      string,
      { answer: string; grade: string; importance: RequirementImportance }
    >,
    smsTranscript: '',
    callId: '',
    overallGrade: 0,
    questionCompletionRate: 0,
    candidateName: '',
    lastCalled: '',
    allCalls: [],
    callComplete: false,
  });

  const [transcriptOpened, { open: openTranscript, close: closeTranscript }] =
    useDisclosure(false);

  const location = useLocation();
  const match = location.pathname.match(
    /\/scripts\/script-editor\/(new\/)?([^/]+)/
  );
  const { campaignId: paramCampaignId } = useParams();
  const campaignId = match ? match[2] : paramCampaignId || '';

  const fetchCandidates = useCallback(
    async ({ skip, limit }) => {
      if (!campaignId) return;
      try {
        setIsFetchingCalls(true);
        const payload = {
          sort: {
            column: sort?.column || SortColumn.LAST_UPDATED,
            descending: sort?.direction === 'descending' || !sort?.direction,
          },
        };
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/campaigns/campaign/${campaignId}/candidates_and_calls`,
          payload,
          {
            params: {
              skip,
              limit,
            },
          }
        );
        setCandidates((prev) => {
          console.log('Previous candidates:', prev); // Log previous candidates
          return [...prev, ...response.data.candidates]; // Append new candidates
        });
        setTotalCount(Number(response.data['total_records']));
      } catch (error) {
        console.error('Error fetching campaign calls:', error);
        notifications.show({
          title: 'There was an error retrieving the campaign calls',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      } finally {
        setIsFetchingCalls(false);
      }
    },
    [setTotalCount, setCandidates, campaignId, sort]
  );

  useEffect(() => {
    if (!campaignId) return;
    setCandidates([]);
    setTotalCount(0);
    // Reset pagination when campaignId or sort changes
    setPaginationObject((prev) => ({ ...prev, skip: 0 }));
  }, [campaignId, sort]);

  useEffect(() => {
    setIsFetchingCalls(true);
    const debouncedFetchCandidates = debounce(() => {
      if (paginationObject.skip === 0) {
        setCandidates([]);
        setTotalCount(0);
      }
      fetchCandidates({
        limit: paginationObject.limit,
        skip: paginationObject.skip,
      });
    }, 300); // Debounce delay (e.g., 300ms)

    if (!campaignId) return;
    debouncedFetchCandidates();

    // Cleanup debounced functions when the component unmounts or dependencies change
    return () => {
      debouncedFetchCandidates.cancel();
    };
  }, [campaignId, paginationObject, fetchCandidates]);

  const fetchTranscriptData = async (candidateId) => {
    setIsFetchingTranscript(true);
    try {
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/call_details/${campaignId}`
      );
      setTranscriptData({
        transcript: response.data.transcript,
        requirementGrades: response.data.requirement_grades,
        smsTranscript: response.data.sms_transcript,
        overallGrade: response.data.last_call?.overall_grade || 0,
        questionCompletionRate:
          response.data.last_call?.question_completion_rate || 0,
        callId: response.data.last_call?.call_id || undefined,
        candidateName: response.data.candidate.fullName,
        lastCalled: response.data.last_call?.last_updated || '',
        allCalls: response.data.all_calls || [],
        callComplete: response.data.last_call?.call_status === 'COMPLETED',
      });

      setTranscriptError(false);
    } catch (error) {
      setTranscriptError(true);
      console.error('Error fetching transcript data:', error);
      notifications.show({
        title: 'There was an error retrieving the call details',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
    } finally {
      setIsFetchingTranscript(false);
    }
  };

  const handleRowClick = (candidateId) => {
    openTranscript();
    fetchTranscriptData(candidateId);
  };

  const candidateToCallStatus = (candidate) => {
    const { calls } = candidate;
    if (calls && calls.length > 0) {
      const lastCall = calls[0];
      if (lastCall.call_status === CallStatus.PENDING) {
        if (calls.call_initiated_by === CallInitiatedBy.AUTOMATIC_RETRY) {
          return 'Automatic Callback Pending';
        }
        if (calls.call_initiated_by === CallInitiatedBy.CANDIDATE_RESCHEDULED) {
          return 'Callback Pending';
        }
        return 'Pending';
      }
      return convertUpperSnakeToTitle(lastCall.call_status);
    }
  };

  const candidateToSmsStatus = (candidate) => {
    return convertUpperSnakeToTitle(candidate.sms_status);
  };

  // const getLongestCall = (candidate) =>
  //   candidate.calls?.reduce(
  //     (longest, current) =>
  //       current.call_length_sec > longest.call_length_sec ? current : longest,
  //     candidate.calls[0]
  //   );

  // const isCallCompleted = (call) => call?.call_status === CallStatus.COMPLETED;
  const hasCallHappened = (call) =>
    call?.call_status !== CallStatus.PENDING &&
    call?.call_status !== CallStatus.CANCELLED;

  const candidateToRecentCallLength = (candidate) => {
    const max_call_length_sec = candidate.max_call_length_sec;
    const numCalls = candidateToNumberOfCalls(candidate);
    return max_call_length_sec && numCalls > 0
      ? convertSecondsToMinutesAndSeconds(max_call_length_sec)
      : '-';
  };

  const candidateToCompletionRate = (candidate) => {
    const numCalls = candidateToNumberOfCalls(candidate);
    if (numCalls === 0) {
      return '-';
    }
    return `${(candidate.max_completion_rate * 100).toFixed(0)}%` || '-';
  };

  const candidateToOverallGrade = (candidate) => {
    const numCalls = candidateToNumberOfCalls(candidate);
    if (numCalls === 0) {
      return '-';
    }
    if (!candidate.max_overall_grade) {
      return '-';
    }
    return `${candidate.max_overall_grade?.toFixed(0)}%` || '-';
  };

  const candidateToLastCalled = (candidate) => {
    const numCalls = candidateToNumberOfCalls(candidate);

    return numCalls > 0
      ? formatToLocalTime(candidate.max_last_updated) || '-'
      : '-';
  };

  const candidateToNumberOfCalls = (candidate) => {
    return (
      candidate.candidate.calls.filter((c) => hasCallHappened(c)).length || 0
    );
  };

  return (
    <div className='campaign-details-page' style={{ marginTop: '0pt' }}>
      <CampaignDetailStatsRow campaignId={campaignId} />
      <div
        className='common-table-wrapper'
        style={{
          maxHeight: 'calc(100vh - 160px)',
          overflowY: 'scroll',
          background: 'white',
        }}
        onScroll={(e) => {
          const element = e.target as HTMLElement;

          if (
            element.scrollHeight - element.scrollTop <=
              element.clientHeight + 20 &&
            !isFetchingCalls
          ) {
            if (paginationObject.skip + paginationObject.limit >= totalCount)
              return;

            setIsFetchingCalls(true);
            setPaginationObject({
              limit: paginationObject.limit,
              skip: paginationObject.skip + paginationObject.limit,
            });
          }
        }}
      >
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th
                style={{
                  width: '20%',
                }}
              >
                Name
              </Table.Th>
              {!isCollapsed && (
                <Table.Th
                  style={{
                    width: '20%',
                  }}
                >
                  Number
                </Table.Th>
              )}

              <Table.Th
                style={{
                  width: '20%',
                }}
              >
                Last Called
              </Table.Th>
              <SortableHeader
                sorted={sort?.column === SortColumn.CALL_LENGTH_SEC}
                reversed={sort?.direction === 'ascending'}
                onSort={handleSort(SortColumn.CALL_LENGTH_SEC)}
                style={{
                  width: '15%',
                }}
              >
                Call Length
              </SortableHeader>
              <Table.Th
                style={{
                  width: '10%',
                }}
              >
                Calls
              </Table.Th>
              <SortableHeader
                sorted={sort?.column === SortColumn.OVERALL_GRADE}
                reversed={sort?.direction === 'ascending'}
                onSort={handleSort(SortColumn.OVERALL_GRADE)}
                style={{
                  width: '15%',
                }}
              >
                Score
              </SortableHeader>
              <SortableHeader
                sorted={sort?.column === SortColumn.COMPLETION_RATE}
                reversed={sort?.direction === 'ascending'}
                onSort={handleSort(SortColumn.COMPLETION_RATE)}
                style={{
                  width: '20%',
                }}
              >
                Completion Rate
              </SortableHeader>
              {!isCollapsed && (
                <Table.Th
                  style={{
                    width: '20%',
                  }}
                >
                  SMS Status
                </Table.Th>
              )}

              <Table.Th
                style={{
                  width: '15%',
                }}
              >
                Call Status
              </Table.Th>
              <Table.Th
                style={{
                  width: '5%',
                  padding: '0',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center' /* Center horizontally */,
                    alignItems: 'center' /* Center vertically */,
                    height: '100%',
                    color: 'var(--salv-dark-6)',
                    marginRight: '5px',
                  }}
                  className='refreshIconContainer'
                >
                  <ActionIcon
                    variant='transparent'
                    className='refreshIconContainer'
                    onClick={() => {
                      setIsFetchingCalls(true);
                      setCandidates([]);
                      setPaginationObject({
                        limit: paginationObject.limit,
                        skip: 0,
                      });
                    }}
                  >
                    <IconRefresh className='refreshIconContainer' size={16} />
                  </ActionIcon>
                </div>
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {candidates.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Table.Tr
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(item.candidate.candidate_id);
                    }}
                    // onClick={() => toggleRow(item.full_name)}
                  >
                    <Table.Td>{item.candidate.full_name}</Table.Td>
                    {!isCollapsed && (
                      <Table.Td>
                        {formatPhoneNumber(item.candidate.phone_number)}
                      </Table.Td>
                    )}
                    <Table.Td>{candidateToLastCalled(item)}</Table.Td>
                    <Table.Td>{candidateToRecentCallLength(item)}</Table.Td>
                    <Table.Td>{candidateToNumberOfCalls(item)}</Table.Td>
                    <Table.Td>{candidateToOverallGrade(item)}</Table.Td>
                    <Table.Td>{candidateToCompletionRate(item)}</Table.Td>
                    {!isCollapsed && (
                      <Table.Td>
                        <Badge autoContrast className='status-badge'>
                          {candidateToSmsStatus(item.candidate)}
                        </Badge>
                      </Table.Td>
                    )}

                    <Table.Td>
                      <Badge autoContrast className='status-badge'>
                        {candidateToCallStatus(item.candidate)}
                      </Badge>
                    </Table.Td>
                    <Table.Td>{}</Table.Td>
                  </Table.Tr>
                </React.Fragment>
              );
            })}
            {!isFetchingCalls && candidates && candidates?.length === 0 && (
              <tr>
                <td colSpan={12} style={{ minWidth: '100%' }}>
                  <Center p='lg'>No contacts</Center>
                </td>
              </tr>
            )}
            {isFetchingCalls && (
              <tr>
                <td colSpan={12} style={{ minWidth: '100%', width: '100%' }}>
                  <Center p='lg'>
                    <Loader size='sm' type='dots' />
                  </Center>
                </td>
              </tr>
            )}
          </Table.Tbody>
        </Table>
      </div>

      <Modal
        opened={transcriptOpened}
        onClose={closeTranscript}
        size='100%'
        // style={{ padding: '40px' }}
        styles={{
          header: {
            backgroundColor: 'transparent',
          },
        }}
      >
        {isFetchingTranscript ? (
          <Center p='lg'>
            <Loader size='sm' type='dots' />
          </Center>
        ) : transcriptError ? (
          <div
            style={{
              padding: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Title c={'black'}>Failed to retrieve call details</Title>
          </div>
        ) : (
          <Transcript
            transcript={transcriptData.transcript}
            requirementGrades={transcriptData.requirementGrades}
            callId={transcriptData.callId}
            smsTranscript={transcriptData.smsTranscript}
            overallGrade={transcriptData.overallGrade}
            completionRate={transcriptData.questionCompletionRate}
            candidateName={transcriptData.candidateName}
            lastCalled={transcriptData.lastCalled}
            allCalls={transcriptData.allCalls}
            callComplete={transcriptData.callComplete}
          />
        )}
      </Modal>
    </div>
  );
};

export default CampaignDetailsPage;
