import React from 'react';
import { Checkbox, Menu } from '@mantine/core';

import { AgencyFilterState } from './types';
import FilterPill from './FilterPill';

interface ComponentProps {
  onClear: () => void;
  onClose: () => void;
  agencyFilterState: AgencyFilterState;
}

export default function AgencyFilter({
  onClear,
  onClose,
  agencyFilterState: { agencies, selectedAgencies, onChangeSelectedAgencies },
}: ComponentProps) {
  const filterLabel = selectedAgencies.length
    ? String(selectedAgencies.length)
    : undefined;

  const handleItemClick = (selectedAgency: string) => () => {
    if (selectedAgencies.includes(selectedAgency)) {
      onChangeSelectedAgencies(
        selectedAgencies.filter((agency) => agency !== selectedAgency)
      );
    } else {
      onChangeSelectedAgencies([...selectedAgencies, selectedAgency]);
    }
  };

  const handleSelectAll = () => {
    if (selectedAgencies.length === 0) {
      onChangeSelectedAgencies(agencies);
    } else {
      onChangeSelectedAgencies([]);
    }
  };

  return (
    <FilterPill
      title='Agencies'
      label={filterLabel}
      onClear={onClear}
      onClose={onClose}
    >
      <Menu.Item
        key='all'
        leftSection={
          <Checkbox
            size='xs'
            style={{ pointerEvents: 'none' }}
            checked={selectedAgencies.length > 0}
            indeterminate={
              selectedAgencies.length > 0 &&
              agencies.length !== selectedAgencies.length
            }
            readOnly
          />
        }
        onClick={handleSelectAll}
      >
        Select all
      </Menu.Item>
      <Menu.Divider />
      {agencies.map((agency) => (
        <Menu.Item
          key={agency}
          leftSection={
            <Checkbox
              size='xs'
              style={{ pointerEvents: 'none' }}
              checked={selectedAgencies.includes(agency)}
              readOnly
            />
          }
          onClick={handleItemClick(agency)}
        >
          {agency}
        </Menu.Item>
      ))}
    </FilterPill>
  );
}
