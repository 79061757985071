// Convert UTC time into local time according to the browser
export const formatToLocalTime = (utcTime, detail = false) => {
  // Ensure the time string is treated as UTC by appending 'Z' if it's not already included.
  const utcTimeWithZ = utcTime.endsWith('Z') ? utcTime : `${utcTime}Z`;

  let dateObj = new Date(utcTimeWithZ);

  // Get the current date to compare
  let today = new Date();
  let isToday = dateObj.toDateString() === today.toDateString();

  if (isToday) {
    return (
      'Today at ' +
      dateObj.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      })
    );
  } else {
    return (
      dateObj.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }) +
      (detail ? ' at ' : ' ') +
      (detail
        ? dateObj.toLocaleTimeString(undefined, {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          })
        : '')
    );
  }
};

export const daysAgo = (dateUtc) => {
  // Create a date object from the UTC date string
  const date = new Date(dateUtc);
  // Get the current date
  const now = new Date();
  // Calculate the difference in milliseconds
  const diff = now.getTime() - date.getTime();
  // Convert the difference from milliseconds to days
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (days <= 0) {
    return 'Today';
  } else if (days === 1) {
    return 'Yesterday';
  } else {
    return `${days} days ago`;
  }
};

export const convertSecondsToMinutesAndSeconds = (totalSeconds) => {
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;
  return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
};

export const convertSecondsToHoursMinutes = (totalSeconds) => {
  let totalMinutes = Math.floor(totalSeconds / 60);
  let hours = Math.floor(totalSeconds / 60 / 60);
  let minutes = totalMinutes % 60;
  return hours > 0 ? `${hours}hr ${minutes}m` : `${minutes}m`;
};
