import React, { useEffect, useState, useCallback } from 'react';
import axios from 'api/axiosConfig';
import env from 'env';
import {
  ActionIcon,
  Center,
  Loader,
  TextInput,
  Button,
  Text,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconPencil } from '@tabler/icons-react';
import './OAuthEmailSetup.css';

type OAuthClient = {
  display_name: string;
  domains: string[];
  oauth_client_id: string;
  org_id: string;
  org_oauth_client_id: string;
  provider: string;
};

function emailUsesDomain(email, domain) {
  if (!email || !domain) {
    return false;
  }
  email = email.toLowerCase().trim();
  domain = domain.toLowerCase().trim();

  const parts = email.split('@');
  if (parts.length !== 2 || !parts[1]) {
    return false; // Invalid email format
  }

  const emailDomain = parts[1];
  return emailDomain === domain || emailDomain.endsWith(`.${domain}`);
}

const OAuthEmailSetup = () => {
  const [orgsToDomains, setOrgsToDomains] = useState<OAuthClient[] | null>(
    null
  );
  const [formOutreachEmail, setFormOutreachEmail] = useState<string>('');
  const [noCurrentEmail, setNoCurrentEmail] = useState<boolean | undefined>(
    undefined
  );
  const [editingEmail, setEditingEmail] = useState(false);
  const [submittingEmail, setSubmittingEmail] = useState(false);

  const emailToTenantId = useCallback(
    (email) => {
      const orgWithDomain = orgsToDomains?.find((oauthClient) =>
        oauthClient.domains.find((domain) => emailUsesDomain(email, domain))
      );
      return orgWithDomain?.oauth_client_id;
    },
    [orgsToDomains]
  );
  const orgId = localStorage.getItem('orgId');

  const isValidEmail = (email) => {
    return emailToTenantId(email) ? true : false;
  };

  const renderDomainsList = () => {
    if (orgsToDomains && orgsToDomains?.length === 0) {
      return <Text>No email providers linked</Text>;
    }
    return (
      <div className='domains-list'>
        {orgsToDomains?.map((oauthClient) => {
          return (
            <div className='domain-card'>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4 style={{ marginTop: '0px', marginBottom: '5px' }}>
                  {oauthClient.display_name}
                </h4>
                <img
                  src='imgs/outlook_logo.png'
                  alt='Button Icon'
                  style={{
                    width: '32px',
                    height: '30px',
                    position: 'relative',
                    right: '5px',
                  }}
                />
              </div>
              <div className={`scroll-container`}>
                {oauthClient.domains.map((d) => {
                  return <div style={{ color: 'gray' }}>{d}</div>;
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderAdminConsentButton = () => {
    const handleAdminConsent = () => {
      const clientId = '270099f5-ade6-4608-88c7-31a4a2a21f1c';
      const redirectUri = 'http://localhost:3000/oauth-redirect'; // Your redirect URI
      const consentUrl = `https://login.microsoftonline.com/common/adminconsent?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}`;

      window.open(consentUrl, '_blank');
    };

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '70px',
        }}
      >
        <div
          className='integration-link-container'
          onClick={handleAdminConsent}
        >
          <img
            src='imgs/outlook_logo.png'
            alt='Button Icon'
            style={{
              right: '5px',
            }}
            className='integration-link-img'
          />
          <h5 style={{ fontWeight: 'bold', padding: '10px', margin: 0 }}>
            Link Outlook
          </h5>
        </div>

        <div
          className='integration-link-container disabled'
          onClick={handleAdminConsent}
        >
          <img
            src='imgs/google_logo.png'
            alt='Button Icon'
            className='integration-link-img'
          />
          <h5 style={{ fontWeight: 'bold', padding: '10px', margin: 0 }}>
            Link Google
          </h5>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const fetchDomains = async () => {
      if (!orgId) {
        return;
      }
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/get_org_email_domains`,
        { orgId: orgId }
      );
      setOrgsToDomains(response.data.orgDomains);
    };

    const fetchOrgOutreachEmail = async () => {
      if (!orgId) {
        return;
      }
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/get_org_recruiting_email`,
        { orgId: orgId }
      );
      setFormOutreachEmail(response.data.recuitingEmail);
      setNoCurrentEmail(!response.data.recuitingEmail);
    };

    fetchDomains();
    fetchOrgOutreachEmail();
  }, [setNoCurrentEmail, orgId]);

  const updateRecruitingEmail = useCallback(() => {
    setSubmittingEmail(true);
    axios
      .post(`${env.REACT_APP_SERVER_URL}/set_org_recruiting_email`, {
        email: formOutreachEmail,
        orgId: orgId,
      })
      .then((response) => {
        if (response.status === 200) {
          setFormOutreachEmail(response.data.currentEmail || '');
          setNoCurrentEmail(!response.data.currentEmail);
          if (response.data.access === false) {
            notifications.show({
              title: 'There was an error submitting your email',
              message:
                'This email address is not accessible via the linked account providers',
              color: 'red',
            });
          } else {
            notifications.show({
              title: 'Success',
              message: 'Recruiting email address updated for your organization',
              color: 'green',
            });
          }
        }
      })
      .catch((error) => {
        setNoCurrentEmail(!formOutreachEmail);
        notifications.show({
          title: 'There was an error submitting your email',
          message: error.message,
          color: 'red',
        });
      })
      .finally(() => {
        setSubmittingEmail(false);
        setEditingEmail(false);
      });
  }, [formOutreachEmail, orgId]);

  const renderOutreachEmailForm = () => {
    return (
      <div style={{ display: 'flex', alignContent: 'center', gap: '15px' }}>
        <TextInput
          disabled={submittingEmail}
          value={formOutreachEmail}
          onChange={(event) => setFormOutreachEmail(event.currentTarget.value)}
          style={{ width: '400px' }}
        />
        <div style={{ width: '100%', alignContent: 'center' }}>
          <Button
            disabled={!isValidEmail(formOutreachEmail) || submittingEmail}
            onClick={updateRecruitingEmail}
          >
            Update
          </Button>
        </div>
      </div>
    );
  };

  const renderOutreachEmailValue = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ fontWeight: 'bold' }}>{formOutreachEmail}</div>
        <ActionIcon
          variant={'subtle'}
          onClick={() => setEditingEmail(true)}
          style={{ bottom: '2px', marginLeft: '3px' }}
        >
          <IconPencil size={20} />
        </ActionIcon>
      </div>
    );
  };

  const renderFormOutreachEmailArea = () => {
    return (
      <div
        style={{
          display: 'flex',
          gap: '75px',
          alignItems: 'center',
          padding: '30px 0px',
        }}
        className={(orgsToDomains?.length || 0) > 0 ? '' : 'disabled'}
      >
        <div>
          <h4 style={{ marginBottom: '0px', marginTop: '0px' }}>
            Recruiting Outreach Email
          </h4>
          <Text style={{ color: 'gray' }}>
            Email address to use for candidate outreach. Must be from one of
            your linked domains.
          </Text>
        </div>

        {editingEmail || noCurrentEmail
          ? renderOutreachEmailForm()
          : renderOutreachEmailValue()}
      </div>
    );
  };

  if (!orgsToDomains) {
    return (
      <Center p='lg'>
        <Loader size='sm' type='dots' />
      </Center>
    );
  }

  return (
    <div className='emailSettingsContainer' style={{ overflowY: 'scroll' }}>
      <div
        style={{
          background: 'white',
          borderRadius: '6px',
        }}
      >
        <h4 style={{ margin: '5px 0px' }}>Link Your Email Provider</h4>
        <Text style={{ color: 'gray', marginBottom: '25px' }}>
          Link your mailboxes with Salv to gain functionality of email sequence
          services.
        </Text>
        {renderAdminConsentButton()}
        {renderFormOutreachEmailArea()}
      </div>
      <div
        style={{
          background: 'white',
          // height: '100%',
          borderRadius: '6px',
        }}
      >
        <h4 style={{ marginBottom: '5px', marginTop: '5px' }}>
          Your Linked Accounts
        </h4>
        <Text style={{ color: 'gray', marginBottom: '15px' }}>
          Accounts and domains already linked to your organizaition.
        </Text>
        {renderDomainsList()}
      </div>
    </div>
  );
};

export default OAuthEmailSetup;
