import { useEffect, useState } from 'react';
import axios from 'axios';
import env from 'env';

import { AgencyFilterState } from './types';

export default function useAgencyFilterState(): AgencyFilterState {
  const [agencies, setAgencies] = useState<string[]>([]);
  const [selectedAgencies, setSelectedAgencies] = useState<string[]>([]);

  const clear = () => {
    setSelectedAgencies([]);
  };

  const isEmpty = selectedAgencies.length === 0;

  useEffect(() => {
    axios
      .get<{
        agencies: string[];
      }>(`${env.REACT_APP_SERVER_URL}/staffing_agencies`)
      .then((response) => setAgencies(response.data.agencies));
  }, []);

  return {
    agencies,
    selectedAgencies,
    onChangeSelectedAgencies: setSelectedAgencies,
    clear,
    isEmpty,
  };
}
