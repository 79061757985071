import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { StytchB2BProvider } from '@stytch/react/b2b';
import { StytchB2BUIClient } from '@stytch/vanilla-js/b2b';
import env from 'env';

import App from './App';
import reportWebVitals from './reportWebVitals';

// optional object for configuring SDK cookie behavior, currently showing defaults
const stytchOptions = {
  cookieOptions: {
    opaqueTokenCookieName: 'stytch_session',
    jwtCookieName: 'stytch_session_jwt',
    path: '',
    availableToSubdomains: false,
    domain: '',
  },
  emailMagicLinksOptions: {
    loginRedirectURL: 'https://example.com/authenticate',
    discoveryRedirectURL: 'https://example.com/authenticate',
  },
};

const stytch = new StytchB2BUIClient(
  env.REACT_APP_STYTCH_PUBLIC_TOKEN,
  stytchOptions
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StytchB2BProvider stytch={stytch}>
      <App />
    </StytchB2BProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
