import React, { useState, useEffect } from 'react';
import { Tabs, Loader } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconSettings } from '@tabler/icons-react';
import OAuthEmailSetup from 'pages/email-settings/OAuthEmailSetup';
import UserSettingsPage from 'pages/admin/UserSettingsPage';

import './AdminTabsPage.css';
import 'css/common.css';

const TabOptions = {
  USERS: 'users',
  EMAIL: 'email',
  LOADER: 'loader',
} as const;

type Tab = (typeof TabOptions)[keyof typeof TabOptions];

const isTab = (value: any): value is Tab => {
  return Object.values(TabOptions).includes(value);
};

const AdminTabsPage = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab: Tab = isTab(queryParams.get('tab') as Tab)
    ? (queryParams.get('tab') as Tab)
    : TabOptions.USERS;

  const [activeTab, setActiveTab] = useState<Tab>(initialTab);

  useEffect(() => {
    if (
      activeTab !== TabOptions.LOADER &&
      location.pathname.startsWith('/settings')
    ) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('tab', activeTab);
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [activeTab, navigate, location.pathname, location.search]);

  return (
    <div
      className='page-container-common'
      style={{
        display: 'flex',
        flexDirection: 'column',
        // backgroundColor: '#f0f0f0',
      }}
    >
      <Tabs
        variant='pills'
        value={activeTab}
        onChange={(value) => {
          if (isTab(value)) {
            setActiveTab(value);
          }
        }}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            flex: 1,
            overflow: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <IconSettings size={20} color='#4a564d' />
            <h4 style={{ marginBottom: '0px', marginTop: '0px' }}>Settings</h4>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '14px',
            }}
          >
            <Tabs.List>
              <Tabs.Tab
                value={TabOptions.USERS}
                classNames={{
                  tab: 'header-tab', // Add a custom class name for this tab
                }}
              >
                Manage Users
              </Tabs.Tab>
              <Tabs.Tab
                value={TabOptions.EMAIL}
                classNames={{
                  tab: 'header-tab', // Add a custom class name for this tab
                }}
              >
                Email Settings
              </Tabs.Tab>
            </Tabs.List>
          </div>
          <Tabs.Panel
            value={TabOptions.USERS}
            style={{ height: 'calc(100% - 90px)', padding: 0, margin: 0 }}
          >
            <UserSettingsPage />
          </Tabs.Panel>

          <Tabs.Panel
            value={TabOptions.EMAIL}
            style={{
              height: 'calc(100% - 90px)',
              overflow: 'scroll',
              padding: 0,
              margin: 0,
            }}
          >
            <OAuthEmailSetup />
          </Tabs.Panel>

          <Tabs.Panel
            value={TabOptions.LOADER}
            style={{
              height: 'calc(100% - 90px)',
              overflow: 'scroll',
              padding: 0,
              margin: 0,
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Loader type='dots' style={{ marginTop: '0%' }} />
            </div>
          </Tabs.Panel>
        </div>
      </Tabs>
    </div>
  );
};

export default AdminTabsPage;
