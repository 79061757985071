import React from 'react';
import {
  Group,
  NumberInput,
  SimpleGrid,
  Stack,
  Switch,
  TextInput,
  Select,
} from '@mantine/core';

import IndeedJobsField from './IndeedJobsField';

const CallerSettingsStep = ({ campaignSettings, campaignId }) => {
  const email = localStorage.getItem('email') || '';
  const isInternal = email.includes('salv.ai');

  return (
    <Stack justify='space-between' h='100%'>
      <>
        <SimpleGrid cols={1} style={{ width: '80%', minWidth: '400px' }}>
          <Stack>
            <TextInput
              label='Email for campaign results'
              placeholder='Your email'
              required
              {...campaignSettings.getInputProps('email')}
            />
            {isInternal && (
              <div>
                <IndeedJobsField
                  campaignId={campaignId}
                  campaignSettings={campaignSettings}
                />
              </div>
            )}
            {isInternal && (
              <>
                <TextInput
                  label='Call From Number:'
                  {...campaignSettings.getInputProps('callFromNumber')}
                />
              </>
            )}
            <Select
              label='Voice'
              data={[
                'Lisa (American)',
                'George (American)',
                'Abby (British)',
                'William (British)',
              ]}
              placeholder='Select a voice'
              nothingFoundMessage='No matching voices'
              {...campaignSettings.getInputProps('voiceName')}
              allowDeselect={false}
            />
            <Group
              style={{
                border: '1px solid #80808066',
                padding: '16px',
                borderRadius: '12px',
                marginTop: '20px',
              }}
            >
              <Switch
                label='Auto-reschedule on voicemail'
                checked={campaignSettings.values.rescheduleCallOnVoicemail}
                {...campaignSettings.getInputProps('rescheduleCallOnVoicemail')}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  gap: '20px',
                }}
              >
                <NumberInput
                  w={80}
                  label='# of retries'
                  disabled={!campaignSettings.values.rescheduleCallOnVoicemail}
                  min={1}
                  max={5}
                  allowDecimal={false}
                  {...campaignSettings.getInputProps('callRetryCount')}
                />
                <NumberInput
                  w={100}
                  label='Call again after'
                  disabled={!campaignSettings.values.rescheduleCallOnVoicemail}
                  allowNegative={false}
                  rightSection='hours'
                  rightSectionWidth='max-content'
                  {...campaignSettings.getInputProps('callRetryHours')}
                />
              </div>
            </Group>
          </Stack>
        </SimpleGrid>
      </>
    </Stack>
  );
};

export default CallerSettingsStep;
