import { useState } from 'react';

import { RevenueFilterState, RevenueRange } from './types';

export default function useRevenueFilterState(): RevenueFilterState {
  const [selectedRevenueRanges, setSelectedRevenueRanges] = useState<
    RevenueRange[]
  >([]);

  const clear = () => {
    setSelectedRevenueRanges([]);
  };

  const isEmpty = selectedRevenueRanges.length === 0;

  return {
    selectedRevenueRanges,
    onChangeSelectedRevenueRanges: setSelectedRevenueRanges,
    clear,
    isEmpty,
  };
}
