import { useState } from 'react';

import { DateFilterState, DateSelectionMode } from './types';

const DEFAULT_DATE_SELECTION_MODE: DateSelectionMode = 'after';

export default function useDateFilterState(): DateFilterState {
  const [selectionMode, setSelectionMode] = useState<DateSelectionMode>(
    DEFAULT_DATE_SELECTION_MODE
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const clear = () => {
    setSelectionMode(DEFAULT_DATE_SELECTION_MODE);
    setStartDate(null);
    setEndDate(null);
  };

  const isEmpty = (() => {
    switch (selectionMode) {
      case 'before':
        return !endDate;
      case 'after':
        return !startDate;
      case 'between':
        return !startDate || !endDate;
    }
  })();

  return {
    selectionMode,
    onChangeSelectionMode: setSelectionMode,
    startDate,
    onChangeStartDate: setStartDate,
    endDate,
    onChangeEndDate: setEndDate,
    clear,
    isEmpty,
  };
}
