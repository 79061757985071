import React from 'react';
import { Button, Menu, rem } from '@mantine/core';
import { IconTrash, IconCopy, IconMail, IconCancel } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import {
  deleteCampaign,
  duplicateScript,
  sendResultsEmail,
  cancelPendingCalls,
} from 'pages/scripts/actions';
interface MenuDropdownProps {
  campaignId: string;
}

const MenuDropdown: React.FC<MenuDropdownProps> = ({ campaignId }) => {
  const handleSendResultsEmail = () => {
    sendResultsEmail(campaignId)
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Results email sent successfully!',
          color: 'green',
        });
      })
      .catch((error) => {
        showNotification({
          title: 'There was an error sending the results email',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      });
  };

  const handleDeleteCampaign = () => {
    deleteCampaign(campaignId)
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Campaign deleted successfully!',
          color: 'green',
        });
      })
      .catch((error) => {
        showNotification({
          title: 'There was an error deleting the campaign',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      });
  };

  const handleCancelPendingCalls = () => {
    cancelPendingCalls(campaignId)
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Pending calls cancelled!',
          color: 'green',
        });
      })
      .catch((error) => {
        showNotification({
          title: 'There was an error cancelling the pending calls',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      });
  };

  const handleDuplicateScript = () => {
    duplicateScript(campaignId)
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Script duplicated successfully!',
          color: 'green',
        });
      })
      .catch((error) => {
        showNotification({
          title: 'There was an error duplicating the script',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      });
  };

  return (
    <Menu shadow='md' width={200} onChange={() => {}}>
      <Menu.Target>
        <Button
          size='xs'
          variant='light'
          style={{ marginLeft: '20px', borderRadius: '24px' }}
        >
          More
          {/* <IconDots /> */}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{'More Actions'}</Menu.Label>
        <Menu.Item
          leftSection={<IconMail style={{ width: rem(14), height: rem(14) }} />}
          onClick={handleSendResultsEmail}
        >
          Send Results Email
        </Menu.Item>

        <Menu.Item
          leftSection={<IconCopy style={{ width: rem(14), height: rem(14) }} />}
          onClick={handleDuplicateScript}
        >
          Duplicate Sequence
        </Menu.Item>
        <Menu.Item
          color='yellow'
          leftSection={
            <IconCancel style={{ width: rem(14), height: rem(14) }} />
          }
          onClick={handleCancelPendingCalls}
        >
          Cancel Pending Calls
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          color='red'
          leftSection={
            <IconTrash style={{ width: rem(14), height: rem(14) }} />
          }
          onClick={handleDeleteCampaign}
        >
          Delete Campaign
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default MenuDropdown;
