import React from 'react';
import { Group, Menu, Radio, Stack } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { TablerIcon } from '@tabler/icons-react';
import dayjs from 'dayjs';

import FilterPill from './FilterPill';
import { DateFilterState, DateSelectionMode } from './types';

function formatDate(date: Date): string {
  return dayjs(date).format('M/D/YY');
}

interface ComponentProps {
  title: string;
  Icon?: TablerIcon;
  onClear: () => void;
  onClose: () => void;
  dateFilterState: DateFilterState;
}

export default function DateFilter({
  title,
  Icon,
  onClear,
  onClose,
  dateFilterState: {
    selectionMode,
    onChangeSelectionMode,
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate,
  },
}: ComponentProps) {
  const filterLabel = (() => {
    switch (selectionMode) {
      case 'before':
        return endDate ? `Before ${formatDate(endDate)}` : undefined;
      case 'after':
        return startDate ? `After ${formatDate(startDate)}` : undefined;
      case 'between':
        return endDate && startDate
          ? `${formatDate(startDate)} - ${formatDate(endDate)}`
          : undefined;
    }
  })();

  const handleChangeSelectionMode = (value: string) => {
    const mode = value as DateSelectionMode;
    if (mode === 'before') {
      if (!endDate) onChangeEndDate(startDate);
      onChangeStartDate(null);
    } else if (mode === 'after') {
      if (!startDate) onChangeStartDate(endDate);
      onChangeEndDate(null);
    }

    onChangeSelectionMode(mode);
  };

  const dateValue: Date | null | [Date | null, Date | null] = (() => {
    switch (selectionMode) {
      case 'before':
        return endDate;
      case 'after':
        return startDate;
      case 'between':
        return [startDate, endDate];
    }
  })();

  const handleChangeDate = (date: Date | null | [Date | null, Date | null]) => {
    if (Array.isArray(date)) {
      const [start, end] = date;
      onChangeStartDate(start);
      onChangeEndDate(end);
    } else {
      if (selectionMode === 'before') onChangeEndDate(date);
      if (selectionMode === 'after') onChangeStartDate(date);
    }
  };

  return (
    <FilterPill
      title={title}
      Icon={Icon}
      label={filterLabel}
      onClear={onClear}
      onClose={onClose}
    >
      <Stack gap='xs' p='sm'>
        <Radio.Group value={selectionMode} onChange={handleChangeSelectionMode}>
          <Group>
            <Radio
              label='Before'
              value='before'
              style={{ cursor: 'pointer' }}
            />
            <Radio
              label='Between'
              value='between'
              style={{ cursor: 'pointer' }}
            />
            <Radio label='After' value='after' style={{ cursor: 'pointer' }} />
          </Group>
        </Radio.Group>
        <Menu.Divider />
        <DatePicker
          key={selectionMode}
          type={selectionMode === 'between' ? 'range' : 'default'}
          value={dateValue}
          onChange={handleChangeDate}
          firstDayOfWeek={0}
          maxDate={dayjs().endOf('day').toDate()}
        />
      </Stack>
    </FilterPill>
  );
}
