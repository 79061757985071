import React, { useState, useEffect, useCallback } from 'react';
import { Button, Switch, Tabs, Loader } from '@mantine/core';
import axios from 'axios';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  IconAlertCircle,
  IconClipboardList,
  IconRocket,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import AddContactsModal from 'components/campaign/AddContactsModal';

import { pauseCampaign, startCampaign } from './actions';
import CampaignMenuDropdown from './components/CampaignMenuDropdown';
import EditorPageV2 from '../editorv2/EditorPageV2';
import CampaignDetailsPage from '../campaign/details/CampaignDetailsPage';
import env from '../../env';
import './ScriptTabsPage.css';
import 'css/common.css';

const TabOptions = {
  SEQUENCE: 'sequence',
  CONTACTS: 'contacts',
  LOADER: 'loader',
} as const;

type Tab = (typeof TabOptions)[keyof typeof TabOptions];

const isTab = (value: any): value is Tab => {
  return Object.values(TabOptions).includes(value);
};

const ScriptTabsPage = () => {
  const [campaignDetailsKey, setCampaignDetailsKey] = useState(0);
  const [canSubmitCampaign, setCanSubmitCampaign] = useState<boolean>(false);

  const handleContactsRefresh = () => {
    setCampaignDetailsKey((prevKey) => prevKey + 1);
  };

  const navigate = useNavigate();

  const location = useLocation();
  const match = location.pathname.match(
    /\/scripts\/script-editor\/(new\/)?([^/]+)/
  );
  const { campaignId: paramCampaignId } = useParams();
  const campaignId = match ? match[2] : paramCampaignId || '';

  const queryParams = new URLSearchParams(location.search);
  const initialTab: Tab = isTab(queryParams.get('tab') as Tab)
    ? (queryParams.get('tab') as Tab)
    : TabOptions.SEQUENCE;

  const [activeTab, setActiveTab] = useState<Tab>(TabOptions.LOADER);
  const [isCampaignActive, setIsCampaignActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contactsModalOpen, setContactsModalOpen] = useState(false);
  const [jobTitle, setJobTitle] = useState<string>('');
  const [scriptFetched, setScriptFetched] = useState<boolean>(false);
  const fetchCampaignStatus = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/campaigns/${campaignId}/status`
      );
      setIsCampaignActive(
        response.data.status === 'ACTIVE' || response.data.status === 'PENDING'
      );
    } catch (error) {
      console.error('Failed to fetch campaign status', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch campaign status',
        color: 'red',
        icon: <IconAlertCircle size={18} />,
      });
    } finally {
      setIsLoading(false);
    }
  }, [campaignId]);

  useEffect(() => {
    if (scriptFetched) {
      setActiveTab(initialTab);
    }
  }, [scriptFetched, initialTab]);

  const handleCampaignToggle = async () => {
    try {
      setIsLoading(true);
      if (isCampaignActive) {
        await pauseCampaign(campaignId);
        setIsCampaignActive(false);
        notifications.show({
          title: 'Campaign Paused',
          message: 'The campaign has been paused successfully.',
          color: 'yellow',
        });
      } else {
        await startCampaign(campaignId);
        setIsCampaignActive(true);
        notifications.show({
          title: 'Campaign Resumed',
          message: 'The campaign has been resumed successfully.',
          color: 'green',
        });
      }
    } catch (error) {
      console.error('Failed to toggle campaign status', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to toggle campaign status',
        color: 'red',
        icon: <IconAlertCircle size={18} />,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab !== TabOptions.LOADER) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('tab', activeTab);
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [activeTab, navigate, location.pathname, location.search]);

  useEffect(() => {
    fetchCampaignStatus();
  }, [fetchCampaignStatus]);

  return (
    <div
      className='page-container-common'
      style={{
        display: 'flex',
        flexDirection: 'column',
        // backgroundColor: '#f0f0f0',
      }}
    >
      <Tabs
        color='gray'
        variant='pills'
        value={activeTab}
        onChange={(value) => {
          if (isTab(value)) {
            setActiveTab(value);
          }
        }}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            flex: 1,
            overflow: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              visibility: scriptFetched ? 'visible' : 'hidden',
            }}
          >
            <IconClipboardList size={20} color='#4a564d' />
            <h5
              style={{ marginBottom: '0px', marginTop: '0px' }}
              className='nav-link-text'
              onClick={() => {
                navigate('/scripts');
              }}
            >
              Jobs
            </h5>
            <h5 style={{ marginBottom: '0px', marginTop: '0px' }}>{'>'}</h5>
            <h5 style={{ marginBottom: '0px', marginTop: '0px' }}>
              {jobTitle ? jobTitle : 'Untitled'}
            </h5>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '14px',
              visibility: scriptFetched ? 'visible' : 'hidden',
            }}
          >
            <Tabs.List>
              <Tabs.Tab
                value='sequence'
                classNames={{
                  tab: 'header-tab', // Add a custom class name for this tab
                }}
              >
                Sequence
              </Tabs.Tab>
              <Tabs.Tab
                value='contacts'
                classNames={{
                  tab: 'header-tab', // Add a custom class name for this tab
                }}
              >
                Contacts
              </Tabs.Tab>
            </Tabs.List>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CampaignMenuDropdown campaignId={campaignId} />
              <Button
                size='xs'
                variant='light'
                style={{
                  marginLeft: '14px',
                  borderRadius: '24px',
                }}
                onClick={() => setContactsModalOpen(true)}
                leftSection={<IconRocket size={20} />}
                disabled={!canSubmitCampaign}
              >
                Add Contacts
              </Button>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '14px',
                }}
              >
                <Switch
                  checked={isCampaignActive}
                  onChange={handleCampaignToggle}
                  // label={isCampaignActive ? 'Active' : 'Paused'}
                  disabled={
                    isLoading || (!isCampaignActive && !canSubmitCampaign)
                  }
                  size='md'
                  // style={{ marginLeft: '20px' }}
                />
                <span
                  style={{
                    marginLeft: '10px',
                    color: isCampaignActive
                      ? 'var(--mantine-color-blue-4)'
                      : 'var(--mantine-color-gray-4)',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  {isCampaignActive ? 'Active' : 'Paused'}
                </span>
              </div>
            </div>
          </div>
          {/* When i set to 50px, buttons look better BUT then becomes scrollable. TODO: fix */}
          <Tabs.Panel value='sequence' style={{ height: 'calc(100% - 90px)' }}>
            <EditorPageV2
              setScriptTitle={setJobTitle}
              scriptFetched={scriptFetched}
              setScriptFetched={setScriptFetched}
              isCampaignActive={isCampaignActive}
              setIsCampaignActive={setIsCampaignActive}
              handleContactsRefresh={handleContactsRefresh}
              canSubmitCampaign={canSubmitCampaign}
              setCanSubmitCampaign={setCanSubmitCampaign}
            />
          </Tabs.Panel>

          <Tabs.Panel
            value='contacts'
            style={{ height: 'calc(100% - 90px)', overflow: 'scroll' }}
          >
            <CampaignDetailsPage key={campaignDetailsKey} />
          </Tabs.Panel>

          <Tabs.Panel
            value='loader'
            style={{ height: 'calc(100% - 90px)', overflow: 'scroll' }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Loader type='dots' style={{ marginTop: '0%' }} />
            </div>
          </Tabs.Panel>
        </div>
      </Tabs>
      <AddContactsModal
        campaignModalOpen={contactsModalOpen}
        setCampaignModalOpen={setContactsModalOpen}
        campaignId={campaignId}
        isCampaignActive={isCampaignActive}
        setIsCampaignActive={setIsCampaignActive}
        handleContactsRefresh={handleContactsRefresh}
      />
    </div>
  );
};

export default ScriptTabsPage;
