import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Divider,
  TextInput,
  CloseButton,
  Switch,
  Skeleton,
} from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { setCampaignJobs, getCampaignJobs } from 'pages/editorv2/actions';
import { showNotification } from '@mantine/notifications';
import { IndeedJob } from 'pages/editorv2//types';

const IndeedJobsField = ({ campaignId, campaignSettings }) => {
  const email = localStorage.getItem('email') || '';
  const isInternal = email.includes('salv.ai');
  const callNewApplicants = true; // not sure if we want this editable
  const [editStateDone, setEditStateDone] = useState(false);

  const [editedJobs, setEditedJobs] = useState<IndeedJob[]>([]);
  const [serverJobs, setServerJobs] = useState<IndeedJob[]>([]); // the truth from the server not the edit state
  const [modalOpen, setModalOpen] = useState(false);

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleUpdateCampaignJobs = (jobs: IndeedJob[]) => {
    setSaving(true);
    setCampaignJobs(jobs, campaignId)
      .then((updatedJobs) => {
        setEditedJobs(updatedJobs);
        setServerJobs(updatedJobs);
        showNotification({
          title: 'Success',
          message: 'Campaign jobs updated successfully!',
          color: 'green',
        });
      })
      .catch((error) => {
        console.error('Error updating campaign jobs:', error);
        showNotification({
          title: 'There was an error updating campaign jobs',
          message:
            error.response?.data?.message || error.message || 'Unknown error',
          color: 'red',
        });
      })
      .finally(() => {
        setModalOpen(false);
        setSaving(false);
      });
  };

  useEffect(() => {
    if (!campaignId) {
      return;
    }

    getCampaignJobs(campaignId)
      .then((jobs) => {
        setEditedJobs(jobs);
        setServerJobs(jobs);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching campaign jobs:', error);
        showNotification({
          title: 'There was an error fetching campaign jobs',
          message:
            error.response?.data?.message || error.message || 'Unknown error',
          color: 'red',
        });
      });
  }, [campaignId]);

  useEffect(() => {
    const hasEmptyProperty = editedJobs.some((job) =>
      Object.values(job).some((value) => value === '')
    );
    setEditStateDone(!hasEmptyProperty);
  }, [editedJobs]);

  const updateCityAtIndex = (index, newValue) => {
    const updatedArray = [...editedJobs];
    const updatedObject = { ...updatedArray[index], jobCity: newValue };
    updatedArray[index] = updatedObject;
    setEditedJobs(updatedArray);
  };

  const updateStateAtIndex = (index, newValue) => {
    const updatedArray = [...editedJobs];
    const updatedObject = { ...updatedArray[index], jobState: newValue };
    updatedArray[index] = updatedObject;
    setEditedJobs(updatedArray);
  };

  const updateTitleAtIndex = (index, newValue) => {
    const updatedArray = [...editedJobs];
    const updatedObject = { ...updatedArray[index], jobTitle: newValue };
    updatedArray[index] = updatedObject;
    setEditedJobs(updatedArray);
  };

  const addJob = () => {
    const newObj = [
      {
        jobCity: '',
        jobState: '',
        jobTitle: '',
      },
    ];
    setEditedJobs([...editedJobs, ...newObj]);
  };

  const handleRemove = (index) => {
    const newArray = [...editedJobs];
    newArray.splice(index, 1);
    setEditedJobs([...newArray]);
  };

  const renderJob = (j) => {
    return (
      <div style={{ display: 'flex' }}>
        <h5 style={{ fontWeight: 'bold', margin: '0px', marginRight: '6px' }}>
          {j.jobTitle}
        </h5>
        <h5 style={{ margin: '0px' }}>
          {j.jobCity}
          {', '}
          {j.jobState}
        </h5>
      </div>
    );
  };

  return isInternal ? (
    <div
      style={{
        border: '1px solid #80808066',
        borderRadius: '8px',
        padding: '12px',
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
      }}
    >
      <Modal
        opened={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        size='md'
        style={{ height: '80vh' }}
        title='Configure Indeed Application Calling'
      >
        <>
          {editedJobs?.map((job, idx) => (
            <div style={{ display: 'flex' }}>
              <div>
                <TextInput
                  style={{ marginBottom: '10px' }}
                  label={
                    idx === 0
                      ? 'Indeed Job Title, City, State (must match exactly)'
                      : `Indeed Job ${idx + 1}`
                  }
                  value={job.jobTitle}
                  placeholder='CNC Machinist'
                  onChange={(e) => updateTitleAtIndex(idx, e.target.value)}
                  disabled={!callNewApplicants}
                  required
                />
                <div style={{ display: 'flex' }}>
                  <TextInput
                    style={{
                      marginBottom: '10px',
                      width: '80%',
                      marginRight: '4px',
                    }}
                    label=''
                    placeholder='Greenville'
                    value={job.jobCity}
                    onChange={(e) => updateCityAtIndex(idx, e.target.value)}
                    disabled={!callNewApplicants}
                    required
                  />
                  <TextInput
                    style={{
                      marginBottom: '10px',
                      width: '20%',
                    }}
                    label=''
                    placeholder='SC'
                    value={job.jobState}
                    onChange={(e) => updateStateAtIndex(idx, e.target.value)}
                    disabled={!callNewApplicants}
                    required
                  />
                </div>
                <Divider my='md' />
              </div>
              <div style={{ alignContent: 'center', paddingLeft: '15px' }}>
                <CloseButton onClick={() => handleRemove(idx)} />
              </div>
            </div>
          ))}
          <div>
            <Button
              onClick={() => addJob()}
              leftSection={<IconPlus size={20} />}
              w='max-content'
              variant='outline'
            >
              Add Job
            </Button>
          </div>

          <div style={{ textAlign: 'right', marginTop: '20px' }}>
            <Button
              onClick={() => {
                handleUpdateCampaignJobs(editedJobs);
              }}
              disabled={saving || !editStateDone}
              style={{ opacity: saving || !editStateDone ? 0.5 : 1.0 }}
            >
              Save
            </Button>
          </div>
        </>
      </Modal>
      {/* <Checkbox
        style={{
          marginBottom: '10px',
          marginTop: '0px',
        }}
        label='Call New Indeed Applicants'
        {...campaignSettings.getInputProps('callNewApplicants')}
      /> */}
      <Switch
        label='Call New Indeed Applicants'
        checked={campaignSettings.values.callNewApplicants}
        {...campaignSettings.getInputProps('callNewApplicants')}
      />
      <div
        style={{ opacity: campaignSettings.values.callNewApplicants ? 1 : 0.5 }}
      >
        {loading && (
          <>
            <Skeleton height={10} mt={6} radius='xl' width='70%' />
            <Skeleton height={10} mt={6} width='50%' radius='xl' />
          </>
        )}
        {!loading && serverJobs?.length === 0 && (
          <h5 style={{ marginTop: '0px', marginBottom: '12px' }}>
            {' '}
            No jobs associated yet
          </h5>
        )}
        {serverJobs.map((j) => (
          <div>{renderJob(j)}</div>
        ))}
        <div style={{ marginTop: '8px' }}>
          <Button
            size='xs'
            onClick={() => setModalOpen(true)}
            variant='outline'
            disabled={!campaignSettings.values.callNewApplicants}
          >
            Edit Associated Indeed Jobs
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default IndeedJobsField;
