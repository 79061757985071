// Set these values for local development connecting to a locally running server connected to DEV (staging) resources
export const env = {
  HOST: 'localhost',
  REACT_APP_SERVER_URL: 'http://localhost:8000',
  REACT_APP_WS_URL: 'ws://localhost:8000',
  REACT_APP_STYTCH_PROJECT_ENV: 'test',
  REACT_APP_STYTCH_REDIRECT_URL: 'http://localhost:3000/authenticate',
  REACT_APP_STYTCH_PUBLIC_TOKEN:
    'public-token-test-95ca1437-a1fd-404c-961e-b632b4446c81',
};
