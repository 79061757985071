import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import React, { useEffect, useState } from 'react';
import { fileTypeFromBuffer } from 'file-type';

import '@react-pdf-viewer/core/lib/styles/index.css';
import FilePreview from './FilePreview.jsx';

import './FilePreview.css';

const PDFViewer = ({ fileUrl, backupText }) => {
  const [loadState, setLoadState] = useState('');
  const [fileType, setFileType] = useState('');

  useEffect(() => {
    const fetchAndConvertFile = async () => {
      if (fileUrl) {
        try {
          const response = await fetch(fileUrl);
          const arrayBuffer = await response.arrayBuffer();
          const fileType = await fileTypeFromBuffer(arrayBuffer);
          if (fileType) {
            setFileType(fileType.ext);
          }
          setLoadState('success');
        } catch (error) {
          console.error('Error reading file type', error);
          setLoadState('failure');
        }
      }
    };

    fetchAndConvertFile();
  }, [fileUrl]);

  const renderPreview = () => {
    return (
      <div
        style={{
          fontSize: '0.9vw',
          background: 'white',
          padding: '10px',
          borderRadius: '12px',
        }}
        className='file-preview'
      >
        <FilePreview fileUrl={fileUrl} backupText={backupText} />
      </div>
    );
  };
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        marginTop: '5px',
        height: 'calc(100% - 75px)', // something weird is happening with the pdfs getting cut off but this is a good enough workaround for now
      }}
    >
      {fileType === 'pdf' || !loadState ? (
        <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
          <Viewer fileUrl={fileUrl} renderError={renderPreview} />
        </Worker>
      ) : (
        <div>{renderPreview()}</div>
      )}
    </div>
  );
};

export default PDFViewer;
