import React, { useState } from 'react';
import { Card, TextInput, Container, Center } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './CustomStytchLoginPage.css';
import './LoginCommon.css';
import { useStytchB2BClient } from '@stytch/react/b2b';

import LoginButton from './LoginButton';
import { setLocalStorageFromEmail } from '../../utils/userInfoUtils';
import { clearLocalStorage } from '../../utils/userInfoUtils';

const CustomStytchLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const [emailError, setEmailError] = useState(false);
  const stytch = useStytchB2BClient();

  const validateEmail = (email) => {
    // Basic email regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getUserInfo = async () => {
    setEmailError(false);
    if (!validateEmail) {
      alert('enter a valid email');
      setEmailError(true);
      return;
    }
    try {
      await setLocalStorageFromEmail(email);
      navigate(`/`);
      window.location.reload();
    } catch (error) {
      alert('failed to get user from server');
      console.error(error);
    }
  };

  const renderEmailEnter = () => {
    return (
      <>
        <h2 className='card-header'>Super User Access</h2>
        <h4 className='card-header'>Enter Email To Become</h4>
        <TextInput
          label='Email'
          placeholder='Enter user email'
          required
          style={{ width: '100%' }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={emailError && !validateEmail(email)}
          mb='md'
          size='md'
        />
        <LoginButton disabled={false} onClick={getUserInfo}>
          Continue
        </LoginButton>
        <br /> <br />
        <p
          className='clickable-text small-text'
          onClick={async () => {
            await stytch.session.revoke();
            clearLocalStorage();
          }}
        >
          Back to login
        </p>
      </>
    );
  };

  return (
    <div className='background-image'>
      <Container style={{ height: '100vh' }}>
        <Center style={{ height: '90%' }}>
          <Card className='login-card' style={{ border: '3px solid #f80000' }}>
            {renderEmailEnter()}
          </Card>
        </Center>
      </Container>
    </div>
  );
};

export default CustomStytchLogin;
