import React from 'react';
import {
  Group,
  Input,
  NumberInput,
  Slider,
  Stack,
  Menu,
  Button,
  Text,
} from '@mantine/core';

import { GoogleMapsPlaceWithDescription } from '../../../components/location-autocomplete/types';
import LocationAutocomplete from '../../../components/location-autocomplete/LocationAutocomplete';
import { isState } from '../../../components/location-autocomplete/utils';

const MIN_RADIUS = 1;
const MAX_RADIUS = 200;

interface LocationFilterState {
  selectedLocation?: GoogleMapsPlaceWithDescription;
  onChangeLocation: (location?: GoogleMapsPlaceWithDescription) => void;
  radius: number;
  onChangeRadius: (radius: number) => void;
}

interface ComponentProps {
  onClear: () => void;
  onClose: () => void;
  locationFilterState: LocationFilterState;
  pill: boolean;
  disabled?: boolean;
}

export default function LocationFilter({
  pill,
  onClear,
  onClose,
  locationFilterState: {
    selectedLocation,
    onChangeLocation,
    radius,
    onChangeRadius,
  },
  disabled,
}: ComponentProps) {
  const filterLabel =
    radius && selectedLocation
      ? `${radius}mi - ${selectedLocation.description.split(',').slice(0, 2).join(',')}`
      : undefined;

  const stateSelected: boolean =
    !!selectedLocation && isState(selectedLocation);

  const handleChangeRadius = (value: string | number) => {
    onChangeRadius(Number(value));
  };

  const renderStack = () => {
    return (
      <Stack p='sm' w={280}>
        <LocationAutocomplete
          selectedPlace={selectedLocation}
          onPlaceSelected={onChangeLocation}
        />
        <Stack gap={0}>
          <Group justify='space-between'>
            <Input.Label>Within radius</Input.Label>
            <NumberInput
              w='50%'
              suffix='mi'
              min={MIN_RADIUS}
              max={MAX_RADIUS}
              value={radius}
              onChange={handleChangeRadius}
              disabled={stateSelected}
            />
          </Group>
          <Slider
            mb='lg'
            min={MIN_RADIUS}
            max={MAX_RADIUS}
            marks={[
              { value: 50, label: '50mi' },
              { value: 100, label: '100mi' },
              { value: 150, label: '150mi' },
            ]}
            value={radius}
            onChange={onChangeRadius}
            disabled={stateSelected}
          />
        </Stack>
      </Stack>
    );
  };

  if (!pill) {
    return <>{renderStack()} </>;
  }

  return (
    <Menu position='bottom-start' onClose={onClose}>
      <Menu.Target>
        <Button
          variant='default'
          radius='xl'
          size='xs'
          style={{ opacity: disabled ? 0.5 : 1.0 }}
          disabled={disabled}
        >
          <Group gap={4} align='center'>
            {'Distance'}
            {filterLabel && (
              <Text c='dimmed' size='sm'>
                {filterLabel}
              </Text>
            )}
          </Group>
        </Button>
      </Menu.Target>

      <Menu.Dropdown>{renderStack()}</Menu.Dropdown>
    </Menu>
  );
}
