import React from 'react';
import {
  IconChevronDown,
  IconChevronUp,
  IconSelector,
  TablerIcon,
} from '@tabler/icons-react';
import { Center, Group, Table, Text, UnstyledButton } from '@mantine/core';
import classNames from 'classnames';

import classes from './SortableHeader.module.css';
import './SortableHeader.module.css';

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
  style?: React.CSSProperties;
}

export default function SortableHeader({
  children,
  reversed,
  sorted,
  onSort,
  style,
}: ThProps) {
  const Icon: TablerIcon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;

  // mantine stylings added here for consistency with other th in the app
  const mantineDefaultThStyling = {
    padding:
      'var(--table-vertical-spacing) var(--table-horizontal-spacing, var(--mantine-spacing-xs))',
    ...style,
  };

  return (
    <Table.Th style={mantineDefaultThStyling} className={classes.th}>
      <UnstyledButton
        onClick={onSort}
        className={classNames(classes.control, sorted && classes.sorted)}
      >
        <Group
          justify='space-between'
          w='max-content'
          className={classes.hidden}
        >
          <Text fz='sm'>{children}</Text>
          <Center className={classes.icon}>
            <Icon size={16} />
          </Center>
        </Group>

        <Group justify='space-between' w='max-content' style={{ gap: '4px' }}>
          <Text fz='sm'>{children}</Text>
          <Center className={classes.icon}>
            <Icon size={16} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}
