import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDebouncedValue } from '@mantine/hooks';
import {
  ActionIcon,
  Center,
  Loader,
  Menu,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import env from 'env';

import { Client, ClientFilterState } from './types';
import FilterPill from './FilterPill';
import classes from './ClientFilter.module.css';

interface ComponentProps {
  onClear: () => void;
  onClose: () => void;
  clientFilterState: ClientFilterState;
}

export default function ClientFilter({
  onClear,
  onClose,
  clientFilterState: { selectedClient, onChangeClient },
}: ComponentProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const [debouncedSearchValue] = useDebouncedValue(searchValue, 500);

  useEffect(() => {
    setLoading(true);
    setClients([]);
    axios
      .get(`${env.REACT_APP_SERVER_URL}/clients`, {
        params: { query: debouncedSearchValue },
      })
      .then((response) => {
        setClients(response.data);
      })
      .finally(() => setLoading(false));
  }, [debouncedSearchValue]);

  const handleSelectClient = (client: Client | undefined) => () => {
    onChangeClient(client);
    setSearchValue(client?.company_name || '');
  };

  return (
    <FilterPill
      title='Client'
      label={selectedClient?.company_name}
      onClear={onClear}
      onClose={onClose}
    >
      <Stack w={350} gap={0} p='sm'>
        <TextInput
          size='sm'
          leftSection={<IconSearch size={16} />}
          placeholder='Search by company name...'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          rightSection={
            <ActionIcon
              variant='subtle'
              c='gray'
              size='xs'
              onClick={() => setSearchValue('')}
            >
              <IconX size={12} />
            </ActionIcon>
          }
        />
        {selectedClient && (
          <>
            <Menu.Label>Selected Client</Menu.Label>
            <Menu.Item
              className={classes.selected}
              onClick={handleSelectClient(undefined)}
            >
              {selectedClient.company_name}
            </Menu.Item>
          </>
        )}
        <Menu.Divider />
        {clients.length ? (
          clients.map((client) => (
            <Menu.Item onClick={handleSelectClient(client)}>
              {client.company_name}
            </Menu.Item>
          ))
        ) : (
          <Center p='md'>
            {loading ? (
              <Loader size='sm' />
            ) : (
              <Text size='sm' c='dimmed'>
                {searchValue.length > 3
                  ? 'Enter a search to find clients.'
                  : `No clients found for "${searchValue}"`}
              </Text>
            )}
          </Center>
        )}
      </Stack>
    </FilterPill>
  );
}
