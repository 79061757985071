import React, { useState, useRef, useMemo } from 'react';
import 'react-chat-elements/dist/main.css'; // Make sure to import the styles
import { Input } from 'react-chat-elements';
import {
  Paper,
  Text,
  Button,
  Container,
  Title,
  TextInput,
  Textarea,
  Box,
  Grid,
  Flex,
  ActionIcon,
  Modal,
  Checkbox,
} from '@mantine/core';
import { marked } from 'marked';
import './CustomMarkdown.css';
import { IconArrowsMaximize } from '@tabler/icons-react';

import { removeHighlighting } from './utils';
import SkillsQuillEditor from './SkillsQuillEditor';

function ResumeChatInput({
  sendNewMessage,
  disabled,
  gptMessages,
  jobDescription,
  setJobDescription,
  potentialJobSkills,
  setPotentialJobSkills,
  jobSkills,
  companies,
  resumeExists,
  usePartial,
  setUsePartial,
}) {
  const [message, setMessage] = useState('');
  const key = useRef(0); // Using a key to force re-render

  const [jobTitle, setJobTitle] = useState('');
  const [jobLocation, setJobLocation] = useState('');
  const [jobCompany, setJobCompany] = useState('');
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  const [skillsEditorContent, setSkillsEditorContent] = useState('');

  const handleInputChange = (event) => {
    if (disabled) {
      return;
    }
    setMessage(event.target.value);
  };

  const sendMessage = () => {
    if (disabled) {
      return;
    }
    if (message.trim()) {
      sendNewMessage(message);
      setMessage(''); // Clear the input after sending
      key.current += 1;
    }
  };

  const sendJobDescriptionMessage = () => {
    if (jobDescription.trim() && jobLocation.trim() && jobTitle.trim()) {
      sendNewMessage(
        'Location ' +
          jobLocation.trim() +
          ', Title: ' +
          jobTitle.trim() +
          ', Company: ' +
          jobCompany.trim() +
          ', Description: ' +
          jobDescription.trim(),
        {
          jobDescription: jobDescription.trim(),
          jobLocation: jobLocation,
          jobCompany: jobCompany,
        }
      );
      setMessage(''); // Clear the input after sending
      key.current += 1;
    }
  };

  const skillsContentNoHighlighting = useMemo(() => {
    return removeHighlighting(skillsEditorContent);
  }, [skillsEditorContent]);

  const sendUpdateSkillsMessage = () => {
    setUpdateModalOpen(false);
    if (jobDescription.trim() && jobLocation.trim() && jobTitle.trim()) {
      sendNewMessage('UPDATED SKILLS \n' + skillsContentNoHighlighting.trim(), {
        includedSkills: skillsContentNoHighlighting.trim(),
        originalSkills: jobSkills,
        companies: companies,
        jobDescription: jobDescription.trim(),
        jobLocation: jobLocation,
        jobCompany: jobCompany,
      });
      setMessage(''); // Clear the input after sending
      key.current += 1;
    }
  };

  const handleKeyPress = (event) => {
    if (disabled) {
      event.preventDefault();
      return;
    }
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const renderGptText = (msg) => {
    const msgJson = JSON.parse(msg);

    if ('planning' in msgJson) {
      return marked(msgJson['planning'].replace(/```/g, '')); // replace ``` so markdown renders properly
    }
    return "Resume extracted and formatted. Let me know if you'd like any other adjustments";
  };

  const renderMsgText = (msg) => {
    if (msg.role === 'user') {
      return msg.content;
    } else if (msg.role === 'assistant') {
      return renderGptText(msg.content);
    } else {
      return 'System message: ' + msg.content; // unreachable. left for debugging
    }
  };

  const gptMessagesReveresed = useMemo(() => {
    return [...gptMessages].reverse();
  }, [gptMessages]);

  const renderMessage = (msg, index) => {
    if (msg.role === 'system') {
      return <></>;
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: msg.role === 'user' ? 'flex-end' : '',
        }}
      >
        <Paper
          key={index}
          shadow='xs'
          p='md'
          style={{
            margin: '10px 0',
            width: 'fit-content',
            padding: '10px',
            backgroundColor: msg.role === 'user' ? '#80808012' : '',
          }}
        >
          <Text
            style={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}
            className={msg.role === 'assistant' ? 'markdown-content' : ''}
            dangerouslySetInnerHTML={{ __html: renderMsgText(msg) }}
          ></Text>
        </Paper>
      </div>
    );
  };

  const renderInputJobDetails = () => {
    return (
      <div style={{ overflowY: 'scroll' }}>
        <Container style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
          <Box
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ flex: '3', paddingRight: '1rem' }}>
                <h3 style={{ marginTop: '2px' }}>Start From Job Details</h3>
                <Grid>
                  <Grid.Col span={4}>
                    <TextInput
                      label='Job Title'
                      required
                      value={jobTitle}
                      placeholder='Enter the job title'
                      onChange={(e) => setJobTitle(e.target.value)}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <TextInput
                      label='Location'
                      required
                      value={jobLocation}
                      placeholder='Enter the job location'
                      onChange={(e) => setJobLocation(e.target.value)}
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <TextInput
                      label='Company'
                      required
                      value={jobCompany}
                      placeholder='Enter the company name'
                      onChange={(e) => setJobCompany(e.target.value)}
                    />
                  </Grid.Col>
                </Grid>
                <Title order={4} mt='lg' mb='md'>
                  Job Description
                </Title>
                <Textarea
                  placeholder='Enter the job description'
                  autosize
                  minRows={10}
                  maxRows={10}
                  style={{
                    overflow: 'auto',
                    height: '30vh',
                    resize: 'none',
                  }}
                  key={'jobDescription'}
                  value={jobDescription}
                  onChange={(e) => {
                    setJobDescription(e.target.value);
                  }}
                />
                <Checkbox
                  label='Modify skills before full resume generation'
                  description='2 step generation where you can modify the skills that will be included in the resume. If unchecked, will generate the resume in a single step.'
                  checked={usePartial}
                  onChange={(event) =>
                    setUsePartial(event.currentTarget.checked)
                  }
                />
                <Button
                  mt='md'
                  fullWidth
                  disabled={
                    !(jobDescription && jobTitle && jobLocation && jobCompany)
                  }
                  onClick={sendJobDescriptionMessage}
                >
                  Send
                </Button>
              </div>
            </div>
          </Box>
        </Container>
      </div>
    );
  };

  const potentialJobSkillsLowered = potentialJobSkills
    .join(' ')
    .split(/[\s\n-.,]+/)
    .filter((s) => s)
    .map((s) => s.toLocaleLowerCase());

  const jobSkillsStrListLowered = jobSkills
    .join(' ')
    .split(/[\s\n-.,]+/)
    .filter((s) => s)
    .map((s) => s.toLocaleLowerCase());
  // .filter((s) => !SKIP_WORDS.includes(s));

  const highlightedJobDescriptionFromPotential = (
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {jobDescription?.split(/\n/).map((line, lineIndex) => (
        <React.Fragment key={lineIndex}>
          {line.split(' ').map((word, wordIndex) => {
            const cleanWord = word.replace(/[.,!?"]/g, '').toLocaleLowerCase(); // Remove punctuation for matching
            if (potentialJobSkillsLowered.includes(cleanWord)) {
              return (
                <span
                  key={wordIndex}
                  style={{
                    backgroundColor: 'rgb(0 251 255 / 46%)',
                    fontWeight: 'bold',
                  }}
                >
                  {word}{' '}
                </span>
              );
            }
            return <span key={wordIndex}>{word} </span>;
          })}
          <br /> {/* Add a line break after each line */}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightedSkills = jobSkills.map((skill) => {
    return (
      <div>
        {skill
          ?.replace(/[^a-zA-Z0-9]/g, ' ')
          .split(/\s+/)
          .map((word, index) => {
            const cleanWord = word.replace(/[.,!?"]/g, '').toLocaleLowerCase(); // Remove punctuation for matching
            if (potentialJobSkillsLowered.includes(cleanWord)) {
              return (
                <span
                  key={index}
                  style={{
                    backgroundColor: 'rgb(0 251 255 / 46%)',
                    fontWeight: 'bold',
                  }}
                >
                  {word}{' '}
                </span>
              );
            }
            return <span key={index}>{word} </span>;
          })}
      </div>
    );
  });

  const renderUpdateJobDetails = () => {
    return (
      <>
        <div
          style={{
            paddingTop: '0px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {!updateModalOpen && (
            <>
              <h4 style={{ margin: '0px' }}> Modify Included Skills</h4>
              <ActionIcon
                variant='subtle'
                c='gray'
                size='xs'
                onClick={() => setUpdateModalOpen(true)}
              >
                <IconArrowsMaximize size={20} />
              </ActionIcon>
            </>
          )}
        </div>

        <div style={{ overflowY: 'scroll' }}>
          <Box
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Flex style={{ height: '60vh' }}>
              {' '}
              {/* Set a fixed height or use a dynamic one */}
              <div
                style={{
                  fontSize: '12px',
                  padding: '16px',
                  background: 'white',
                  borderRadius: '12px',
                  overflowY: 'auto', // Makes the content scrollable
                  flex: 1.2,
                  height: '100%', // Ensures it takes up the full height
                }}
              >
                <h3>Full Job Description</h3>
                {highlightedJobDescriptionFromPotential}
              </div>
              <div
                style={{
                  fontSize: '12px',
                  padding: '12px',
                  background: 'white',
                  borderRadius: '12px',
                  overflowY: 'auto', // Makes the content scrollable
                  flex: 1,
                  height: '100%', // Ensures it takes up the full height
                  marginLeft: '2px',
                }}
              >
                <h3>Extracted Skills</h3>
                {highlightedSkills}
              </div>
              <div
                style={{
                  fontSize: '12px',
                  padding: '12px',
                  background: 'white',
                  borderRadius: '12px',
                  flex: 1,
                  height: '100%', // Ensures it takes up the full height
                  marginLeft: '2px',
                }}
              >
                <h3>Included Skills</h3>
                <SkillsQuillEditor
                  editorContent={skillsEditorContent}
                  setEditorContent={setSkillsEditorContent}
                  jobSkillsStrListLowered={jobSkillsStrListLowered}
                  potentialJobSkills={potentialJobSkills}
                />
              </div>
            </Flex>
          </Box>
        </div>
        <Button
          mt='md'
          fullWidth
          disabled={!potentialJobSkills}
          onClick={sendUpdateSkillsMessage}
        >
          Send
        </Button>
      </>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '70vh',
        padding: '20px',
        background: 'white',
        borderRadius: '4px',
        border: '1px solid #8080805c',
      }}
    >
      {gptMessagesReveresed?.length > 0 && resumeExists ? (
        <>
          <div
            style={{
              flexGrow: 1,
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
              padding: '10px 0',
            }}
          >
            {gptMessagesReveresed.map((msg, index) =>
              renderMessage(msg, index)
            )}
          </div>
          <div key={key.current}>
            <Input
              placeholder='Type a message...'
              multiline={true}
              value={message}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              rightButtons={
                <Button
                  onClick={sendMessage}
                  style={{
                    padding: '8px 16px',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  disabled={disabled}
                >
                  Send
                </Button>
              }
              autofocus={disabled ? false : true}
              maxHeight={300}
            />
          </div>
        </>
      ) : (
        <>
          {jobSkills?.length > 0
            ? renderUpdateJobDetails()
            : renderInputJobDetails()}
          <Modal
            opened={updateModalOpen}
            onClose={() => setUpdateModalOpen(false)}
            size={'100%'}
            title={'Modify Included Skils'}
          >
            {renderUpdateJobDetails()}
          </Modal>
        </>
      )}
    </div>
  );
}

export default ResumeChatInput;
