import React, { createContext, useState } from 'react';
import { produce } from 'immer';

import { FilterType, FilterWithType } from './types';
import useLocationFilterState from './useLocationFilterState';
import useAgencyFilterState from './useAgencyFilterState';
import useScoreFilterState from './useScoreFilterState';
import useDateFilterState from './useDateFilterState';
import useClientFilterState from './useClientFilterState';
import useRevenueFilterState from './useRevenueFilterState';

interface FilterState {
  activeFilters: FilterWithType[];
  addFilter: (filterType: FilterType) => void;
  removeFilter: (filterType: FilterType) => void;
  clearAll: () => void;
}

export const FilterContext = createContext<FilterState | undefined>(undefined);

interface FilterContextProviderProps {
  children: React.ReactNode;
}

export function FilterContextProvider({
  children,
}: FilterContextProviderProps) {
  const locationFilterState = useLocationFilterState();
  const clientFilterState = useClientFilterState();
  const agencyFilterState = useAgencyFilterState();
  const revenueFilterState = useRevenueFilterState();
  const scoreFilterState = useScoreFilterState();
  const postedDateFilterState = useDateFilterState();
  const lastSeenDateFilterState = useDateFilterState();

  const [activeFilterTypes, setActiveFilterTypes] = useState<FilterType[]>([]);

  const getFilterStateForType = (filterType: FilterType) => {
    switch (filterType) {
      case 'location':
        return locationFilterState;
      case 'client':
        return clientFilterState;
      case 'revenue':
        return revenueFilterState;
      case 'agency':
        return agencyFilterState;
      case 'score':
        return scoreFilterState;
      case 'posted':
        return postedDateFilterState;
      case 'seen':
        return lastSeenDateFilterState;
    }
  };

  const activeFilters = activeFilterTypes.map(
    (filterType) =>
      ({
        type: filterType,
        state: getFilterStateForType(filterType),
      }) as FilterWithType
  );

  const addFilter = (type: FilterType) => {
    setActiveFilterTypes(
      produce(activeFilterTypes, (draft) => {
        draft.push(type);
      })
    );
  };

  const removeFilter = (filterType: FilterType) => {
    setActiveFilterTypes(
      activeFilterTypes.filter(
        (activeFilterType) => activeFilterType !== filterType
      )
    );
    getFilterStateForType(filterType).clear();
  };

  const clearAll = () => {
    activeFilterTypes.forEach((filterType) => {
      getFilterStateForType(filterType).clear();
    });
    setActiveFilterTypes([]);
  };

  return (
    <FilterContext.Provider
      value={{ activeFilters, addFilter, removeFilter, clearAll }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export const withFilterContextProvider =
  <T extends {}>(Component: React.ComponentType<T>) =>
  (props: T) => {
    return (
      <FilterContextProvider>
        <Component {...props} />
      </FilterContextProvider>
    );
  };
