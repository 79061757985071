import React from 'react';
import { Text } from '@mantine/core';
type OutroOption = {
  selectionPageDisplay: React.ReactNode;
  previewDisplay: React.ReactNode;
  actualValue: string;
};

const NO_SCHEDULE_CALL_OUTRO: OutroOption = {
  selectionPageDisplay: (
    <>
      Awesome, I really appreciate you sharing all that with me. It sounds like
      you've got a solid background that could be a great fit for this role.
      Before we wrap up, any questions on your end or anything else you'd like
      to add?
      <br />
      <br />
      Perfect, thanks a ton for your time today! You'll be hearing from us soon.
      Bye for now.
    </>
  ),
  previewDisplay: (
    <>
      Awesome, I really appreciate you sharing all that with me. It sounds like
      you've got a solid background that could be a great fit for this role.
      Before we wrap up, any questions on your end or anything else you'd like
      to add?
      <Text size='xs' c='gray' className='wait-for-response'>
        Wait for response
      </Text>
      Perfect, thanks a ton for your time today! You'll be hearing from us soon.
      Bye for now.
    </>
  ),
  actualValue: `Awesome, I really appreciate you sharing all that with me. It sounds like you've got a solid background that could be a great fit for this role. Before we wrap up, any questions on your end or anything else you'd like to add?

Perfect, thanks a ton for your time today! You'll be hearing from us soon. Bye for now.`,
};

const SCHEDULE_CALL_OUTRO: OutroOption = {
  selectionPageDisplay: (
    <>
      Awesome, I really appreciate you sharing all that with me. Before we move
      on, were there any questions you had?
      <br />
      <br />
      <i>Schedule follow-up call if candidate passed screen</i>
      <br />
      <br />
      Thanks for your time today! Goodbye
    </>
  ),

  previewDisplay: (
    <>
      Awesome, I really appreciate you sharing all that with me. Before we move
      on, were there any questions you had?
      <Text size='xs' c='gray' className='wait-for-response'>
        Wait for response
      </Text>
      <i>Schedule follow-up call if candidate passed screen</i>
      <Text size='xs' c='gray' className='wait-for-response'>
        Wait for response
      </Text>
      Thanks for your time today! Goodbye
    </>
  ),
  actualValue: `Awesome, I really appreciate you sharing that with me. Before we move on, were there any questions you had?

{book_call}

Goodbye`,
};

export const getOutroFromSchedule = (scheduleCall: boolean): OutroOption =>
  scheduleCall ? SCHEDULE_CALL_OUTRO : NO_SCHEDULE_CALL_OUTRO;
