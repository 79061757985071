import { useState } from 'react';

import { ScoreFilterState } from './types';

const DEFAULT_SCORE = 0;

export default function useScoreFilterState(): ScoreFilterState {
  const [score, setScore] = useState(DEFAULT_SCORE);

  const clear = () => {
    setScore(DEFAULT_SCORE);
  };

  const isEmpty = score === 0;

  return {
    score,
    onChangeScore: setScore,
    clear,
    isEmpty,
  };
}
