// This intercepts all axios requests to append the auth data
import axios from 'axios';
import env from 'env';
import { v4 as uuidv4 } from 'uuid';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: env.REACT_APP_SERVER_URL, // Set your base URL here
});

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

// Use an interceptor to append session info to each request
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve session information from localStorage
    const orgId = localStorage.getItem('orgId');
    const userId = localStorage.getItem('userId');
    const stytch_session = getCookie('stytch_session');
    if (stytch_session) {
      config.headers['Authorization'] = `Bearer ${stytch_session}`;
    }
    // don't use this sessionId anymore but backend expects it
    // TODO: make backend not care
    const sessionId = uuidv4();
    // Check if the request is sending FormData
    if (config.data instanceof FormData) {
      // Append session info directly to the FormData
      config.data.append('session_id', sessionId);
      config.data.append('org_id', orgId);
      config.data.append('user_id', userId);
    } else {
      if (!config.data) {
        config.data = {};
      }
      Object.assign(config.data, { sessionId, orgId, userId });
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosInstance;
