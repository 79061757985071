export function removeHighlighting(highlightedHtmlContent: string): string {
  // Parse the highlighted content as HTML
  const parser = new DOMParser();
  const doc = parser.parseFromString(highlightedHtmlContent, 'text/html');

  // Walk the DOM and remove the highlighting spans and styles
  function removeHighlightingFromNode(node: Node): void {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;

      // Check if the element has a style attribute with specific properties
      if (element.style.backgroundColor !== '') {
        element.style.backgroundColor = '';

        // Remove the style attribute if it is now empty
        if (element.getAttribute('style') === '') {
          element.removeAttribute('style');
        }
      }

      // If the element is a span and no longer has any styles, unwrap it
      if (element.tagName === 'SPAN' && !element.getAttribute('style')) {
        const parentNode = element.parentNode;
        while (element.firstChild) {
          parentNode?.insertBefore(element.firstChild, element);
        }
        parentNode?.removeChild(element);
      }

      // Recursively handle child nodes
      Array.from(element.childNodes).forEach((child) =>
        removeHighlightingFromNode(child)
      );
    }
  }

  // Apply the function to the document body
  removeHighlightingFromNode(doc.body);

  // Return the cleaned HTML
  return doc.body.innerHTML;
}
