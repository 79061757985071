import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Table,
  ActionIcon,
  Menu,
  rem,
  Center,
  Loader,
  Badge,
  Text,
  Progress,
  Skeleton,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import {
  IconDotsVertical,
  IconTrash,
  IconEdit,
  IconCopy,
  IconClipboardList,
} from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import { deleteCampaign, duplicateScript } from 'pages/scripts/actions';
import { v4 as uuidv4 } from 'uuid';
import 'css/common.css';
import env from 'env';
import axios from 'api/axiosConfig';
import { formatToLocalTime } from 'utils/dateUtils';
import { convertUpperSnakeToTitle } from 'utils/formatUtils';
import './ScriptTabsPage.css';

type CampaignResponse = {
  campaign_id: string;
  campaign_name: string;
  user: {
    email: string;
  };
  status: string;
  created: Date;
};

type CampaginsProgressResponse = {
  campaign_id: string;
  total_candidates: number;
  done_candidates: number;
};

const COLLAPSE_THRESHOLD = 1100; // Note: threshold here AND css file

const ScriptsPage = () => {
  const navigate = useNavigate();

  const [campaignsData, setCampaignsData] = useState<CampaignResponse[]>([]);
  const [campaignsProgress, setCampaignsProgress] = useState<
    CampaginsProgressResponse[]
  >([]);
  const [campaignsProgressLoading, setCampaignProgressLoading] = useState(true);
  const [campaignsLoading, setCampaignsLoading] = useState(true);
  const orgId = localStorage.getItem('orgId');

  const fetchCampaigns = useCallback(async () => {
    if (!orgId) {
      return;
    }
    try {
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/campaigns/org/${orgId}`
      );
      const sortedData = response.data.campaigns.sort(
        (a, b) =>
          new Date(b.created as string).getTime() -
          new Date(a.created as string).getTime()
      ); // Sort by created time, most recent first
      setCampaignsData(sortedData);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setCampaignsLoading(false);
    }
  }, [orgId]); // Add orgId as a dependency

  const fetchCampaignProgress = useCallback(async () => {
    if (!orgId) {
      return;
    }
    setCampaignProgressLoading(true);
    try {
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/campaigns/org/${orgId}/stats`
      );
      setCampaignsProgress(response.data.campaigns);
      setCampaignProgressLoading(false);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setCampaignProgressLoading(false);
    }
  }, [orgId]); // Add orgId as a dependency

  useEffect(() => {
    // Select all matching elements
    const progressSections = document.querySelectorAll(
      'div.mantine-Progress-section'
    );

    if (progressSections.length > 0) {
      progressSections.forEach((section) => {
        if (!env.IS_LOCAL) {
          section.classList.add('custom-progress-style');
        } else {
          section.classList.remove('custom-progress-style');
        }
      });
    }
  }, [campaignsData]); // Add dependencies if necessary

  const [isCollapsed, setIsCollapsed] = useState(
    window.innerWidth <= COLLAPSE_THRESHOLD
  );

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth <= COLLAPSE_THRESHOLD);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on window width

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchCampaigns();
    fetchCampaignProgress();
  }, [fetchCampaigns, fetchCampaignProgress]);

  const campaginProgressFromId = (
    campaignId: string
  ): CampaginsProgressResponse | undefined => {
    if (campaignsProgressLoading) return undefined;
    const progress = campaignsProgress.find(
      (c) => c.campaign_id === campaignId
    );
    return progress;
  };

  const didCampaignStart = (campaignId: string) => {
    const progress = campaginProgressFromId(campaignId);
    if (!progress) return false;
    return progress.total_candidates > 0;
  };

  const navigateToScriptEditor = (
    c: CampaignResponse | undefined,
    tab = ''
  ) => {
    const campaignId = c?.campaign_id || '';
    let url = `/scripts/script-editor/${campaignId}`;
    if (!campaignId) {
      const newId = uuidv4();
      url = `/scripts/script-editor/new/${newId}`;
    } else if (tab) {
      url = url + `?tab=${tab}`;
    } else if (c && didCampaignStart(c.campaign_id)) {
      url = url + '?tab=contacts';
    }
    navigate(url);
  };

  const handleDuplicateScript = (campaignId: string) => {
    duplicateScript(campaignId)
      .then(() => {
        showNotification({
          title: 'Success',
          message: 'Script duplicated successfully!',
          color: 'green',
        });
        fetchCampaigns();
        fetchCampaignProgress();
      })
      .catch((error) => {
        showNotification({
          title: 'There was an error duplicating the script',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      });
  };

  const handleDeleteCampaign = (campaignId: string) => {
    deleteCampaign(campaignId)
      .then(() => {
        fetchCampaigns();
        showNotification({
          title: 'Success',
          message: 'Campaign deleted successfully!',
          color: 'green',
        });
      })
      .catch((error) => {
        showNotification({
          title: 'There was an error deleting the campaign',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      });
  };

  const [selectedcampaignIdx, setSelectedcampaignIdx] = useState(-1);

  const renderProgress = (campaignId: string) => {
    const progress = campaginProgressFromId(campaignId);
    if (!progress) {
      return <Skeleton height={6} style={{ width: '33%' }} />;
    }
    return (
      <div style={{ display: 'flex' }}>
        {!isCollapsed && (
          <Progress
            style={{
              width: '50px',
              alignSelf: 'center',
              marginRight: '12px',
            }}
            // color='#aeb0b5'
            color='var(--salv-dark-1)'
            // color='var(--mantine-color-blue-1)'
            radius='lg'
            value={(100 * progress.done_candidates) / progress.total_candidates}
            // styles={{
            //   // Using 'filled' to target the progress bar's filled area
            //   filled: {
            //     background:
            //       'linear-gradient(90deg, var(--salv-dark-2), var(--salv-light-1))',
            //   },
            // }}
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: 'auto',
          }}
        >
          <Text size='xs'>
            {progress.done_candidates} / {progress.total_candidates}
          </Text>
        </div>
      </div>
    );
  };

  // TODO: make the headers sticky. Follow this https://ui.mantine.dev/category/tables/
  return (
    <div className='page-container-common'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '8px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <IconClipboardList size={20} color='#4a564d' />
          <h4 style={{ marginBottom: '0px', marginTop: '0px' }}>Jobs</h4>
        </div>
        <Button
          variant='light'
          size='sm'
          onClick={() => navigateToScriptEditor(undefined)}
        >
          New Job
        </Button>
      </div>

      <div className='common-table-wrapper'>
        <Table style={{ tableLayout: 'fixed', width: '100%' }}>
          <Table.Thead>
            <Table.Tr>
              <Table.Th
                style={{
                  width: '25%',
                }}
              >
                Name
              </Table.Th>
              <Table.Th
                style={{
                  width: '20%',
                }}
              >
                Status
              </Table.Th>
              <Table.Th
                style={{
                  width: '20%',
                }}
              >
                Progress
              </Table.Th>
              <Table.Th
                style={{
                  width: '22%',
                }}
              >
                Owner
              </Table.Th>
              <Table.Th
                style={{
                  width: '22%',
                }}
              >
                Created
              </Table.Th>
              <Table.Th
                style={{
                  width: isCollapsed ? '8%' : '5%',
                }}
              >
                {''}
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {campaignsData.map((campaign, index) => (
              <Table.Tr
                key={index}
                style={{
                  cursor: 'pointer',
                  background:
                    selectedcampaignIdx === index ? 'var(--salv-dark-0)' : '',
                }}
                onClick={() => navigateToScriptEditor(campaign)}
              >
                <Table.Td>{campaign.campaign_name}</Table.Td>
                <Table.Td>
                  <Badge autoContrast className='status-badge'>
                    {convertUpperSnakeToTitle(campaign.status)}
                  </Badge>
                </Table.Td>
                <Table.Td>
                  {/* <Text size='sm'>
                    {campaign.total_calls - campaign.pending_calls} /{' '}
                    {campaign.total_calls}
                  </Text> */}
                  {/* Bar concept */}
                  {renderProgress(campaign.campaign_id)}
                </Table.Td>
                <Table.Td style={{ color: '#4a4e56' }}>
                  {campaign.user.email}
                </Table.Td>
                <Table.Td style={{ color: '#4a4e56' }}>
                  {campaign.created ? formatToLocalTime(campaign.created) : ''}
                </Table.Td>
                <Table.Td
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  className='no-ellipses'
                >
                  <Menu
                    shadow='md'
                    width={200}
                    onChange={(e) => {
                      setSelectedcampaignIdx(e ? index : -1);
                    }}
                  >
                    <Menu.Target>
                      <ActionIcon variant='subtle'>
                        <IconDotsVertical
                          size={20}
                          onClick={() => {
                            // e.stopPropagation();
                            // e.preventDefault();
                          }}
                        />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Label>{campaign.campaign_name}</Menu.Label>
                      <Menu.Item
                        leftSection={
                          <IconEdit
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                        onClick={() =>
                          navigateToScriptEditor(campaign, 'sequence')
                        }
                      >
                        Edit
                      </Menu.Item>
                      <Menu.Item
                        leftSection={
                          <IconCopy
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                        onClick={() =>
                          handleDuplicateScript(campaign.campaign_id)
                        }
                      >
                        Duplicate
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item
                        color='red'
                        leftSection={
                          <IconTrash
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                        onClick={() =>
                          handleDeleteCampaign(campaign.campaign_id)
                        }
                      >
                        Delete
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Table.Td>
              </Table.Tr>
            ))}
            {campaignsLoading && (
              <tr>
                <td colSpan={7}>
                  <Center p='lg'>
                    <Loader size='sm' type='dots' />
                  </Center>
                </td>
              </tr>
            )}
          </Table.Tbody>
        </Table>
      </div>
    </div>
  );
};

export default ScriptsPage;
