// Import AuthProvider and useAuth hook
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { useStytchMemberSession, useStytchMember } from '@stytch/react/b2b';
import AdminTabsPage from 'pages/admin/AdminTabsPage';

import SelectSupportRole from './pages/login/SelectSupportRole';
import CustomStytchLogin from './pages/login/CustomStytchLoginPage';
import FinishInfoPage from './pages/login/FinishInfoPage';
import RedirectLinkHandler from './pages/login/RedirectLinkHandler';
import UserSettingsPage from './pages/admin/UserSettingsPage';
import SourcingPage from './pages/sourcing/SourcingPage';
import SideNavBar from './components/side-nav-bar/SideNavBar';
import OAuthRedirect from './pages/email-settings/OAuthRedirect';
import CampaignDetailsPage from './pages/campaign/details/CampaignDetailsPage';
import ResumeEditorPage from './pages/resume-editor/ResumeEditorPage';
import JobPostingTipsPage from './pages/job-posting-tips/JobPostingTipsPage';
import ScriptsPage from './pages/scripts/ScriptsPage';
import { checkLocalServerRunning } from './serverCheck';
import {
  useNeedsSupportSelection,
  useSetLocalStorageIfNeeded,
} from './utils/userInfoUtils';
import ScriptTabsPage from './pages/scripts/ScriptTabsPage';

const Layout = () => {
  const location = useLocation();
  const hideSideNavBarRoutes = ['/login', '/authenticate', '/finish-creation'];

  return (
    <div
      style={{
        // display: 'flex',
        height: '100%',
        minHeight: '100%',
        maxHeight: '100vh',
        display: hideSideNavBarRoutes.includes(location.pathname)
          ? 'contents'
          : 'flex',
      }}
    >
      {/* Conditionally render SideNavBar if not on certain routes */}
      {!hideSideNavBarRoutes.includes(location.pathname) && <SideNavBar />}
      <Routes>
        <Route path='/authenticate' element={<RedirectLinkHandler />} />
        <Route path='/login' element={<LoginRoute />} />
        <Route
          path='/finish-creation'
          element={<ProtectedRoute component={FinishInfoPage} />}
        />
        <Route
          path='/scripts/script-editor/new/:campaignId'
          element={<ProtectedRoute component={ScriptTabsPage} />}
        />
        <Route
          path='/scripts/script-editor/:campaignId'
          element={<ProtectedRoute component={ScriptTabsPage} />}
        />
        <Route path='*' element={<HomeRouter />} exact={false} />
      </Routes>
    </div>
  );
};

const App = () => {
  useEffect(() => {
    checkLocalServerRunning();
  }, []);

  useSetLocalStorageIfNeeded();

  return (
    <MantineProvider
      theme={{
        fontFamily: 'Inter, system-ui, sans-serif',
      }}
    >
      <ModalsProvider>
        <Notifications position='top-right' />
        <Router>
          <Layout />
        </Router>
      </ModalsProvider>
      <Notifications position='top-right' />
    </MantineProvider>
  );
};

// Must be in order of decreasing specificity
const HOME_ROUTES = [
  {
    path: '/',
    Component: ScriptsPage,
  },
  {
    path: '/oauth-redirect',
    Component: OAuthRedirect,
  },
  {
    path: '/scripts',
    Component: ScriptsPage,
  },
  {
    path: '/campaigns/campaign/:campaignId',
    Component: CampaignDetailsPage,
  },
  {
    path: '/sourcing',
    Component: SourcingPage,
  },
  {
    path: '/resume-editor',
    Component: ResumeEditorPage,
  },
  {
    path: '/job-posting-tips',
    Component: JobPostingTipsPage,
  },
  {
    path: '/user-settings',
    Component: UserSettingsPage,
  },
  {
    path: '/settings',
    Component: AdminTabsPage,
  },
];

// ProtectedRoute component checks if user is logged in and either shows the main page or redirects to login
const HomeRouter = () => {
  const { session } = useStytchMemberSession();
  const { member } = useStytchMember();
  const location = useLocation();
  const isMatch = (path) => matchPath(path, location.pathname);
  const needsSupportSelection = useNeedsSupportSelection();

  if (!session) {
    return <Navigate replace to='/login' />;
  }
  if (!member.trusted_metadata.salv_user_id) {
    return <Navigate replace to='/finish-creation' />;
  }
  if (needsSupportSelection) {
    return <SelectSupportRole />;
  }
  console.log('get rerender home router');

  return (
    <>
      {HOME_ROUTES.map(({ path, Component }) => (
        <div
          key={path}
          style={
            !isMatch(path)
              ? { display: 'none' }
              : {
                  display: 'contents',
                  height: '100%',
                  minHeight: '100%',
                  maxHeight: '100vh',
                  width: '100%',
                }
          }
        >
          <Component style={{ minHeight: '0px' }} />
        </div>
      ))}
    </>
  );
};

// ProtectedRoute component checks if user is logged in and either shows the EditorPage or redirects to login
const ProtectedRoute = ({ component: Component }) => {
  const { session } = useStytchMemberSession();
  const { member } = useStytchMember();
  const needsSupportSelection = useNeedsSupportSelection();
  // TODO: this is very redeundant to the home router redirect logic
  if (!session) {
    return <Navigate replace to='/login' />;
  }
  if (!member.trusted_metadata.salv_user_id) {
    return <FinishInfoPage />;
  }
  if (needsSupportSelection) {
    return <SelectSupportRole />;
  }
  return (
    <>
      <div
        style={{
          flexDirection: 'column',
          height: '100%',
          minHeight: '0%',
          maxHeight: '100vh',
          width: '100%',
          display: 'contents',
        }}
      >
        <Component />
      </div>
    </>
  );
};

// LoginRoute component does the opposite; it redirects to the main page if logged in, otherwise shows LoginPage
const LoginRoute = () => {
  const { session } = useStytchMemberSession();
  const needsSupportSelection = useNeedsSupportSelection();

  if (needsSupportSelection) {
    return <SelectSupportRole />;
  }

  return !session ? <CustomStytchLogin /> : <Navigate replace to='/' />;
};

export default App;
