import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDebouncedValue } from '@mantine/hooks';
import {
  ActionIcon,
  Menu,
  Stack,
  Text,
  TextInput,
  Button,
  Group,
  Center,
  Loader,
} from '@mantine/core';
import { IconSearch, IconX, IconUser } from '@tabler/icons-react';
import env from 'env';

import { formatPhoneNumber } from '../../../utils/phoneUtils';

type Candidate = {
  fullName: string;
  email: string;
  phoneNumber: string;
};

interface ComponentProps {
  selectedCandidateName: string;
  onChangeCandidateName: (arg0: string | undefined) => void;
  onSelectCandidateName: (arg0: string | undefined) => void;
  onClear: () => void;
  onClose: () => void;
}

export default function NameFilter({
  selectedCandidateName,
  onChangeCandidateName,
  onSelectCandidateName,
  onClear,
  onClose,
}: ComponentProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [opened, setOpened] = useState(false);

  const [debouncedSearchValue] = useDebouncedValue(selectedCandidateName, 500);

  const handleSelectCandidate = (candidateName: string | undefined) => () => {
    onSelectCandidateName(candidateName);
    // setSearchValue(candidateName || '');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setOpened(false);
      onSelectCandidateName(selectedCandidateName);
    }
  };

  useEffect(() => {
    setLoading(true);
    setCandidates([]);
    axios
      .get(`${env.REACT_APP_SERVER_URL}/candidates_by_identifier`, {
        params: { query: debouncedSearchValue },
      })
      .then((response) => {
        console.log('response', response);
        setCandidates(response.data);
      })
      .finally(() => setLoading(false));
  }, [debouncedSearchValue]);
  return (
    <Menu
      position='bottom-start'
      onClose={onClose}
      opened={opened}
      onChange={setOpened}
    >
      <Menu.Target>
        <Button variant='default' radius='xl' size='xs'>
          <Group gap={4} align='center'>
            <IconUser size={15} />
            {selectedCandidateName && (
              <Text c='dimmed' size='sm'>
                {selectedCandidateName}
              </Text>
            )}
            <ActionIcon
              variant='subtle'
              onClick={onClear}
              size='sm'
              style={{ zIndex: 100 }}
              component='div'
            >
              <IconX size={12} />
            </ActionIcon>
          </Group>
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Stack w={350} gap={0} p='sm'>
          <TextInput
            size='sm'
            leftSection={<IconSearch size={16} />}
            placeholder='Search by name, phone, or email...'
            value={selectedCandidateName}
            onKeyDown={handleKeyDown}
            onChange={(e) => onChangeCandidateName(e.target.value)}
            rightSection={
              <ActionIcon
                variant='subtle'
                c='gray'
                size='xs'
                onClick={() => onChangeCandidateName('')}
              >
                <IconX size={12} />
              </ActionIcon>
            }
          />
          <Menu.Divider />
          <div style={{ position: 'relative' }}>
            {candidates.length ? (
              <>
                {candidates.map((c, index) => (
                  <Menu.Item
                    key={index}
                    onClick={handleSelectCandidate(c.fullName)}
                  >
                    <div>
                      <div style={{ fontWeight: 'bold' }}>{c.fullName}</div>
                      <div>{formatPhoneNumber(c.phoneNumber)}</div>

                      <div style={{ opacity: 0.6 }}>{c.email}</div>
                    </div>
                  </Menu.Item>
                ))}
              </>
            ) : (
              <Center p='md'>
                {loading ? (
                  <Loader size='sm' />
                ) : (
                  <Text size='sm' c='dimmed'>
                    {selectedCandidateName.length < 3
                      ? 'Enter a search to find candidate names.'
                      : `No candidate names found for "${selectedCandidateName}"`}
                  </Text>
                )}
              </Center>
            )}
          </div>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
}
