import React, { useState } from 'react';
import {
  Box,
  ScrollArea,
  Text,
  Group,
  RingProgress,
  Tabs,
} from '@mantine/core';
import { formatToLocalTime } from 'utils/dateUtils';
import { convertUpperSnakeToTitle } from 'utils/formatUtils';

import { RequirementImportance } from '../../pages/editorv2/EditorPageV2';
import CallRecordingPlayer from './CallRecordingPlayer';

import './Transcript.css';

const TabOptions = {
  CALL: 'Call',
  // EMAIL: 'email',
  SMS: 'SMS',
} as const;

type Tab = (typeof TabOptions)[keyof typeof TabOptions];
const isTab = (value: any): value is Tab => {
  return Object.values(TabOptions).includes(value);
};

interface TranscriptProps {
  transcript: string;
  requirementGrades: Record<
    string,
    { answer: string; grade: string; importance: RequirementImportance }
  >;
  callId: string;
  smsTranscript?: string;
  overallGrade?: number;
  completionRate?: number;
  candidateName?: string;
  lastCalled?: string;
  testCall?: boolean;
  allCalls?: any[];
  callComplete?: boolean;
}

const BoldTranscript = ({ transcript }) => {
  // Function to escape angle brackets to prevent them from being treated as HTML tags
  const escapeAngleBrackets = (text) =>
    text.replace(/</g, '&lt;').replace(/>/g, '&gt;');

  // Function to wrap text inside angle brackets and ~~text~~ with gray color
  const highlightGrayText = (text) => {
    let updatedText = escapeAngleBrackets(text);
    updatedText = updatedText.replace(
      /(&lt;.*?&gt;|~(.*?)~)/g,
      '<span style="color:gray">$1</span>'
    );
    return updatedText;
  };

  // Function to render a message block
  const renderMessageBlock = (label, line, index) => (
    <Group
      key={index}
      style={{ marginTop: index === 0 ? 0 : 12, alignItems: 'flex-start' }}
    >
      <div style={{ marginLeft: 0 }}>
        <div style={{ display: 'flex' }}>
          <Text
            size='sm'
            fw={700}
            style={{
              color:
                label === 'Candidate'
                  ? 'var(--mantine-color-blue-9)'
                  : '#222324',
            }}
          >
            {label}:
          </Text>
        </div>
        <Text
          style={{ paddingLeft: '4px', fontSize: '14px' }}
          dangerouslySetInnerHTML={{
            __html: highlightGrayText(line.replace(`${label}:`, '').trim()),
          }}
        />
      </div>
    </Group>
  );

  // Process the transcript text into structured elements
  const processTranscript = (text) =>
    text.split('\n').map((line, index) => {
      if (line.startsWith('Recruiter:')) {
        return renderMessageBlock('Recruiter', line, index);
      } else if (line.startsWith('Candidate:')) {
        return renderMessageBlock('Candidate', line, index);
      } else {
        return (
          <Text
            key={index}
            dangerouslySetInnerHTML={{ __html: highlightGrayText(line) }}
          />
        );
      }
    });

  // Render the processed transcript
  return <div>{processTranscript(transcript)}</div>;
};

const Transcript: React.FC<TranscriptProps> = ({
  transcript,
  requirementGrades,
  callId,
  smsTranscript,
  overallGrade,
  completionRate,
  candidateName,
  lastCalled,
  testCall,
  allCalls,
  callComplete,
}) => {
  console.log('all calls', allCalls);
  const [activeTab, setActiveTab] = useState<Tab>(TabOptions.CALL);
  console.log('transcript callId', callId);
  const renderResultsColumn = () => {
    if (!callId) {
      return (
        <div className='qa-column'>
          <h4
            className='candidate-name'
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            {candidateName}
          </h4>
          <h4 style={{ margin: 'auto' }}> No Screening Results</h4>
        </div>
      );
    }
    return (
      <div className='qa-column'>
        <h4
          className='candidate-name'
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          {candidateName}
        </h4>
        <div
          style={{
            display: 'flex',
            gap: '15%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <RingProgress
              size={65}
              thickness={6}
              roundCaps
              sections={[
                {
                  value: (completionRate || 0) * 100,
                  color: 'var(--mantine-color-blue-4)',
                },
              ]}
              label={
                <Text c='blue' fw={700} ta='center' size='xs'>
                  {`${((completionRate || 0) * 100).toFixed(0)}%`}
                </Text>
              }
            />
            <div style={{ fontWeight: '500' }}>Completion Rate</div>
          </div>
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <RingProgress
              size={65}
              thickness={6}
              roundCaps
              sections={[
                {
                  value: overallGrade || 0,
                  color: 'var(--mantine-color-blue-4)',
                },
              ]}
              label={
                <Text c='blue' fw={700} ta='center' size='xs'>
                  {`${(overallGrade || 0).toFixed(0)}%`}
                </Text>
              }
            />
            <div style={{ fontWeight: '500' }}>Grade</div>
          </div>
        </div>
        <ScrollArea className='scrollable-content'>
          <div className='scroll-fade-top'></div>
          <div className='inside'>
            {Object.entries(requirementGrades).map(
              ([question, { answer, grade, importance }], index) => (
                <div key={index} className='qa-item'>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div className='grade'>{grade}</div>
                    <div className='question'>{question}</div>
                  </div>
                  <div className='answer'>{answer}</div>
                </div>
              )
            )}
          </div>
          <div className='scroll-fade-bottom'></div>
        </ScrollArea>
      </div>
    );
  };

  return (
    <Box className='transcript-container'>
      <Tabs
        variant='pills'
        value={activeTab}
        onChange={(value) => {
          if (isTab(value)) {
            setActiveTab(value);
          }
        }}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          zIndex: 10001,
        }}
      >
        <div
          style={{
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '6px',
            }}
          >
            <Tabs.List>
              {/* <h3 style={{ margin: 0, marginRight: '20px' }}>Communication</h3> */}
              <Tabs.Tab
                value={TabOptions.CALL}
                classNames={{
                  tab: 'header-tab',
                }}
              >
                Phone Call
              </Tabs.Tab>
              {!testCall && (
                <Tabs.Tab
                  value={TabOptions.SMS}
                  classNames={{
                    tab: 'header-tab',
                  }}
                >
                  SMS
                </Tabs.Tab>
              )}
            </Tabs.List>
          </div>

          <Tabs.Panel
            value={TabOptions.SMS}
            style={{ height: 'calc(100% - 90px)', padding: 0, margin: 0 }}
          >
            {smsTranscript && (
              <div className='transcript-column'>
                <ScrollArea className='scrollable-content'>
                  <div className='scroll-fade-top'></div>
                  <div className='inside'>
                    {/* <Text>{smsTranscript}</Text> */}
                    <BoldTranscript transcript={smsTranscript} />
                  </div>
                  <div className='scroll-fade-bottom'></div>
                </ScrollArea>
              </div>
            )}
          </Tabs.Panel>

          <Tabs.Panel
            value={TabOptions.CALL}
            style={{ height: 'calc(100% - 90px)', padding: 0, margin: 0 }}
          >
            {!allCalls?.length && !testCall && (
              <div className='transcript-column'>
                <h4 style={{ margin: 'auto' }}> No Call Results</h4>
              </div>
            )}
            {!!allCalls?.length && !callComplete && !testCall && (
              <div className='transcript-column'>
                <h3>Call History</h3>
                <div>
                  {allCalls.map((c) => (
                    <div
                      style={{
                        display: 'flex',
                        padding: '14px',
                        gap: '8px',
                        fontSize: '14px',
                        justifyContent: 'space-between',
                        marginRight: '25%',
                        border: '1px solid var(--salv-dark-1)',
                        borderRadius: '8px',
                        marginBottom: '10px',
                      }}
                    >
                      <div style={{ fontWeight: '600', fontSize: '16px' }}>
                        <div>{convertUpperSnakeToTitle(c.call_status)}</div>
                      </div>
                      <div style={{ opacity: 0.7 }}>
                        <div>{formatToLocalTime(c.last_updated, true)}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!!callId && callComplete && !testCall && (
              <div>
                {lastCalled && (
                  <div
                    style={{
                      padding: '8px',
                      fontSize: '12px',
                      marginTop: '6px',
                    }}
                  >
                    {`Call from ${formatToLocalTime(lastCalled, true)}`}
                  </div>
                )}

                <CallRecordingPlayer callId={callId} />
              </div>
            )}
            {callComplete && (
              <div className='transcript-column phone-transcript-column'>
                {/* <h3 style={{ marginTop: '4px', marginBottom: '4px' }}>Contact</h3> */}
                <ScrollArea className='scrollable-content'>
                  <div className='scroll-fade-top'></div>
                  <div className='inside transcript-box'>
                    {/* <Text>{transcript}</Text> */}
                    <BoldTranscript transcript={transcript} />
                  </div>
                  <div className='scroll-fade-bottom'></div>
                </ScrollArea>
              </div>
            )}
          </Tabs.Panel>
        </div>
      </Tabs>
      {/* <Divider orientation='vertical' /> */}
      {renderResultsColumn()}
    </Box>
  );
};

export default Transcript;
