import React from 'react';
import { ActionIcon, Button, Group, Menu, Text } from '@mantine/core';
import { IconX, TablerIcon } from '@tabler/icons-react';

interface ComponentProps {
  Icon?: TablerIcon;
  title: string;
  label?: string;
  onClear: () => void;
  onClose: () => void;
  children: React.ReactNode;
}

export default function FilterPill({
  Icon,
  title,
  label,
  onClear,
  onClose,
  children,
}: ComponentProps) {
  return (
    <Menu
      defaultOpened
      closeOnItemClick={false}
      position='bottom-start'
      onClose={onClose}
    >
      <Menu.Target>
        <Button
          variant='default'
          radius='xl'
          size='xs'
          leftSection={Icon ? <Icon size={16} /> : undefined}
        >
          <Group gap={4} align='center'>
            {title}
            {label && (
              <Text c='dimmed' size='sm'>
                {label}
              </Text>
            )}
            <ActionIcon
              variant='subtle'
              onClick={onClear}
              size='sm'
              style={{ zIndex: 100 }}
              component='div'
            >
              <IconX size={12} />
            </ActionIcon>
          </Group>
        </Button>
      </Menu.Target>
      <Menu.Dropdown>{children}</Menu.Dropdown>
    </Menu>
  );
}
