import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'api/axiosConfig';
import env from 'env';
import { Button } from '@mantine/core';

const OAuthRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tenantId = queryParams.get('tenant');
    const success = queryParams.get('admin_consent');

    if (tenantId && success) {
      // Send the approved domain to the backend
      axios
        .post(env.REACT_APP_SERVER_URL + '/add_microsoft_email_tenant', {
          tenantId,
        })
        .then((response) => {
          console.log('Admin consent granted:', response.data);
        })
        .catch((error) => {
          console.error('Error during token exchange:', error);
        });
    } else {
      console.error('No authorization code returned from Microsoft');
    }
  }, [location]);

  return (
    <div className='page-container-common'>
      <div className='full-screen-message'>
        <img
          src='imgs/outlook_logo.png'
          alt='Button Icon'
          style={{
            width: '82px',
            height: '78px',
            position: 'relative',
            right: '5px',
          }}
        />
        <h2>Account linked successfully!</h2>
        <Button
          variant='outline'
          onClick={() => {
            navigate('/settings?tab=email');
            window.location.reload();
          }}
        >
          Return to Email Settings
        </Button>
      </div>
    </div>
  );
};

export default OAuthRedirect;
