import React from 'react';
import { Checkbox, Menu } from '@mantine/core';

import { RevenueFilterState, RevenueRange } from './types';
import FilterPill from './FilterPill';

interface ComponentProps {
  onClear: () => void;
  onClose: () => void;
  revenueFilterState: RevenueFilterState;
}

const REVENUE_RANGES = [
  {
    Label: '1 - 10 M',
    Range: [1, 10],
  },
  {
    Label: '10 - 50 M',
    Range: [10, 50],
  },
  {
    Label: '50 - 100 M',
    Range: [50, 100],
  },
  {
    Label: '100 - 500 M',
    Range: [100, 500],
  },
  {
    Label: '500 - 1 B',
    Range: [500, 1000],
  },
  {
    Label: '1B +',
    Range: [1000, -1],
  },
];

export default function ClientRevenueFilter({
  onClear,
  onClose,
  revenueFilterState: { selectedRevenueRanges, onChangeSelectedRevenueRanges },
}: ComponentProps) {
  const mergeRanges = (ranges: number[][]): number[][] => {
    let mergedRanges: number[][] = [];
    ranges.sort((a, b) => a[0] - b[0]);
    ranges.forEach((r_) => {
      const r = structuredClone(r_);
      const len = mergedRanges.length;
      if (len === 0) {
        mergedRanges.push(r);
      } else if (mergedRanges[len - 1][1] === r[0]) {
        // merge
        mergedRanges[len - 1][1] = r[1];
      } else {
        mergedRanges.push(r);
      }
    });
    return mergedRanges;
  };

  const getLabelForRange = (range: number[]): string => {
    const firstStr = (() => {
      switch (true) {
        case range[0] >= 1000:
          if (range[1] === -1) {
            return '' + range[0] / 1000 + 'B';
          }
          return '' + range[0] / 1000;
        case range[1] >= 1000 || range[1] === -1:
          return '' + range[0] + 'M';
        default:
          return '' + range[0];
      }
    })();
    const secondStr = (() => {
      switch (true) {
        case range[1] >= 1000:
          return ' - ' + range[1] / 1000 + 'B';
        case range[1] === -1:
          return ' +';
        default:
          return ' - ' + range[1] + 'M';
      }
    })();
    return firstStr + secondStr;
  };

  const getMergedLabels = (ranges: number[][]) => {
    const mergedRanges = mergeRanges(ranges);
    return mergedRanges
      .map((r) => {
        return getLabelForRange(r);
      })
      .join(', ');
  };

  const filterLabel = selectedRevenueRanges.length
    ? getMergedLabels(selectedRevenueRanges.map((r) => r.Range))
    : undefined;

  const handleItemClick = (selectedRevenueRange: RevenueRange) => () => {
    if (selectedRevenueRanges.includes(selectedRevenueRange)) {
      onChangeSelectedRevenueRanges(
        selectedRevenueRanges.filter((r) => r !== selectedRevenueRange)
      );
    } else {
      onChangeSelectedRevenueRanges([
        ...selectedRevenueRanges,
        selectedRevenueRange,
      ]);
    }
  };

  const handleSelectAll = () => {
    if (selectedRevenueRanges.length === 0) {
      onChangeSelectedRevenueRanges(REVENUE_RANGES);
    } else {
      onChangeSelectedRevenueRanges([]);
    }
  };

  return (
    <FilterPill
      title='Revenue'
      label={filterLabel}
      onClear={onClear}
      onClose={onClose}
    >
      <Menu.Item
        key='all'
        leftSection={
          <Checkbox
            size='xs'
            style={{ pointerEvents: 'none' }}
            checked={selectedRevenueRanges.length > 0}
            indeterminate={
              selectedRevenueRanges.length > 0 &&
              REVENUE_RANGES.length !== selectedRevenueRanges.length
            }
            readOnly
          />
        }
        onClick={handleSelectAll}
      >
        Select all
      </Menu.Item>
      <Menu.Divider />
      {REVENUE_RANGES.map((range) => (
        <Menu.Item
          key={range.Label}
          leftSection={
            <Checkbox
              size='xs'
              style={{ pointerEvents: 'none' }}
              checked={selectedRevenueRanges.some(
                (r) => r.Label === range.Label
              )}
              readOnly
            />
          }
          onClick={handleItemClick(range)}
        >
          {range.Label}
        </Menu.Item>
      ))}
    </FilterPill>
  );
}
