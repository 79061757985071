import { Anchor, Button, Center, Group, Menu, Text } from '@mantine/core';
import React, { useContext } from 'react';
import {
  IconCalendarPlus,
  IconEye,
  IconFilterPlus,
  IconMapPin,
  TablerIcon,
} from '@tabler/icons-react';

import LocationFilter from './LocationFilter';
import { FilterType, FilterWithType } from './types';
import AgencyFilter from './AgencyFilter';
import ClientRevenueFilter from './ClientRevenueFilter';
import { FilterContext } from './FilterContext';
import ScoreFilter from './ScoreFilter';
import DateFilter from './DateFilter';
import ClientFilter from './ClientFilter';

const FILTER_LABELS: Record<FilterType, { label: string; Icon?: TablerIcon }> =
  {
    location: {
      label: 'Location',
      Icon: IconMapPin,
    },
    client: {
      label: 'Client',
    },
    revenue: {
      label: 'Revenue',
    },
    agency: {
      label: 'Agency',
    },
    score: {
      label: 'Score',
    },
    posted: {
      label: 'Date Posted',
      Icon: IconCalendarPlus,
    },
    seen: {
      label: 'Last Seen',
      Icon: IconEye,
    },
  };

export default function FilterBar() {
  const filterState = useContext(FilterContext);
  if (!filterState) return null;

  const renderFilter = (filter: FilterWithType) => {
    const { label, Icon } = FILTER_LABELS[filter.type];

    const handleClear = () => {
      filterState.removeFilter(filter.type);
    };

    const handleClose = () => {
      if (filter.state.isEmpty) filterState.removeFilter(filter.type);
    };

    const commonProps = {
      key: filter.type,
      onClear: handleClear,
      onClose: handleClose,
    };

    switch (filter.type) {
      case 'location':
        return (
          <LocationFilter locationFilterState={filter.state} {...commonProps} />
        );
      case 'client':
        return (
          <ClientFilter clientFilterState={filter.state} {...commonProps} />
        );
      case 'agency':
        return (
          <AgencyFilter agencyFilterState={filter.state} {...commonProps} />
        );
      case 'revenue':
        return (
          <ClientRevenueFilter
            revenueFilterState={filter.state}
            {...commonProps}
          />
        );
      case 'score':
        return <ScoreFilter scoreFilterState={filter.state} {...commonProps} />;
      case 'posted':
        return (
          <DateFilter
            title={label}
            Icon={Icon}
            dateFilterState={filter.state}
            {...commonProps}
          />
        );
      case 'seen':
        return (
          <DateFilter
            title={label}
            Icon={Icon}
            dateFilterState={filter.state}
            {...commonProps}
          />
        );
    }
  };

  const filterMenuItems = (() => {
    const selectedFilters = new Set(
      filterState.activeFilters.map((filter) => filter.type)
    );
    const unselectedFilters = Object.entries(FILTER_LABELS).filter(
      ([filterType, _]) => !selectedFilters.has(filterType as FilterType)
    );

    return unselectedFilters.length > 0 ? (
      unselectedFilters.map(([filterType, { label, Icon }]) => {
        const handleClick = () => {
          filterState.addFilter(filterType as FilterType);
        };

        return (
          <Menu.Item
            key={filterType}
            leftSection={Icon ? <Icon size={16} /> : undefined}
            onClick={handleClick}
          >
            {label}
          </Menu.Item>
        );
      })
    ) : (
      <Center p='md'>
        <Text size='sm'>All filters selected.</Text>
      </Center>
    );
  })();

  return (
    <Group>
      <Menu position='bottom-start'>
        <Menu.Target>
          <Button
            leftSection={<IconFilterPlus size={20} />}
            variant='light'
            color='blue'
          >
            Filter
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Filter by</Menu.Label>
          {filterMenuItems}
        </Menu.Dropdown>
      </Menu>
      {filterState.activeFilters.map(renderFilter)}
      {filterState.activeFilters.length > 0 && (
        <Anchor onClick={filterState.clearAll}>Clear all</Anchor>
      )}
    </Group>
  );
}
