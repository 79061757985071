import { useState } from 'react';

import { GoogleMapsPlaceWithDescription } from '../../../components/location-autocomplete/types';
import { LocationFilterState } from './types';

const DEFAULT_RADIUS = 50;

export default function useLocationFilterState(): LocationFilterState {
  const [selectedLocation, setSelectedLocation] =
    useState<GoogleMapsPlaceWithDescription>();
  const [radius, setRadius] = useState<number>(DEFAULT_RADIUS);

  const clear = () => {
    setSelectedLocation(undefined);
    setRadius(DEFAULT_RADIUS);
  };

  const isEmpty = selectedLocation === undefined;

  return {
    selectedLocation,
    onChangeLocation: setSelectedLocation,
    radius,
    onChangeRadius: setRadius,
    clear,
    isEmpty,
  };
}
