import React from 'react';
import { Group, Input, NumberInput, Slider, Stack } from '@mantine/core';
import { IconMapPin } from '@tabler/icons-react';

import LocationAutocomplete from '../../../components/location-autocomplete/LocationAutocomplete';
import { LocationFilterState } from './types';
import FilterPill from './FilterPill';
import { isState } from '../../../components/location-autocomplete/utils';

const MIN_RADIUS = 1;
const MAX_RADIUS = 200;

interface ComponentProps {
  onClear: () => void;
  onClose: () => void;
  locationFilterState: LocationFilterState;
}

export default function LocationFilter({
  onClear,
  onClose,
  locationFilterState: {
    selectedLocation,
    onChangeLocation,
    radius,
    onChangeRadius,
  },
}: ComponentProps) {
  const filterLabel =
    radius && selectedLocation
      ? `${radius}mi - ${selectedLocation.description.split(',').slice(0, 2).join(',')}`
      : undefined;

  const stateSelected: boolean =
    !!selectedLocation && isState(selectedLocation);

  const handleChangeRadius = (value: string | number) => {
    onChangeRadius(Number(value));
  };

  return (
    <FilterPill
      Icon={IconMapPin}
      title='Location'
      label={filterLabel}
      onClear={onClear}
      onClose={onClose}
    >
      <Stack p='sm' w={280}>
        <LocationAutocomplete
          selectedPlace={selectedLocation}
          onPlaceSelected={onChangeLocation}
        />
        <Stack gap={0}>
          <Group justify='space-between'>
            <Input.Label>Within radius</Input.Label>
            <NumberInput
              w='50%'
              suffix='mi'
              min={MIN_RADIUS}
              max={MAX_RADIUS}
              value={radius}
              onChange={handleChangeRadius}
              disabled={stateSelected}
            />
          </Group>
          <Slider
            mb='lg'
            min={MIN_RADIUS}
            max={MAX_RADIUS}
            marks={[
              { value: 50, label: '50mi' },
              { value: 100, label: '100mi' },
              { value: 150, label: '150mi' },
            ]}
            value={radius}
            onChange={onChangeRadius}
            disabled={stateSelected}
          />
        </Stack>
      </Stack>
    </FilterPill>
  );
}
