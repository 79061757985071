import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  Fragment,
} from 'react';
import {
  ActionIcon,
  Switch,
  Alert,
  Box,
  Badge,
  Slider,
  NumberInput,
  Button,
  Card,
  Center,
  ComboboxData,
  Flex,
  Group,
  Input,
  MantineColor,
  NavLink,
  Select,
  Stack,
  Table,
  Text,
  Textarea,
  TextInput,
  Tooltip,
  Title,
  Menu,
  Modal,
  Divider,
  Loader,
} from '@mantine/core';
import './EditorPageV2.css';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import {
  IconCheck,
  IconExclamationCircle,
  IconPencil,
  IconPhone,
  IconPlus,
  IconTrash,
  IconRocket,
  IconGripVertical,
  IconHelp,
} from '@tabler/icons-react';
import { useLocation, useParams } from 'react-router-dom';
import { modals } from '@mantine/modals';
import { useDisclosure } from '@mantine/hooks';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useListState } from '@mantine/hooks';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import env from 'env';
import { Player } from '@lottiefiles/react-lottie-player';
import AddContactsModal from 'components/campaign/AddContactsModal';

import { getOutroFromSchedule } from './components/Outro';
import axios from '../../api/axiosConfig';
import Transcript from '../../components/transcript/Transcript';
import CallerSettingsStep from './components/CallerSettingsStep';

export enum RequirementImportance {
  Major = 'MAJOR',
  Normal = 'NORMAL',
  Minor = 'MINOR',
  Info = 'INFO',
}

export const IMPORTANCE_LABELS: Record<RequirementImportance, string> = {
  [RequirementImportance.Major]: 'Major',
  [RequirementImportance.Normal]: 'Normal',
  [RequirementImportance.Minor]: 'Minor',
  [RequirementImportance.Info]: 'Info',
};

export const IMPORTANCE_COLORS: Record<RequirementImportance, MantineColor> = {
  [RequirementImportance.Major]: 'var(--mantine-color-red-9)',
  [RequirementImportance.Normal]: 'var(--salv-dark-6)',
  [RequirementImportance.Minor]: 'var(--salv-dark-4)',
  [RequirementImportance.Info]: 'var(--mantine-color-blue-4)',
};

const IMPORTANCE_OPTIONS: ComboboxData = Object.entries(IMPORTANCE_LABELS).map(
  ([value, label]) => ({
    value,
    label,
  })
);

const ORG_BACKGROUND_INFO_PLACEHOLDER = `Example:
- Our company is called Salv AI. It is headquarted in San Francisco, CA.
- We specialize in building automated recruiting solutions
- Our corporate phone number is  877-527-7454
- If the candidate asks about benefits, say we offer a comprehensive benefits package including medical, dental, and vision. A recruiter can go more in-depth on a follow-up call`;

interface CandidateRequirement {
  question: string;
  importance: RequirementImportance;
  llmGradingInstructions?: string;
  failIfZero: boolean;
}

enum EditableRequirementField {
  Grading = 'grading',
  Question = 'question',
}

type RequriementEditState = {
  index: number | null; // null if no field is being edited
  fieldType: EditableRequirementField | null; // null if no specific field type is selected
};

const EmptyRequirementEditState: RequriementEditState = {
  index: null,
  fieldType: null,
};

export default function EditorPageV2({
  setScriptTitle,
  scriptFetched,
  setScriptFetched,
  isCampaignActive,
  setIsCampaignActive,
  handleContactsRefresh,
  canSubmitCampaign,
  setCanSubmitCampaign,
}) {
  const location = useLocation();
  const match = location.pathname.match(
    /\/scripts\/script-editor\/(new\/)?([^/]+)/
  );

  const { campaignId: paramCampaignId } = useParams();
  const isNew = !!match?.[1]; // 'new/' part exists if match[1] is truthy
  const campaignId = match ? match[2] : paramCampaignId;

  const email = localStorage.getItem('email') || '';
  const isInternal = email.includes('salv.ai');

  // scriptFetched is set when it's actually fetched
  // needsReFetch is set if we need a re-fetch (immediately after first fetch)
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [unsavedBackgroundInfoChanges, setUnsavedBackgroundInfoChanges] =
    useState(false);
  const [opened, setOpened] = useState(false);

  // just a UI hack so we don't show 'saving' when the script first loads

  const UNTITLED_SCRIPT = 'Untitled Script';
  const isInitialFetch = useRef(true);
  const needsReFetch = useRef(true);

  let lastTestPhoneNumber = localStorage.getItem('lastTestPhoneNumber') || '';
  let lastTestPhoneName = localStorage.getItem('lastTestPhoneName') || '';
  const [activeStep, setActiveStep] = useState<number>(0);
  const orgId = localStorage.getItem('orgId');

  const [confirmEditCompanyModalOpen, setConfirmEditCompanyModalOpen] =
    useState(false);
  const [editngCompanyInfo, setEditingCompanyInfo] = useState(false);
  const [companyInfoEdits, setCompanyInfoEdits] = useState('');

  const [newMessage, setNewMessage] = useState<string>('');
  const [msgEditIndex, setMsgEditIndex] = useState<number | null>(null);

  const orgBackgroundInfo = useForm({
    initialValues: {
      callOrgBackgroundInfo: '',
      backgroundInfoId: '' + uuidv4(),
    },
  });

  const testCallInfo = useForm({
    initialValues: {
      candidateName: lastTestPhoneName,
      phoneNumber: lastTestPhoneNumber,
    },
  });

  // const [isCollapsed, setIsCollapsed] = useState(
  //   window.innerWidth <= COLLAPSE_THRESHOLD
  // );
  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsCollapsed(window.innerWidth <= COLLAPSE_THRESHOLD);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   handleResize(); // Set initial state based on window width

  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  const [recruitingEmail, setRecruitingEmail] = useState<string | null>(null);
  useEffect(() => {
    const fetchOrgOutreachEmail = async () => {
      if (!orgId) {
        return;
      }
      try {
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/get_org_recruiting_email`,
          { orgId: orgId }
        );
        setRecruitingEmail(response.data.recuitingEmail);
      } catch (error) {
        console.error('Error fetching recruiting email');
        console.error(error);
      }
    };
    fetchOrgOutreachEmail();
  }, [orgId]);

  const [requirements, requirementsHandlers] =
    useListState<CandidateRequirement>([]);
  const [generatingScript, setGeneratingScript] = useState<boolean>(false);
  // const [requirementBeingEdited, setRequirementBeingEdited] = useState<
  //   number | undefined
  // >();

  const [requirementItemBeingEdited, setRequirementItemBeingEdited] =
    useState<RequriementEditState>(EmptyRequirementEditState);

  const [testCallActive, setTestCallActive] = useState<boolean>(false);
  const [testCallModalOpen, setTestCallModalOpen] = useState<boolean>(false);
  const [campaginModalOpen, setCampaignModalOpen] = useState<boolean>(false);

  const [candidateTimezone, setCandidateTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const defaultScheduleFollowUp =
    !!localStorage.getItem('calAPIKey') &&
    !!localStorage.getItem('calEventTypeID');
  const scriptInfo = useForm({
    initialValues: {
      jobDescription: '',
      callIntro: '',
      backgroundInfo: '',
      callOutro: getOutroFromSchedule(defaultScheduleFollowUp).actualValue,
      failedCallSms: [] as string[],
      failedCallEmailSubject: '',
      failedCallEmailBody: '',
      name: UNTITLED_SCRIPT,
      campaignId: campaignId,
      scheduleFollowUp: defaultScheduleFollowUp,
      passingScore: 60,

      // Old campaign Settings
      email: email,
      callNewApplicants: false,
      jobTitle: '',
      jobCity: '',
      jobState: '',

      // Old caller settings:
      callFromNumber: '',
      useScriptScaffolding: true,
      LLMModel: 'faster',
      voiceName: 'Lisa (American)',
      rescheduleCallOnVoicemail: true,
      callRetryCount: 3,
      callRetryHours: 6,
    },
  });

  const getMessagesArray = useCallback(
    (messages: string | string[]): string[] => {
      return Array.isArray(messages)
        ? messages
        : messages.split('-').filter((msg) => msg.trim() !== '');
    },
    []
  );

  const handleAddMessage = useCallback(() => {
    if (newMessage.trim()) {
      const currentMessages = getMessagesArray(scriptInfo.values.failedCallSms);
      const updatedMessages = [...currentMessages, newMessage];
      scriptInfo.setFieldValue('failedCallSms', updatedMessages);
      setNewMessage('');
    }
  }, [newMessage, scriptInfo, getMessagesArray]);

  const handleRemoveMessage = useCallback(
    (index: number) => {
      const currentMessages = getMessagesArray(scriptInfo.values.failedCallSms);
      const updatedMessages = currentMessages.filter((_, i) => i !== index);
      scriptInfo.setFieldValue('failedCallSms', updatedMessages);
    },
    [scriptInfo, getMessagesArray]
  );

  const handleEditMessage = useCallback((index: number) => {
    setMsgEditIndex(index);
  }, []);

  const handleSaveMessage = useCallback(
    (index: number, editedMessage: string) => {
      const currentMessages = getMessagesArray(scriptInfo.values.failedCallSms);
      const updatedMessages = [...currentMessages];
      updatedMessages[index] = editedMessage;
      scriptInfo.setFieldValue('failedCallSms', updatedMessages);
      setMsgEditIndex(null);
    },
    [scriptInfo, getMessagesArray]
  );

  const messages = getMessagesArray(scriptInfo.values.failedCallSms);

  useEffect(() => {
    setScriptFetched(false);
    needsReFetch.current = true;
  }, [campaignId, setScriptFetched]);

  useEffect(() => {
    const fetchScript = async () => {
      if (scriptFetched || !needsReFetch.current) {
        return;
      }
      needsReFetch.current = false;
      const setInitialFetchFalse = () => {
        isInitialFetch.current = false;
      };
      const setInitialFetchFalseAfterTimeout = () => {
        setTimeout(setInitialFetchFalse, 2000);
      };

      try {
        const backgroundInfoResp = await axios.get(
          `${env.REACT_APP_SERVER_URL}/org_background_info/${orgId}`
        );
        // Always fetch the background info
        if (backgroundInfoResp?.data) {
          orgBackgroundInfo.setValues({
            callOrgBackgroundInfo:
              backgroundInfoResp?.data?.callOrgBackgroundInfo || '',
          });
          setCompanyInfoEdits(
            backgroundInfoResp?.data?.callOrgBackgroundInfo || ''
          );
        }
        let scriptResponse;
        try {
          scriptResponse = await axios.get(
            `${env.REACT_APP_SERVER_URL}/script/${campaignId}`
          );
        } catch (error) {
          throw error;
        }

        if (!scriptResponse?.data && isNew) {
          // script failure would happen on first page open
          scriptInfo.reset();
          requirementsHandlers.setState([]);
          setScriptFetched(true);
          setEditingName(true);
          setInitialFetchFalseAfterTimeout();
          return;
        }

        // Only difference between these two objects is campaign_settings is from the sql
        // but script_info from cosmos. Made them separate here for clarity during developement
        const { script_info, campaign_settings } = scriptResponse.data;

        const infoToSet = {
          callIntro: script_info.callIntro,
          backgroundInfo: script_info.backgroundInfo,
          callOutro: script_info.callOutro,
          failedCallSms: script_info.failedCallSms,
          name: campaign_settings.campaign_name,
          campaignId: script_info.campaignId,
          failedCallEmailSubject: script_info.failedCallEmailSubject || '',
          failedCallEmailBody: script_info.failedCallEmailBody || '',
          jobDescription: script_info.jobDescription,
          passingScore:
            typeof script_info.passingScore === 'number'
              ? script_info.passingScore
              : 60,
          callNewApplicants: campaign_settings.call_new_applicants,
          // Indeed info (internal settings): TODO: make all that work
          email: campaign_settings.results_email,
          voiceName: campaign_settings.voice_name,
          rescheduleCallOnVoicemail:
            campaign_settings.reschedule_call_on_voicemail,
          callRetryCount: campaign_settings.call_retry_count,
          callRetryHours: campaign_settings.call_retry_hours,
          callFromNumber: campaign_settings.call_from_number,
        };
        setScriptTitle(campaign_settings.campaign_name);
        scriptInfo.setValues(infoToSet);
        requirementsHandlers.setState(script_info.requirements);
        scriptInfo.setFieldValue(
          'scheduleFollowUp',
          !!script_info.scheduleFollowUp
        );

        setScriptFetched(true);
        // this is somewhat hacky, we set this initial fetch done after timeout
        // so that we only "save" on state diffs we are confident are triggered not by the initial fetch
        // it's just a qol thing so that the user doesn't see an uneeded "save" when they first open the script while the state is setting
        // This is because the state changing is what triggers the useEffects
        // It's technically a potential race condition but should barely ever happen and I think this is the cleanest solution code-wise
        // + it's not too bad when it triggeres since it would only "save" the successfullt fetched values anyways
        // The one actually bad case is when BOTH setStates take > 2 seconds, one fails, one succeeds, and then the update is only partial,
        // Using useStates are tricky due to race conditions between set states and setstates triggering the save useEffects

        // TODO: I believe the only non-timeout hack solution is to track if each state value has been independently set to the value the backend returned
        // , doubly checking the backend return values with useRefs, and that the backend fetch succeeded.
        // something like
        // if (backendFetchedRef.current && valueRefAFromBackend.current === stateValueForA && sameForBB && sameForC..)
        //      then saving A,B,C allowed (A, B, C being parts of the script maintained as independent variables)
        // For now, the timeouts should be good enough since setState should never take more than a few millieconds

        // Note to future developer: you must be very careful when to use refs and state. If you try to use state variables in the useEffect or useaCallback that triggers saves,
        // changing those variables will cause the saves to fire
        setInitialFetchFalseAfterTimeout();
      } catch (error: unknown) {
        notifications.show({
          title: 'There was an error retrieving your script. Editing Disabled',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
        console.error('Error fetching campaign details:', error);
      } finally {
      }
    };
    const debouncedFetchScript = debounce(() => {
      fetchScript();
    }, 250);
    debouncedFetchScript();
  }, [
    campaignId,
    scriptInfo,
    orgBackgroundInfo,
    isNew,
    scriptFetched,
    setScriptFetched,
    requirementsHandlers,
    orgId,
    setScriptTitle,
  ]);

  const handleDeleteRequirement = useCallback(
    (i: number) => () => {
      setRequirementItemBeingEdited(EmptyRequirementEditState);
      // setRequirementBeingEdited(undefined);
      requirementsHandlers.remove(i);
    },
    [requirementsHandlers]
  );

  const handleGenerateScript = useCallback(() => {
    setGeneratingScript(true);
    axios
      .post(`${env.REACT_APP_SERVER_URL}/generate_script`, {
        jobDescription: scriptInfo.values.jobDescription,
      })
      .then((response) => {
        scriptInfo.setValues({
          callIntro: response.data.call_intro,
          backgroundInfo: `START_JOB_DESCRIPTION\n${scriptInfo.values.jobDescription}\nEND_JOB_DESCRIPTION`,
          failedCallSms: response.data.failed_call_sms,
          failedCallEmailSubject: response.data.failed_call_email_subject || '',
          failedCallEmailBody: response.data.failed_call_email_body || '',
        });
        requirementsHandlers.setState(
          response.data.requirements.map((requirement) => ({
            question: requirement.question,
            llmGradingInstructions: requirement.llm_grading_instructions,
            importance: RequirementImportance.Normal,
            failIfZero: false,
          })) || []
        );
        setActiveStep(activeStep + 1);
      })
      .catch((e) => {
        notifications.show({
          title: 'Error generating call script',
          message: e.message,
          color: 'red',
        });
      })
      .finally(() => {
        setGeneratingScript(false);
      });
  }, [activeStep, requirementsHandlers, scriptInfo]);

  const handleClickGenerateScript = useCallback(() => {
    const hasExistingContent =
      Object.entries(scriptInfo.values)
        .filter(
          ([key]) =>
            key !== 'jobDescription' &&
            key !== 'name' &&
            key !== 'callOutro' &&
            key !== 'campaignId' &&
            key !== 'requirements'
        )
        .some(([, value]) => !!value) || requirements.length > 0;
    if (hasExistingContent) {
      modals.openConfirmModal({
        title: 'Generate Script Info from Job Description',
        children: (
          <Alert color='red' icon={<IconExclamationCircle size={16} />}>
            Warning: this action will overwrite your existing script and
            requirements. Are you sure you want to proceed?
          </Alert>
        ),
        labels: { confirm: 'Generate', cancel: 'Cancel' },
        onConfirm: handleGenerateScript,
      });
    } else {
      handleGenerateScript();
    }
  }, [handleGenerateScript, requirements.length, scriptInfo.values]);

  const [callId, setCallId] = useState<string | null>(null);
  const [callStatusMsg, setCallStatusMsg] = useState<string>('');
  const [transcript, setTranscript] = useState<string>('');
  const [requirementGrades, setRequirementGrades] = useState<any>({});
  const [overallGrade, setOverallGrade] = useState(0);
  const [completionRate, setCompletionRate] = useState(0);
  const [finishedCallAt, setFinishedCallAt] = useState('');

  const [transcriptOpened, { open: openTranscript, close: closeTranscript }] =
    useDisclosure(false);

  useEffect(() => {
    if (testCallActive === false && transcript) {
      openTranscript();
    }
  }, [testCallActive, transcript, openTranscript]);

  useEffect(() => {
    let ws: WebSocket | null = null;
    if (callId && testCallActive) {
      ws = new WebSocket(`${env.REACT_APP_WS_URL}/ws/call_status/${callId}`);

      ws.onopen = () => {
        console.log('WebSocket Connected for callId:', callId);
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === 'event_transcript') {
          setTranscript(data.transcript);
          setRequirementGrades(data.requirement_grades);
          const currentUtcTime = new Date().toISOString();
          setCompletionRate(data.question_completion_rate);
          setOverallGrade(data.overall_grade);
          setFinishedCallAt(currentUtcTime);
          setTestCallActive(false);
        } else if (data.type === 'event_phone_call_ended') {
          setCallStatusMsg('Call complete. Extracting answers...');
        } else if (data.type === 'event_phone_call_connected') {
          setCallStatusMsg(
            `On the phone with ${testCallInfo.values.candidateName}...`
          );
        }
        console.log(data);
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onclose = () => {
        console.log('WebSocket Disconnected for callId:', callId);
      };
    }

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [callId, testCallActive, testCallInfo.values.candidateName]);

  const msgTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  useEffect(() => {
    if (msgTextareaRef.current && msgEditIndex !== -1) {
      msgTextareaRef.current.focus();
      const value = msgTextareaRef.current.value;
      if (value) {
        msgTextareaRef.current.setSelectionRange(value.length, value.length);
      }
    }
  }, [msgEditIndex]);

  const handleScheduleFollowUpChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.checked;

      if (
        newValue &&
        (!localStorage.getItem('calAPIKey') ||
          !localStorage.getItem('calEventTypeID'))
      ) {
        notifications.show({
          id: 'calendar-integration-required',
          title: 'Calendar Integration Required',
          message:
            'Please set up your calendar integration to use the "Schedule follow up" option.',
          color: 'red',
        });
        return;
      }

      scriptInfo.setFieldValue('scheduleFollowUp', newValue);

      const defaultCallOutro = getOutroFromSchedule(newValue).actualValue;
      scriptInfo.setFieldValue('callOutro', defaultCallOutro);
    },
    [scriptInfo]
  );

  const handleTestCall = async (e: React.FormEvent) => {
    e.preventDefault();

    if (verifyCallOutro()) {
      return;
    }

    localStorage.setItem(
      'lastTestPhoneName',
      testCallInfo.values.candidateName
    );
    localStorage.setItem(
      'lastTestPhoneNumber',
      testCallInfo.values.phoneNumber
    );

    setTestCallActive(true);
    setCallStatusMsg(`Setting up voice agent...`);

    // TODO: should not need anything except for campaign_id
    const payload: Record<string, any> = {
      campaignId,
      candidateName: testCallInfo.values.candidateName,
      phoneNumber: testCallInfo.values.phoneNumber,
      voiceName: scriptInfo.values.voiceName,
      callFromNumber: scriptInfo.values.callFromNumber,
    };

    if (scriptInfo.values.scheduleFollowUp && candidateTimezone) {
      payload.candidateTimezone = candidateTimezone;
    }

    try {
      const response = await axios.post(
        `${env.REACT_APP_SERVER_URL}/test_call`,
        payload
      );
      setCallId(response.data.data.call_id);
      setCallStatusMsg(`Calling ${testCallInfo.values.candidateName}...`);
    } catch (error) {
      console.error('Error initiating call:', error);
      setTestCallActive(false);
      setCallStatusMsg('Failed to initiate call. Please try again.');
    }
  };

  function CallStatus() {
    const closeButtonHeight = 40;

    return (
      <>
        {testCallActive && <div className='modal-overlay' />}
        <Modal
          opened={testCallActive}
          onClose={() => setTestCallActive(false)}
          centered
          size='lg'
          withCloseButton
        >
          <Center
            style={{
              height: `calc(100% - ${closeButtonHeight}px)`,
              paddingBottom: `${closeButtonHeight}px`,
            }}
          >
            <Text ta='center' fw={700} style={{ fontSize: '40px' }}>
              {callStatusMsg}
            </Text>
          </Center>
        </Modal>
      </>
    );
  }

  const noChanges = useMemo(() => {
    const {
      callIntro,
      backgroundInfo,
      failedCallSms,
      failedCallEmailSubject,
      failedCallEmailBody,
      name,
    } = scriptInfo.values;
    return (
      name === UNTITLED_SCRIPT &&
      !callIntro &&
      !backgroundInfo &&
      !failedCallSms &&
      !failedCallEmailSubject &&
      !failedCallEmailBody &&
      !requirements?.length
    );
  }, [scriptInfo.values, requirements?.length]);

  const saveScript = useCallback(async () => {
    // Make a POST request to /edit_script with the existing script's details

    const {
      jobDescription,
      callIntro,
      backgroundInfo,
      callOutro,
      failedCallSms,
      failedCallEmailSubject,
      failedCallEmailBody,
      name,
      passingScore,
      // campaign settings:
      email,
      callFromNumber,
      voiceName,
      rescheduleCallOnVoicemail,
      callRetryHours,
      callRetryCount,
      callNewApplicants,
    } = scriptInfo.values;
    const payload = {
      jobDescription,
      callIntro,
      backgroundInfo,
      callOutro,
      failedCallSms,
      failedCallEmailSubject,
      failedCallEmailBody,
      name,
      passingScore,
      requirements: requirements,
      scheduleFollowUp: scriptInfo.values.scheduleFollowUp,
      // campaign settings:
      email,
      callFromNumber: callFromNumber ? callFromNumber : '',
      voiceName,
      rescheduleCallOnVoicemail,
      callRetryCount,
      callRetryHours,
      callNewApplicants,
      // url
      campaignId,
    };

    if (noChanges) {
      setUnsavedChanges(false);
      // If no edits made, don't save progress
      return;
    }

    setUnsavedChanges(true);

    try {
      await axios.post(`${env.REACT_APP_SERVER_URL}/save_script`, payload);
      setUnsavedChanges(false);
      setScriptTitle(name);
    } catch (error) {
      console.error('Error updating script:', error);
    }
  }, [requirements, scriptInfo.values, noChanges, setScriptTitle, campaignId]);

  useEffect(() => {
    if (noChanges || isInitialFetch.current) {
      return;
    }
    setUnsavedChanges(true);
    const debouncedSaveScript = debounce(saveScript, 1000);

    debouncedSaveScript();

    // Cleanup function to cancel debounce on unmount or dependency change
    return () => {
      debouncedSaveScript.cancel();
    };
  }, [saveScript, noChanges]);

  const saveBackgroundInfo = useCallback(async () => {
    // Make a POST request to /edit_script with the existing script's details

    const { callOrgBackgroundInfo } = orgBackgroundInfo.values;
    const payload = {
      callOrgBackgroundInfo,
      orgId,
    };

    try {
      await axios.post(
        `${env.REACT_APP_SERVER_URL}/org_background_info`,
        payload
      );
      setUnsavedBackgroundInfoChanges(false);
    } catch (error) {
      console.error('Error saving backgroundinfo:', error);
    }
  }, [orgBackgroundInfo.values, orgId]);

  useEffect(() => {
    if (isInitialFetch.current) {
      return;
    }

    setUnsavedBackgroundInfoChanges(true);
    const debouncedSaveBackgroundInfo = debounce(saveBackgroundInfo, 2000);

    debouncedSaveBackgroundInfo();

    // Cleanup function to cancel debounce on unmount or dependency change
    return () => {
      debouncedSaveBackgroundInfo.cancel();
    };
  }, [saveBackgroundInfo]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the target is not within the table container
      const htmlTarget = e.target as HTMLElement;
      function hasClassOrParent(
        element: HTMLElement,
        className: string
      ): boolean {
        const numParentsToLookThrough = 3;
        let parentCount = 0;
        while (element) {
          if (parentCount > numParentsToLookThrough) {
            break;
          }
          if (element.classList.contains(className)) return true;
          element = element.parentElement as HTMLElement;
          parentCount += 1;
        }
        return false;
      }

      if (!hasClassOrParent(htmlTarget, 'editableField')) {
        setRequirementItemBeingEdited(EmptyRequirementEditState);
      }

      if (!hasClassOrParent(htmlTarget, 'titleInputArea')) {
        setEditingName(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const verifyCallOutro = () => {
    if (
      scriptInfo.values.scheduleFollowUp &&
      (!localStorage.getItem('calAPIKey') ||
        !localStorage.getItem('calEventTypeID'))
    ) {
      notifications.show({
        title: 'Calendar Integration Required',
        message:
          'Please set up your Calendar Integration or deselect "Schedule Follow Up" Call Outro option.',
        color: 'red',
      });
      return true;
    }

    if (
      scriptInfo.values.scheduleFollowUp &&
      !scriptInfo.values.callOutro.includes('{book_call}')
    ) {
      notifications.show({
        title: '{book_call} Required',
        message:
          'Please include "{book_call}" in your Call Outro or deselect the "Schedule Follow Up" option.',
        color: 'red',
      });
      return true;
    }
    return false;
  };

  const handleDragStart = () => {
    // Exit editing mode when starting to drag
    // setRequirementBeingEdited(undefined);
    setRequirementItemBeingEdited(EmptyRequirementEditState);
  };

  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;
      requirementsHandlers.reorder({
        from: result.source.index,
        to: result.destination.index,
      });
    },
    [requirementsHandlers]
  );

  // const handleRowClick = (index: number) => (e: React.MouseEvent) => {
  // e.preventDefault();
  // e.stopPropagation();
  // setRequirementBeingEdited((current) =>
  //   current === index ? undefined : index
  // );
  // };

  const areUnsavedCompanyInfoDiffs =
    companyInfoEdits !== orgBackgroundInfo.values.callOrgBackgroundInfo;

  const steps: {
    label: string;
    description?: string;
    indent?: boolean;
    isComplete: boolean;
    element?: React.ReactNode;
  }[] = useMemo(() => {
    const handlePreviousStep = () => {
      setActiveStep(activeStep - 1);
    };
    const handleNextStep = () => {
      setActiveStep(activeStep + 1);
    };

    const handleAddRequirement = () => {
      requirementsHandlers.append({
        question: '',
        llmGradingInstructions: '',
        importance: RequirementImportance.Normal,
        failIfZero: false,
      });
      // setRequirementBeingEdited(requirements.length);
    };
    const handleFieldChange =
      (index: number, field: keyof CandidateRequirement) =>
      (value: string | RequirementImportance | boolean) => {
        requirementsHandlers.setItemProp(index, field, value);
      };

    return [
      {
        label: 'Job Description (Optional)',
        // description: 'Auto-generate a call script and requirements',
        isComplete: true,
        element: (
          <>
            <div className='stepSectionContainer'>
              <Stack h='100%'>
                <Title order={5}>Job Description (Optional)</Title>
                <Text fz='sm' c='dimmed'>
                  Add a job description which can be used to generate a call
                  script and requirements
                </Text>
                <Textarea
                  className='fullHeightTextarea'
                  placeholder='Paste in complete job description...'
                  {...scriptInfo.getInputProps('jobDescription')}
                  disabled={generatingScript}
                />
              </Stack>
            </div>

            <div className='stepButtonsContainer'>
              <Flex justify='flex-end' gap='md'>
                <Button variant='outline' onClick={handleNextStep}>
                  Skip
                </Button>
                <Button
                  onClick={handleClickGenerateScript}
                  loading={generatingScript}
                  disabled={!scriptInfo.values.jobDescription}
                >
                  Generate Script and Requirements
                </Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Call Script',
        isComplete: true,
        element: (
          <>
            <div className='stepSectionContainer'>
              <Stack>
                <Title order={4}>Script Preview</Title>
                <Stack>
                  <Title order={6}>Intro</Title>
                  <Text
                    size='sm'
                    style={{
                      wordWrap: 'break-word' /* Break long words */,
                      wordBreak: 'break-all' /* Break all lines */,
                      whiteSpace: 'pre-wrap' /* Allow text to wrap */,
                    }}
                  >
                    {renderWithWaitLines(scriptInfo.values.callIntro)}
                  </Text>
                  <Title order={6}>Screening Questions</Title>
                  <div>
                    {requirements?.map((requirement, i) => (
                      <Fragment key={i}>
                        <Text size='sm'>{requirement.question}</Text>
                        {
                          <Text
                            size='xs'
                            c='gray'
                            className='wait-for-response'
                          >
                            Wait for response
                          </Text>
                        }
                      </Fragment>
                    ))}
                  </div>
                  <Title order={6}>Outro</Title>
                  <Text
                    size='sm'
                    style={{
                      wordWrap: 'break-word' /* Break long words */,
                      wordBreak: 'break-all' /* Break all lines */,
                      whiteSpace: 'pre-wrap' /* Allow text to wrap */,
                    }}
                  >
                    {
                      getOutroFromSchedule(scriptInfo.values.scheduleFollowUp)
                        .previewDisplay
                    }
                  </Text>
                </Stack>
              </Stack>
            </div>

            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Call Intro',
        isComplete: !!scriptInfo.values.callIntro,
        indent: true,
        element: (
          <>
            <div className='stepSectionContainer'>
              <Stack h='100%'>
                <Title order={5}>Call Intro</Title>
                <Text fz='sm' c='dimmed'>
                  This message will be used at the beginning of the call to
                  introduce the candidate to the company and role. Use{' '}
                  {'{candidate_name}'} to insert the candidate's name.
                </Text>
                <Textarea
                  className='halfHeightTextarea'
                  {...scriptInfo.getInputProps('callIntro')}
                />
              </Stack>
            </div>

            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Screening Questions',
        isComplete:
          requirements?.length !== 0 &&
          requirements?.every((req) => !!req.question),
        indent: true,
        element: (
          <>
            <div className='stepSectionContainer'>
              <DragDropContext
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                <div>
                  <Table className='requirementTable'>
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th
                          // draggable
                          style={{
                            width: '3%',
                          }}
                        />
                        <Table.Th
                          style={{
                            width: '40%',
                          }}
                        >
                          Question
                          <Input.Label required />
                          <Tooltip label='Questions worded as they will be asked during the screen'>
                            <IconHelp
                              size={16}
                              style={{
                                marginLeft: '4px',
                                position: 'relative',
                                top: '3px',
                                // color: '#868e96',
                              }}
                            />
                          </Tooltip>
                        </Table.Th>
                        <Table.Th
                          style={{
                            width: '40%',
                          }}
                        >
                          Grading Instructions{' '}
                          <Tooltip label='Rules for how to score response on a scale from 0 to 100'>
                            <IconHelp
                              size={16}
                              style={{
                                marginLeft: '4px',
                                position: 'relative',
                                top: '3px',
                                // color: '#868e96',
                              }}
                            />
                          </Tooltip>
                        </Table.Th>
                        <Table.Th
                          style={{
                            width: '10%',
                          }}
                        >
                          Fail if Zero{' '}
                          <Tooltip label='Set overall candidate score to 0 if question score is 0'>
                            <IconHelp
                              size={16}
                              style={{
                                marginLeft: '4px',
                                position: 'relative',
                                top: '3px',
                              }}
                            />
                          </Tooltip>
                        </Table.Th>
                        <Table.Th
                          style={{
                            width: '12%',
                          }}
                        >
                          Importance{' '}
                          <Tooltip label='Weight of the question score toward the overall score. Info is not used for scoring'>
                            <IconHelp
                              size={16}
                              style={{
                                marginLeft: '4px',
                                position: 'relative',
                                top: '3px',
                                // color: '#868e96',
                              }}
                            />
                          </Tooltip>
                        </Table.Th>
                        <Table.Th
                          style={{
                            width: '5%',
                          }}
                        />
                      </Table.Tr>
                    </Table.Thead>
                    <Droppable droppableId='requirements'>
                      {(provided) => (
                        <Table.Tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {requirements?.map(
                            (
                              {
                                question,
                                llmGradingInstructions,
                                importance,
                                failIfZero,
                              },
                              index
                            ) => {
                              const isIdxEdited =
                                requirementItemBeingEdited.index === index;
                              const fieldBeingEdited =
                                requirementItemBeingEdited.fieldType;

                              return (
                                <Draggable
                                  key={index}
                                  draggableId={`req-${index}`}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <Table.Tr
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={{
                                        ...provided.draggableProps.style,
                                        background: snapshot.isDragging
                                          ? 'var(--salv-dark-0)'
                                          : 'transparent',
                                      }}
                                    >
                                      <Table.Td
                                        {...provided.dragHandleProps}
                                        style={{
                                          width: '3%',
                                        }}
                                        className='gripTd'
                                      >
                                        <IconGripVertical
                                          size={18}
                                          className='dragIcon'
                                        />
                                      </Table.Td>
                                      <Table.Td
                                        style={{
                                          width: '40%',
                                        }}
                                      >
                                        <EditableField
                                          editing={
                                            isIdxEdited &&
                                            fieldBeingEdited ===
                                              EditableRequirementField.Question
                                          }
                                          value={question}
                                          onChange={handleFieldChange(
                                            index,
                                            'question'
                                          )}
                                          placeholder='What is your highest level of education?'
                                          setEditing={() => {
                                            setRequirementItemBeingEdited({
                                              fieldType:
                                                EditableRequirementField.Question,
                                              index: index,
                                            });
                                          }}
                                        />
                                      </Table.Td>
                                      <Table.Td
                                        style={{
                                          width: '40%',
                                        }}
                                      >
                                        <EditableField
                                          editing={
                                            isIdxEdited &&
                                            fieldBeingEdited ===
                                              EditableRequirementField.Grading
                                          }
                                          value={llmGradingInstructions}
                                          onChange={handleFieldChange(
                                            index,
                                            'llmGradingInstructions'
                                          )}
                                          setEditing={() => {
                                            setRequirementItemBeingEdited({
                                              fieldType:
                                                EditableRequirementField.Grading,
                                              index: index,
                                            });
                                          }}
                                          placeholder='Score 100 if the candidate has a bachelors or higher. Score 50 if the candidate graduated high school or equivalent. Score 0 if the candidate did not graduate high school.'
                                        />
                                      </Table.Td>
                                      <Table.Td
                                        style={{
                                          width: '10%',
                                        }}
                                      >
                                        <div>
                                          <Switch
                                            color='var(--salv-dark-6)'
                                            checked={failIfZero}
                                            onChange={(e) =>
                                              handleFieldChange(
                                                index,
                                                'failIfZero'
                                              )(e.currentTarget.checked)
                                            }
                                          />
                                        </div>
                                      </Table.Td>
                                      <Table.Td
                                        style={{
                                          width: '10%',
                                        }}
                                      >
                                        <BadgeSelector
                                          value={importance}
                                          onChange={handleFieldChange(
                                            index,
                                            'importance'
                                          )}
                                        />
                                      </Table.Td>
                                      <Table.Td
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                          width: '5%',
                                        }}
                                      >
                                        <Group w='max-content' gap='xs'>
                                          {/* <ActionIcon
                                            variant={
                                              editing ? 'filled' : 'subtle'
                                            }
                                            onClick={handleRowClick(index)}
                                          >
                                            {editing ? (
                                              <IconCheck size={16} />
                                            ) : (
                                              <IconPencil size={16} />
                                            )}
                                          </ActionIcon> */}
                                          <ActionIcon
                                            variant='subtle'
                                            color='red'
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleDeleteRequirement(index)();
                                            }}
                                          >
                                            <IconTrash size={16} />
                                          </ActionIcon>
                                        </Group>
                                      </Table.Td>
                                    </Table.Tr>
                                  )}
                                </Draggable>
                              );
                            }
                          )}
                          {provided.placeholder}
                        </Table.Tbody>
                      )}
                    </Droppable>
                  </Table>
                </div>
              </DragDropContext>
              <Flex justify='center'>
                <Button
                  leftSection={<IconPlus size={20} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddRequirement();
                  }}
                  variant='outline'
                >
                  Add Requirement
                </Button>
              </Flex>
            </div>
            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Follow-Up & Scheduling',
        isComplete: !!scriptInfo.values.callOutro,
        indent: true,
        element: (
          <>
            <div className='stepSectionContainer'>
              <Stack h='100%'>
                <Flex justify='space-between' align='center'>
                  <Title order={5}>Follow-Up & Scheduling Settings</Title>
                  <div
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      paddingTop: '10px',
                    }}
                  ></div>
                  <div style={{ paddingRight: '5px', paddingTop: '10px' }}>
                    <Switch
                      checked={scriptInfo.values.scheduleFollowUp}
                      onChange={handleScheduleFollowUpChange}
                      label='Schedule follow up'
                    />
                  </div>
                </Flex>

                <Flex
                  align='flex-center'
                  style={{ marginTop: '15px', marginBottom: '15px' }}
                >
                  <Text fz='sm' c='dimmed'>
                    This message will be used at the end of the call to debrief
                    the candidate and inform them of next steps. <br />
                    {scriptInfo.values.scheduleFollowUp
                      ? '\n\n\nIf the candidate scores above the passing score, they will be scheduled for a follow up call.'
                      : '  '}
                  </Text>

                  <Flex
                    style={{
                      opacity: scriptInfo.values.scheduleFollowUp ? 1.0 : 0.08,
                      pointerEvents: scriptInfo.values.scheduleFollowUp
                        ? 'all'
                        : 'none',
                      paddingLeft: '25px',
                      marginLeft: 'auto',
                    }}
                  >
                    <div style={{ width: '130px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text size='sm' style={{ paddingBottom: '6px' }}>
                          Passing Score{' '}
                          <Tooltip label='Threshold score past which a follow up call will be scheduled'>
                            <IconHelp
                              size={16}
                              style={{
                                marginLeft: '4px',
                                position: 'relative',
                                top: '2px',
                                // color: '#868e96',
                              }}
                            />
                          </Tooltip>
                        </Text>
                      </div>

                      <Slider
                        size='sm'
                        color={
                          scriptInfo.values.scheduleFollowUp
                            ? '#228be6e8'
                            : 'gray'
                        }
                        {...scriptInfo.getInputProps('passingScore')}
                        // value={scriptInfo.values.passingScore}
                      />
                    </div>
                    <div
                      style={{
                        width: '60px',
                        paddingLeft: '14px',
                        paddingTop: '6px',
                      }}
                    >
                      <NumberInput
                        {...scriptInfo.getInputProps('passingScore')}
                        min={0}
                        max={100}
                        size='xs'
                        hideControls
                      />
                    </div>
                  </Flex>
                </Flex>
                {/* {scriptInfo.values.scheduleFollowUp && (
                <Text fz='sm' c='dimmed'>
                  If the candidate's score is above the passing score, they will
                  be scheduled for a follow up call.
                </Text>
              )} */}
                <div
                  style={{
                    whiteSpace: 'pre-line',
                    border: '1px solid #DCDCDC',
                    borderRadius: '4px',
                    fontSize: '14px',
                    padding: '8px',
                    paddingLeft: '14px',
                    cursor: 'not-allowed',
                    // minHeight: '100px', do not set this, causes issues
                    opacity: 0.94,
                    backgroundColor: '#F8F8F8',
                    pointerEvents: 'none',
                  }}
                >
                  {
                    getOutroFromSchedule(scriptInfo.values.scheduleFollowUp)
                      .selectionPageDisplay
                  }
                </div>
              </Stack>
            </div>
            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Failed Call SMS',
        isComplete: !!scriptInfo.values.failedCallSms?.length,
        element: (
          <>
            <div className='stepSectionContainer'>
              <Stack h='100%'>
                <Title order={5}>SMS Message for Failed Calls</Title>
                <Text fz='sm' c='dimmed'>
                  Add messages to be sent to the candidate if they don't pick up
                  the phone. Use {'{candidate_name}'} to insert the candidate's
                  name.
                </Text>

                <div
                  style={{
                    padding: '20px',
                    border: '1px solid #8080802e',
                    borderRadius: '12px',
                  }}
                >
                  <Stack
                    mt='md'
                    style={{
                      maxHeight: '500px',
                      minHeight: '350px',
                      overflowY: 'auto',
                    }}
                  >
                    {messages.length > 0 ? (
                      messages.map((message, index) => (
                        <Flex
                          key={index}
                          justify='space-between'
                          align='center'
                          className='chatBubble'
                          style={{
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            marginRight: '0px',
                            marginLeft: 'auto',
                            backgroundColor: '#f0f0f0',
                            borderRadius:
                              index === messages.length - 1
                                ? '20px 20px 0 20px'
                                : '20px',
                            width: msgEditIndex === index ? '70%' : 'auto',
                          }}
                        >
                          {msgEditIndex === index ? (
                            <Textarea
                              value={message}
                              onChange={(e) => {
                                const updatedMessages = [...messages];
                                updatedMessages[index] = e.target.value;
                                scriptInfo.setFieldValue(
                                  'failedCallSms',
                                  updatedMessages
                                );
                              }}
                              variant='unstyled'
                              style={{
                                flexGrow: 1,
                                fontSize: '0.875rem',
                                border: '1px solid #cac9c9',
                                borderRadius: '6px',
                              }}
                              minRows={1}
                              ref={msgTextareaRef}
                            />
                          ) : (
                            <Text
                              style={{ fontSize: '0.875rem' }}
                              onClick={() => handleEditMessage(index)}
                              className='editableText'
                            >
                              {message}
                            </Text>
                          )}
                          <Flex style={{ gap: '14px', marginLeft: '20px' }}>
                            {msgEditIndex === index ? (
                              <Button
                                onClick={() =>
                                  handleSaveMessage(index, messages[index])
                                }
                              >
                                Done
                              </Button>
                            ) : (
                              <>
                                <Button
                                  variant='subtle'
                                  onClick={() => handleRemoveMessage(index)}
                                  style={{ padding: '0px 4px' }}
                                >
                                  <IconTrash size={16} color={'red'} />
                                </Button>
                              </>
                            )}
                          </Flex>
                        </Flex>
                      ))
                    ) : (
                      <Text style={{ textAlign: 'center', color: 'gray' }}>
                        No messages added
                      </Text>
                    )}
                  </Stack>
                  <Flex align='center' gap='md' mt='md'>
                    <Input
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      placeholder='Type your message here'
                      style={{ flexGrow: 1 }}
                    />
                    <Button onClick={handleAddMessage}>Add Message</Button>
                  </Flex>
                </div>
              </Stack>
            </div>
            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      ...(isInternal
        ? [
            {
              label: 'Failed Call Email',
              isComplete:
                !recruitingEmail ||
                (!!scriptInfo.values.failedCallEmailBody &&
                  !!scriptInfo.values.failedCallEmailSubject),
              element: (
                <>
                  <div className='stepSectionContainer'>
                    <Stack h='100%'>
                      <Title order={5}>Email for Failed Calls</Title>
                      <Text fz='sm' c='dimmed'>
                        This is the email that will be sent to the candidate if
                        they don't pick up the phone. Use {'{candidate_name}'}{' '}
                        to insert the candidate's name and{' '}
                        {'{recruiter_phone_number}'} to include the recruiter's
                        callback number.
                      </Text>
                      {!recruitingEmail && (
                        <div className='callout callout-md'>
                          Your organization admin must set up a recruiting email
                          to utilize this feature.
                        </div>
                      )}
                      <TextInput
                        disabled={!recruitingEmail}
                        {...scriptInfo.getInputProps('failedCallEmailSubject')}
                        label='Subject'
                      />
                      <Textarea
                        className='fullHeightTextarea'
                        {...scriptInfo.getInputProps('failedCallEmailBody')}
                        disabled={!recruitingEmail}
                        label='Body'
                      />
                      <br />
                    </Stack>
                  </div>
                  <div className='stepButtonsContainer'>
                    <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                      <Button variant='outline' onClick={handlePreviousStep}>
                        Previous
                      </Button>
                      <Button onClick={handleNextStep}>Next</Button>
                    </Flex>
                  </div>
                </>
              ),
            },
          ]
        : []),
      {
        label: 'Background Info',
        isComplete:
          !!scriptInfo.values.backgroundInfo && !areUnsavedCompanyInfoDiffs,
        element: (
          <>
            <div className='stepSectionContainer'>
              {/* NOTE: outer minHeight currently prevents divs from overlapping in small browser window size.
              must be greater than minHeight + total height of other elements */}
              <Stack style={{ minHeight: '300px', height: '40vh' }}>
                <Title order={5}>
                  Job-Specific Background Info and Instructions
                </Title>
                <Text fz='sm' c='dimmed'>
                  Background knowledge the AI can use to provide context or
                  answer questions about the job.
                  <br />
                  This is not directly part of the script.
                </Text>
                <Textarea
                  className='fullHeightTextarea'
                  placeholder={`-This candidate had applied to our ad on Indeed.
-The jobsite is at 123 1st St, San Francisco, CA 94105. It is just off highway 1 near the McDonald's.
-This is a 6 month contract with opportunity to convert to perm after that.`}
                  {...scriptInfo.getInputProps('backgroundInfo')}
                />
              </Stack>
              <Modal.Root
                opened={confirmEditCompanyModalOpen}
                onClose={() => {
                  setConfirmEditCompanyModalOpen(false);
                }}
                size='lg'
              >
                <Modal.Overlay />
                <Modal.Content>
                  <Modal.Header>
                    <Modal.Title style={{ fontWeight: 'bold' }}>
                      Company Edit Warning Confirmation
                    </Modal.Title>
                    <Modal.CloseButton />
                  </Modal.Header>
                  <Divider />
                  <Modal.Body>
                    <br />
                    <Alert
                      color='yellow'
                      icon={<IconExclamationCircle size={16} />}
                      style={{ padding: '16px' }}
                    >
                      <div style={{ fontSize: '16px' }}>
                        <b>Warning</b>: Changes to comapny info will affect all
                        scripts and campaigns
                      </div>
                    </Alert>
                    <br />
                    <div>
                      Modifications to the company background information will
                      be applied to <b>all jobs, scripts, and campaigns</b> in
                      your organization.
                    </div>
                    <br />
                    <br />
                    <Divider />
                    <div
                      style={{
                        display: 'flex',
                        marginTop: '16px',
                        gap: '20px',
                      }}
                    >
                      <Button
                        variant='outline'
                        style={{ marginLeft: 'auto' }}
                        onClick={() => {
                          setConfirmEditCompanyModalOpen(false);
                          setEditingCompanyInfo(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant='light'
                        style={{ marginRight: 'auto' }}
                        onClick={() => {
                          setEditingCompanyInfo(true);
                          setConfirmEditCompanyModalOpen(false);
                        }}
                      >
                        Acknowledge and Edit
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal.Content>
              </Modal.Root>

              <Stack style={{ height: '40vh' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '14px', // a bit gross in terms of consistency accross panels but was feeling cluttered because of how loud this panel can be
                  }}
                >
                  <Title order={5}>
                    Company Info, Policies, and Instructions
                  </Title>
                  <ActionIcon
                    variant={editngCompanyInfo ? 'filled' : 'subtle'}
                    onClick={() => {
                      if (!editngCompanyInfo) {
                        setConfirmEditCompanyModalOpen(true);
                      } else {
                        setEditingCompanyInfo(false);
                        if (
                          orgBackgroundInfo.values.callOrgBackgroundInfo !==
                          companyInfoEdits
                        ) {
                          // Note: need this IF because otherwise the save is triggered
                          orgBackgroundInfo.setValues({
                            callOrgBackgroundInfo: companyInfoEdits,
                          });
                        }
                      }
                    }}
                    style={{
                      // marginLeft: 'auto',
                      marginLeft: editngCompanyInfo ? '14px' : '8px',
                    }}
                  >
                    {editngCompanyInfo ? (
                      <IconCheck size={16} />
                    ) : (
                      <IconPencil size={16} />
                    )}
                  </ActionIcon>
                  {editngCompanyInfo && areUnsavedCompanyInfoDiffs && (
                    <div style={{ display: 'flex', marginLeft: 'auto' }}>
                      <Button
                        variant='transparent'
                        size='xs'
                        onClick={() => {
                          setEditingCompanyInfo(false);
                          setCompanyInfoEdits(
                            orgBackgroundInfo.values.callOrgBackgroundInfo
                          );
                        }}
                      >
                        Discard Edits
                      </Button>
                    </div>
                  )}
                </div>

                {editngCompanyInfo && (
                  <div className='callout'>
                    Modifications to the company background information will be
                    applied to all jobs, scripts, and campaigns in your
                    organization.
                  </div>
                )}

                <Text fz='sm' c='dimmed'>
                  Background knowledge the AI can use to provide context or
                  answer questions about the company.
                  <br />
                  This is not directly part of the script.
                </Text>
                {editngCompanyInfo ? (
                  <>
                    <Textarea
                      className='fullHeightTextarea'
                      placeholder={ORG_BACKGROUND_INFO_PLACEHOLDER}
                      value={companyInfoEdits}
                      onChange={(e) => setCompanyInfoEdits(e.target.value)}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      color: orgBackgroundInfo.values.callOrgBackgroundInfo
                        ? ''
                        : 'gray',
                      whiteSpace: 'pre-line',
                      border: '1px solid #DCDCDC',
                      borderRadius: '4px',
                      fontSize: '14px',
                      padding: '8px',
                      paddingLeft: '14px',
                      opacity: orgBackgroundInfo.values.callOrgBackgroundInfo
                        ? 0.95
                        : 0.6,
                      backgroundColor: orgBackgroundInfo.values
                        .callOrgBackgroundInfo
                        ? '#F8F8F8'
                        : '#FAFAFA',
                      maxHeight: '400px',
                      overflowY: 'scroll',
                      height: '100%',
                    }}
                  >
                    {orgBackgroundInfo.values.callOrgBackgroundInfo
                      ? orgBackgroundInfo.values.callOrgBackgroundInfo
                      : ORG_BACKGROUND_INFO_PLACEHOLDER}
                  </div>
                )}
              </Stack>
            </div>
            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
                <Button onClick={handleNextStep}>Next</Button>
              </Flex>
            </div>
          </>
        ),
      },
      {
        label: 'Settings',
        isComplete: true,
        element: (
          <>
            {/* NOTE: outer minHeight currently prevents divs from overlapping in small browser window size.
              must be greater than minHeight + total height of other elements */}
            <div className='stepSectionContainer'>
              <Stack h='100%'>
                <Title order={5}>Settings</Title>
                <CallerSettingsStep
                  campaignSettings={scriptInfo}
                  campaignId={campaignId}
                />
              </Stack>
            </div>
            <div className='stepButtonsContainer'>
              <Flex h='100%' justify='flex-end' align='flex-end' gap='md'>
                <Button variant='outline' onClick={handlePreviousStep}>
                  Previous
                </Button>
              </Flex>
            </div>
          </>
        ),
      },
    ];
  }, [
    isInternal,
    scriptInfo,
    generatingScript,
    recruitingEmail,
    handleClickGenerateScript,
    requirements,
    handleDragEnd,
    activeStep,
    requirementsHandlers,
    requirementItemBeingEdited,
    handleDeleteRequirement,
    orgBackgroundInfo,
    areUnsavedCompanyInfoDiffs,
    editngCompanyInfo,
    campaignId,
    confirmEditCompanyModalOpen,
    msgEditIndex,
    handleEditMessage,
    handleSaveMessage,
    companyInfoEdits,
    handleScheduleFollowUpChange,
    messages,
    newMessage,
    handleAddMessage,
    handleRemoveMessage,
  ]);

  function renderWithWaitLines(text) {
    const lines = text.split(/\n+/);
    return lines.map((line, index) => (
      <Fragment key={index}>
        <Text
          size='sm'
          style={{
            wordWrap: 'break-word', // Break long words
            wordBreak: 'break-all', // Break all lines
            whiteSpace: 'pre-wrap', // Allow text to wrap
          }}
        >
          {line}
        </Text>
        {
          <Text size='xs' c='gray' className='wait-for-response'>
            Wait for response
          </Text>
        }
      </Fragment>
    ));
  }

  useEffect(() => {
    setCanSubmitCampaign(steps.every((step) => step.isComplete));
  }, [steps, setCanSubmitCampaign]);

  const active = steps[activeStep];

  const handleStepClick = (step: number) => () => {
    setActiveStep(step);
  };

  const [editingName, setEditingName] = useState(false);

  if (!scriptFetched) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Loader type='dots' style={{ marginTop: '2%' }} />
      </div>
    );
  }

  return (
    <div className='editorPageContainer'>
      <Stack
        // p='md'
        className='column-padding'
        style={{
          overflowY: 'scroll',
          gap: '14px',
        }}
      >
        {opened && (
          <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            withCloseButton={false}
            centered
            size='lg'
          >
            <Player
              autoplay
              loop={false}
              src='/confetti.json'
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Modal>
        )}
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Textarea
              className={`titleInputArea editableText ${editingName ? 'editingTitleActive' : ''}`}
              autosize
              {...scriptInfo.getInputProps('name')}
              minRows={1}
              onClick={() => {
                setEditingName(true);
              }}
            />
          </div>
          <div
            style={{
              paddingLeft: '6px',
              margin: '0px',
              fontSize: '10px',
              color: '#696969',
              marginTop: '4px',
            }}
          >
            {areUnsavedCompanyInfoDiffs ? (
              'Unsaved Company Info Changes'
            ) : !noChanges ? (
              <>
                {unsavedChanges || unsavedBackgroundInfoChanges
                  ? 'Saving...'
                  : 'Saved'}
              </>
            ) : (
              <div style={{ visibility: 'hidden' }}>{'Saving'}</div>
            )}
          </div>
        </div>

        {steps.map(({ label, description, isComplete, indent }, i) => (
          <>
            <NavLink
              key={i}
              active={activeStep === i}
              label={label}
              description={description}
              w={indent ? 'calc(100% - 26px)' : '100%'}
              onClick={handleStepClick(i)}
              color={isComplete ? '' : 'red'}
              style={{
                color: isComplete ? '' : 'var(--mantine-color-red-6)',
                borderRadius: '6px',
                marginLeft: indent ? '26px' : '0px',
              }}
            />
          </>
        ))}
        <Divider />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            // justifyContent: 'center',
            height: '100%',
            marginTop: '14px',
          }}
        >
          <Button
            variant='light'
            disabled={!canSubmitCampaign}
            leftSection={<IconPhone size={20} />}
            onClick={() => {
              if (verifyCallOutro()) {
                return;
              }
              setTestCallModalOpen(true);
            }}
            className={'campaign-action-button'}
          >
            Test Call
          </Button>
          <Modal
            opened={testCallModalOpen}
            onClose={() => {
              setTestCallModalOpen(false);
            }}
            title='Test Call'
          >
            <Card shadow='sm' miw={300}>
              <Stack>
                <TextInput
                  label='Candidate Name'
                  placeholder='John Doe'
                  required
                  {...testCallInfo.getInputProps('candidateName')}
                />
                <TextInput
                  label='Candidate Phone Number'
                  placeholder='123-456-7890'
                  required
                  {...testCallInfo.getInputProps('phoneNumber')}
                />
                <Select
                  label='Voice'
                  data={[
                    'Lisa (American)',
                    'George (American)',
                    'Abby (British)',
                    'William (British)',
                  ]}
                  placeholder='Select a voice'
                  nothingFoundMessage='No matching voices'
                  {...scriptInfo.getInputProps('voiceName')}
                  allowDeselect={false}
                />
                {scriptInfo.values.scheduleFollowUp && (
                  <Select
                    label='Timezone'
                    value={candidateTimezone}
                    onChange={(value) => setCandidateTimezone(value || '')}
                    data={[
                      'America/New_York',
                      'America/Chicago',
                      'America/Denver',
                      'America/Los_Angeles',
                    ]}
                    placeholder='Select a timezone'
                    nothingFoundMessage='No matching timezones'
                    allowDeselect={false}
                  />
                )}
                <br />
                <Button
                  variant='light'
                  leftSection={<IconPhone size={20} />}
                  onClick={handleTestCall}
                  size='lg'
                >
                  Test Call
                </Button>
              </Stack>
            </Card>
          </Modal>

          <Button
            onClick={() => {
              if (verifyCallOutro()) {
                return;
              }
              setCampaignModalOpen(true);
            }}
            disabled={!canSubmitCampaign}
            className={'campaign-action-button'}
            leftSection={<IconRocket size={20} />}
          >
            Add Contacts
          </Button>
          <AddContactsModal
            campaignModalOpen={campaginModalOpen}
            setCampaignModalOpen={setCampaignModalOpen}
            campaignId={campaignId}
            isCampaignActive={isCampaignActive}
            setIsCampaignActive={setIsCampaignActive}
            handleContactsRefresh={handleContactsRefresh}
          />
        </div>
      </Stack>
      <div
        // p='md'
        // style={{
        //   overflowY: 'scroll',
        // }}
        className='column-padding'
      >
        <div className='stepContainer'>{active.element && active.element}</div>
      </div>
      {testCallActive && <CallStatus />}
      <Modal
        opened={transcriptOpened}
        onClose={closeTranscript}
        size='80%'
        styles={{
          header: {
            backgroundColor: 'transparent',
          },
        }}
      >
        {' '}
        {callId && (
          <Transcript
            transcript={transcript}
            requirementGrades={requirementGrades}
            callId={callId}
            lastCalled={finishedCallAt}
            overallGrade={overallGrade}
            completionRate={completionRate}
            testCall={true}
            candidateName={testCallInfo.values.candidateName}
            callComplete={true}
          />
        )}
      </Modal>
    </div>
  );
}

const EditableField = ({
  editing,
  value,
  onChange,
  placeholder,
  setEditing,
}) => {
  // if (editing) {
  return (
    <Textarea
      autosize
      minRows={2}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onClick={() => {
        setEditing();
      }}
      className={`editableField tableTextArea editableText ${editing ? 'editingBorder' : ''}`}
      placeholder={placeholder}
      // unstyled
    />
  );
};

const BadgeSelector = ({ value, onChange }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
    }
  }, []);

  return (
    <Box ref={containerRef} style={{ width: width }}>
      <Menu position='bottom-start'>
        <Menu.Target>
          <Badge
            variant='outline'
            color={IMPORTANCE_COLORS[value]}
            style={{ fontWeight: 600, cursor: 'pointer' }}
          >
            {IMPORTANCE_LABELS[value]}
          </Badge>
        </Menu.Target>
        <Menu.Dropdown style={{ padding: '8px' }}>
          {IMPORTANCE_OPTIONS.map((option, index) => (
            <Menu.Item
              key={option}
              onClick={() => onChange(option.value)}
              style={{
                padding: 0,
                marginTop: index === 0 ? 0 : '8px', // apply margin only on items after the first
              }}
            >
              <Badge
                variant='outline'
                color={IMPORTANCE_COLORS[option.value]}
                style={{
                  fontWeight: 600,
                  // width: '100%',
                  textAlign: 'left',
                  padding: '8px',
                }}
              >
                {IMPORTANCE_LABELS[option.value]}
              </Badge>
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
};
