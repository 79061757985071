import axios from 'api/axiosConfig';
import env from 'env';

import { IndeedJob } from './types';

export const setCampaignJobs = (
  jobs: IndeedJob[],
  campaignId: string
): Promise<IndeedJob[]> => {
  const payload = {
    jobs: jobs,
    campaignId: campaignId,
  };

  return axios
    .post(`${env.REACT_APP_SERVER_URL}/update_campaign_jobs`, payload)
    .then((response) => {
      const updatedJobs = response.data.jobs.map((j) => ({
        jobCity: j.city,
        jobState: j.state,
        jobTitle: j.title,
      })) as IndeedJob[];
      return updatedJobs;
    });
};

export const getCampaignJobs = (campaignId: string): Promise<IndeedJob[]> => {
  return axios
    .get(`${env.REACT_APP_SERVER_URL}/campaigns/campaign/${campaignId}/jobs`)
    .then((response) => {
      const jobs = response.data.jobs.map((j) => ({
        jobCity: j.city,
        jobState: j.state,
        jobTitle: j.title,
      })) as IndeedJob[];
      return jobs;
    });
};
