import React, { createRef, useState } from 'react';
import {
  Anchor,
  Button,
  FileInput,
  Flex,
  Stack,
  Modal,
  Divider,
} from '@mantine/core';
import env from 'env';
import { notifications } from '@mantine/notifications';
import { IconPlayerPlay } from '@tabler/icons-react';
import { startCampaign } from 'pages/scripts/actions';
import { Player } from '@lottiefiles/react-lottie-player';

import axios from '../../api/axiosConfig';

import './AddContactsModal.css';

const AddContactsModal = ({
  campaignModalOpen,
  setCampaignModalOpen,
  campaignId,
  isCampaignActive,
  setIsCampaignActive,
  handleContactsRefresh,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = createRef<HTMLButtonElement>();

  const [contactsSuccess, setContactsSuccess] = useState(false);
  const [numContactsUploaded, setNumContactsUploaded] = useState(undefined);
  const [campaignSuccess, setCampaignSuccess] = useState(false);

  const handleFileSelect = (event) => {
    const file = event;
    if (file) {
      const extension = file.name.split('.').pop().toLowerCase();
      const isCSV = extension === 'csv' || file.type === 'text/csv';
      if (isCSV) {
        setSelectedFile(file);
      } else {
        alert(
          'Only CSV files allowed. If you believe this is an error, please contact help@salv.ai'
        );
      }
    }
  };

  const handleActivateCampaign = () => {
    startCampaign(campaignId)
      .then(() => {
        setIsCampaignActive(true);
        setCampaignSuccess(true);
        handleContactsRefresh();
      })
      .catch((err) => {
        console.error('error setting campaign active', err);
      });
  };

  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleFileDrop = (e) => {
    preventDefaults(e);

    const files = e.dataTransfer.files;
    if (files.length) {
      handleFileSelect(files[0]);
    }
  };

  const handleDownloadExample = () => {
    window.location.href = `https://salvstorageaccount.blob.core.windows.net/public/example_call_list.csv`;
  };

  const renderGreenCheck = (size = 48) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='green'
      className='bi bi-check-circle-fill'
      viewBox='0 0 16 16'
    >
      <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.93 10.58l-2.47-2.47-.708.708 3.18 3.18 6.364-6.364-.708-.708L6.93 10.58z' />
    </svg>
  );

  const handleSubmit = () => {
    if (!selectedFile) {
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('file', selectedFile);
    console.log('submit form with id ', campaignId);
    formData.append('campaign_id', campaignId);
    axios
      .post(`${env.REACT_APP_SERVER_URL}/add_contacts`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((resp) => {
        setContactsSuccess(true);
        handleContactsRefresh();
        console.log('Resp', resp);
        if (resp.data.num_contacts) {
          setNumContactsUploaded(resp.data.num_contacts);
        }
      })
      .catch((e) => {
        notifications.show({
          title: 'Failed to upload contacts',
          message: e.message,
          color: 'red',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Define the render functions for each case
  const renderFileInput = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div style={{ width: '360px' }}>
        <div
          onDragOver={preventDefaults}
          onDrop={handleFileDrop}
          className='contacts-file-input'
        >
          <FileInput
            placeholder='Upload CSV'
            label='Upload candidates csv file'
            required
            value={selectedFile}
            ref={fileInputRef}
            error={!selectedFile}
            onChange={handleFileSelect}
          />
          <Anchor
            size='sm'
            onClick={handleDownloadExample}
            style={{ position: 'relative', top: '6px' }}
          >
            Download Example CSV
          </Anchor>
        </div>
        <br />
        <br />
        <Flex justify='center' gap='md'>
          <Button
            onClick={handleSubmit}
            loading={loading}
            disabled={!selectedFile}
          >
            Add Contacts
          </Button>
        </Flex>
        <br />
      </div>
    </div>
  );

  const renderContactsSuccess = () => (
    <Flex
      justify='center'
      align='center'
      direction='column'
      gap='md'
      style={{ minHeight: '200px' }}
    >
      <h3
        style={{
          textAlign: 'center',
          marginTop: '0px',
          top: '-20px',
          position: 'relative',

          display: 'flex',
          alignItems: 'center' /* Vertically centers the h3 */,
          gap: '8px',
        }}
      >
        {numContactsUploaded
          ? `${numContactsUploaded} Contact${numContactsUploaded === 1 ? '' : 's'} Uploaded`
          : `Contacts Uploaded`}
        {!isCampaignActive && renderGreenCheck(24)}
      </h3>

      {isCampaignActive ? (
        renderGreenCheck()
      ) : (
        <Button
          size='md'
          leftSection={<IconPlayerPlay size={20} />}
          onClick={handleActivateCampaign}
        >
          Activate Sequence
        </Button>
      )}
    </Flex>
  );

  const renderSequenceStarted = () => (
    <Flex
      justify='center'
      align='center'
      direction='column'
      gap='md'
      style={{ minHeight: '340px' }}
    >
      <div style={{ position: 'absolute' }}>
        <Player
          autoplay
          loop
          src='/confetti.json'
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <h1
        style={{
          textAlign: 'center',
          marginTop: '0px',
          top: '-10px',
          position: 'relative',
        }}
      >
        Sequence Started Successfully 🚀
      </h1>
      {renderGreenCheck()}
    </Flex>
  );

  const renderContent = () => {
    switch (true) {
      case campaignSuccess:
        return renderSequenceStarted();
      case contactsSuccess:
        return renderContactsSuccess();
      default:
        return renderFileInput();
    }
  };

  return (
    <Modal
      opened={campaignModalOpen}
      onClose={() => {
        setCampaignModalOpen(false);
        setContactsSuccess(false);
      }}
      title='Add Contacts'
      size='lg'
    >
      <Stack justify='space-between' h='100%'>
        <Divider style={{ margin: '5px' }} />

        {renderContent()}
      </Stack>
    </Modal>
  );
};

export default AddContactsModal;
