import React, { useState, useEffect, useRef } from 'react';
import './AudioPlayer.css';
import env from 'env';

const AudioPlayer = ({ url }) => {
  console.log('url', url);
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '0px',
      }}
    >
      {/* <button onClick={togglePlay}>{isPlaying ? 'Pause' : 'Play'}</button> */}
      <audio
        controls
        src={url}
        className='custom-audio'
        style={{
          border: '1px solid var(--salv-dark-1)',
          //   opacity: 0.75,
          padding: '4px',
        }}
      />
    </div>
  );
};

const CallRecordingPlayer = ({ callId }) => {
  const [opacity, setOpacity] = useState(0.75);
  const intervalIdRef = useRef<ReturnType<typeof setInterval> | null>(null); // Correct typing using ReturnType

  useEffect(() => {
    const url = `${env.REACT_APP_SERVER_URL}/download_recording/${callId}`;

    const pollUrl = async () => {
      try {
        const response = await fetch(url);
        if (response.status === 404) {
          setOpacity(0.2);
        } else {
          setOpacity(0.75);
          if (intervalIdRef.current !== null)
            clearInterval(intervalIdRef.current);
        }
      } catch (error) {
        console.error('Error fetching the URL:', error);
      }
    };

    pollUrl();

    intervalIdRef.current = setInterval(pollUrl, 5000); // Poll every 5 seconds

    return () => {
      if (intervalIdRef.current !== null) clearInterval(intervalIdRef.current); // Cleanup on component unmount
    };
  }, [callId]);
  return (
    <div style={{ display: 'flex', fontSize: '14px', opacity: opacity }}>
      {!!callId && (
        <AudioPlayer
          url={`${env.REACT_APP_SERVER_URL}/download_recording/${callId}`}
        />
      )}
    </div>
  );
};

export default CallRecordingPlayer;
